import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Route, Switch, Redirect } from 'react-router'

import { isViewer } from '../../utils/role';
import Week from './week/Week';
import AttendanceView from './attendance/AttendanceView';


class ViewContent extends React.Component {
    static propTypes = {
        profile: PropTypes.object,
        path: PropTypes.string,
        push: PropTypes.func.isRequired,
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    componentWillMount() {
        if (!isViewer(this.props.profile) && !this.props.profile.pending) {
            console.log('ViewContent.componentWillMount: redirect because user is not viewer', this.props.profile);
            this.props.push('/');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.profile.pending && this.props.profile.pending) {
            if (!isViewer(nextProps.profile)) {
                console.log('ViewContent.componentWillReceiveProps: redirect because user is not viewer', this.props.profile);
                this.props.push('/');
            }
        }
    }

    render() {
        let { onTitle } = this.props;

        let show = isViewer(this.props.profile);

        return (
            <>
                {show &&
                    <div className="imsp-view-section">
                        <Switch>
                            <Route
                                path="/view/week"
                                render={(props) => <Week {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                            />
                            <Route
                                path="/view/attendance"
                                render={(props) => <AttendanceView {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                            />
                            <Redirect from="/view" to="/view/week" />
                        </Switch>
                    </div>
                }
            </>
        );
    }
};

export default connect(
    state => ({
        profile: state.profile,
        path: state.router.location.pathname
    }),
    {
        push
    }
)(ViewContent);