import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Modal from '../../../components/Modal';
import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import { Constants } from '../../../helpers/Constants';


class Classroom extends React.Component {
    static propTypes = {
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.initModal = {
            open: false,
            id: '',
            name: '',
            address: '',
            description: '',
            imgId: '',
            file: null,
            imgUrl: '/img/classroom.png'
        };
        this.state = {
            classrooms: {},
            selected: '',
            modal: this.initModal
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadClassrooms();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Header.Classrooms');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadClassrooms() {
        this.api.getClassrooms()
            .then(response => {
                let classrooms = _.mapKeys(response.data, 'id');
                if (this.mounted) {
                    this.setState({ classrooms });
                }
            })
            .catch(error => {
                console.log('Classroom.loadClassrooms', error);
            });
    }

    submitModal() {
        let { id, name, address, description, imgId, file } = this.state.modal;

        if (this.validateModal()) {
            if (id) {
                this.api.updateClassroom(id, name, address, description, imgId, file)
                    .then(response => {
                        this.loadClassrooms();
                        this.closeModal();
                        toastr.success(this.context.translate('Classroom.EditSuccess'));
                    })
                    .catch(error => {
                        console.log('Classroom.submitModal error update classroom', error);
                        toastr.error(error);
                    });
            } else {
                this.api.addClassroom(name, address, description, file)
                    .then(response => {
                        this.loadClassrooms();
                        this.closeModal();
                        toastr.success(this.context.translate('Classroom.AddSuccess'));
                    })
                    .catch(error => {
                        console.log('Classroom.submitModal error add classroom', error);
                        toastr.error(error);
                    });
            }
        }
    }

    validateModal() {
        return true;
    }

    add() {
        this.setState({
            modal: {
                ...this.initModal,
                open: true
            }
        });
    }

    update(id) {
        id = id || this.state.selected;
        if (!id) {
            return;
        }

        let { name, address, description, imgId } = this.state.classrooms[id] || {};
        this.setState({
            modal: {
                ...this.initModal,
                open: true,
                id,
                name: name || '',
                address: address || '',
                description: description || '',
                imgId: imgId || '',
                imgUrl: imgId ? `${Constants.apiRoot}img/${imgId}` : this.initModal.imgUrl
            },
            selected: id
        });
    }

    delete(id) {
        id = id || this.state.selected;
        if (!id) {
            return;
        }

        if (window.confirm(this.context.translate('Classroom.ConfirmDelete'))) {
            this.api.deleteClassroom(id)
                .then(response => {
                    toastr.success(this.context.translate('Classroom.DeleteSuccess'));
                    if (this.mounted) {
                        this.loadClassrooms();
                        this.setState({ selected: '' });
                    }
                })
                .catch(error => {
                    console.log('Classroom.delete error delete classroom', error);
                    toastr.error(error);
                });
        }
    }

    select(key) {
        this.setState({ selected: key === this.state.selected ? null : key });
    }

    closeModal() {
        this.setState({ modal: { ...this.initModal } });
    }

    loadPhoto(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (files && files.length) {
            let maxInsertImageFileSize = 6 * 1024 * 1024; // 6MB
            let allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/gif'];

            let file = files[0];
            if (allowedTypes.indexOf(file.type) < 0) {
                toastr.error(this.context.translate('Img.UnsupportedFileType'));
                return;
            }
            if (file.size > maxInsertImageFileSize) {
                toastr.error(this.context.translate('Img.MaxFileSize6'));
                return;
            }

            this.setState({ modal: { ...this.state.modal, file, imgUrl: URL.createObjectURL(file) } });
        }
    }

    clearPhoto() {
        this.setState({ modal: { ...this.state.modal, file: null, imgUrl: this.initModal.imgUrl, imgId: null } });
    }

    render() {
        let { classrooms, selected, modal } = this.state;

        let items = arraySort(_.map(classrooms), 'name')
        let isSelected = !!selected;

        return (
            <div className="page-container simple-page">
                <div className="page-menu">
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.add()}
                        >{this.context.translate('Btn.Add')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.update()}
                        >{this.context.translate('Btn.Update')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.delete()}
                        >{this.context.translate('Btn.Delete')}</div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-name" />
                            <col className="col-address" />
                            <col className="col-description" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{this.context.translate('Classroom.Name')}</th>
                                <th>{this.context.translate('Classroom.Address')}</th>
                                <th>{this.context.translate('Classroom.Description')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => {
                                return (
                                    <tr
                                        key={o.id}
                                        className={classnames({ selected: selected === o.id })}
                                        onClick={() => this.select(o.id)}
                                        onDoubleClick={() => this.update(o.id)}
                                    >
                                        <td>{o.name}</td>
                                        <td className="multiline">{o.address}</td>
                                        <td className="multiline">{o.description}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Modal
                    modalClass="classroom-modal"
                    onClose={() => this.closeModal()}
                    onSubmit={() => this.submitModal()}
                    title={this.context.translate('Classroom.Title')}
                    show={modal.open}
                    closeOnClick={false}
                >
                    <div>
                        <fieldset>
                            <div className="form-group">
                                <label htmlFor="name">{this.context.translate('Classroom.Name')}</label>
                                <input
                                    className="form-control"
                                    name="name"
                                    type="text"
                                    autoFocus
                                    value={this.state.modal.name}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, name: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Classroom.Address')}</label>
                                <textarea
                                    className="form-control"
                                    rows="3"
                                    value={modal.address}
                                    onChange={e => this.setState({ modal: { ...modal, address: e.target.value } })}
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Classroom.Description')}</label>
                                <textarea
                                    className="form-control"
                                    rows="2"
                                    value={modal.description}
                                    onChange={e => this.setState({ modal: { ...modal, description: e.target.value } })}
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="photo">{this.context.translate('Classroom.Photo')}</label>
                                <div className="photo-input">
                                    <img src={modal.imgUrl} alt={this.context.translate('Classroom.Photo')} />
                                    <div className="img-buttons">
                                        <button
                                            className="btn btn-light import-btn"
                                            onClick={() => this.refs.photo.click()}
                                        >
                                            <input
                                                name="photo"
                                                type="file"
                                                ref="photo"
                                                accept="image/gif,image/jpeg,image/png,image/bmp"
                                                onChange={e => this.loadPhoto(e)}
                                                onClick={e => e.stopPropagation()}
                                            />
                                            <span>{this.context.translate('Classroom.LoadPhoto')}</span>
                                        </button>
                                        <button
                                            className="btn btn-light"
                                            onClick={() => this.clearPhoto()}
                                        >{this.context.translate('Btn.Delete')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn btn-imsp"
                                    onClick={() => this.submitModal()}
                                >{this.context.translate('Btn.Save')}</button>
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeModal()}
                                >{this.context.translate('Btn.Cancel')}</button>
                            </div>
                        </fieldset>
                    </div>
                </Modal>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Classroom);