import _ from 'lodash';
import * as toastr from 'toastr';
import classnames from 'classnames';
import schoolYear, { getSchyFilterOptions } from '../../../utils/schoolYear';
import React, { useContext } from 'react';
import Select from 'react-select';
import { useState } from 'react';
import { ApiService } from '../../../services/ApiService';
import { AppContext } from '../../AppContext';
import { useEffect } from 'react';
import ClassMeetingModal from './ClassMeetingModal';
import { getCourseGroupList } from '../../../utils/course';


const ClassMeeting = ({ onTitle }) => {

    const defModal = { open: false, ts: null, id: null };

    let { translate } = useContext(AppContext);
    let [schyFilter, setSchyFilter] = useState({ value: schoolYear().year(), label: schoolYear().yearText() });
    let [meetings, setMeetings] = useState([]);
    let [selected, setSelected] = useState(null);
    let [modal, setModal] = useState(defModal);

    let api = new ApiService();

    useEffect(() => {
        if (onTitle) onTitle('ClassMeetings.Title');
    }, [onTitle])

    useEffect(() => {
        loadMeetings();
    }, [schyFilter]);

    useEffect(() => {
        if (selected && selected.id) {
            let item = _.find(meetings, { id: selected.id });
            setSelected(item);
        }
    }, [meetings]);

    const loadMeetings = async () => {
        try {
            let response = await api.getClassMeetings({ year: schyFilter.value });
            let data = response.data || [];
            setMeetings(data);
        } catch (error) {
            console.log(error);
        }
    }

    const onSchyFilterChange = value => {
        setSchyFilter(value);
    }

    const onAdd = () => {
        setModal({ ...modal, open: true, ts: new Date().getTime(), id: 'new' });
    }

    const onUpdate = () => {
        if (!selected) {
            return;
        }

        setModal({ ...modal, open: true, ts: new Date().getTime(), id: selected.id });
    }

    const onDelete = async () => {
        if (selected && selected.id && window.confirm(translate('ClassMeetings.DeleteConfirm'))) {
            try {
                await api.deleteClassMeeting(selected.id);
                toastr.success(translate('ClassMeetings.DeletedSuccess'));
                setMeetings(meetings.filter(o => o.id != selected.id));
                loadMeetings();
            } catch (error) {
                console.log(error);
            }
        }
    }

    const onModalCancel = () => {
        setModal({ ...modal, ...defModal });
    }

    const onModalSubmit = () => {
        loadMeetings();
        onModalCancel();
    }

    const onRowClick = item => {
        setSelected(selected && selected.id === item.id ? null : item);
    }

    const onRowDoubleClick = item => {
        setSelected(item);
        setModal({ ...modal, open: true, ts: new Date().getTime(), id: item.id });
    }

    let schyFilterOptions = getSchyFilterOptions({ from: 2020, relTo: 1 });

    return (
        <div className="page-container simple-page course-page">
            <div className="page-menu">
                <div className="filter">
                    <div className="filter-group">
                        <label>{translate('General.SchoolYear')}</label>
                        <Select
                            value={schyFilter}
                            options={schyFilterOptions}
                            onChange={onSchyFilterChange}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </div>
                </div>
                <div className="buttons">
                    <div
                        className="btn btn-imsp"
                        onClick={onAdd}
                    >{translate('Btn.Add')}</div>
                    <div
                        className={classnames("btn btn-imsp", { disabled: !selected })}
                        onClick={onUpdate}
                    >{translate('Btn.Update')}</div>
                    <div
                        className={classnames("btn btn-imsp", { disabled: !selected })}
                        onClick={onDelete}
                    >{translate('Btn.Delete')}</div>
                </div>
            </div>
            <div className="page-content">
                <table className="imsp-table">
                    <colgroup>
                        <col className="col-name" />
                        <col className="col-group" />
                        <col className="col-teacher" />
                        <col className="col-classroom" />
                        <col className="col-yesno" />
                        <col className="col-yesno" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{translate('ClassMeeting.Name')}</th>
                            <th>{translate('ClassMeeting.Group')}</th>
                            <th>{translate('ClassMeeting.Teacher')}</th>
                            <th>{translate('Classroom.Title')}</th>
                            <th className="ta-c">{translate('ClassMeeting.IsDisabled')}</th>
                            <th className="ta-c">{translate('ClassMeeting.IsHidden')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {meetings.map(o => {
                            return (
                                <tr
                                    key={o.id}
                                    className={classnames({ selected: selected && selected.id === o.id })}
                                    onClick={() => onRowClick(o)}
                                    onDoubleClick={() => onRowDoubleClick(o)}
                                >
                                    <td>{o.name}</td>
                                    <td>{getCourseGroupList(o)}</td>
                                    <td>{o.teacher}</td>
                                    <td>{o.classroom}</td>
                                    <td className="ta-c">{translate(`Codelist.${o.isDisabled ? 'Yes' : 'No'}`)}</td>
                                    <td className="ta-c">{translate(`Codelist.${(o.isHidden || false) ? 'Yes' : 'No'}`)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {modal.open &&
                <ClassMeetingModal
                    key={modal.ts}
                    id={modal.id}
                    onSubmit={onModalSubmit}
                    onCancel={onModalCancel}
                />
            }
        </div>
    );
}

export default ClassMeeting;