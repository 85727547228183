import * as toastr from 'toastr';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { AppContext } from '../../containers/AppContext';
import { Constants } from '../../helpers/Constants';


const ImgControl = ({ value, onChange }) => {

    const inputEl = useRef(null);

    let { translate } = useContext(AppContext);

    let [id, setId] = useState(null);
    let [url, setUrl] = useState(null);
    let [file, setFile] = useState(null);

    useEffect(() => {
        setId(value && value.id);
        if (value && value.id && !value.file) {
            setUrl(`${Constants.apiRoot}img/${value.id}`);
        }
    }, [value]);

    const onLoad = (e) => {
        let files = e.target.files || e.dataTransfer.files;
        if (files && files.length) {
            let maxInsertImageFileSize = 6 * 1024 * 1024; // 6MB
            let allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/gif'];

            let file = files[0];
            if (allowedTypes.indexOf(file.type) < 0) {
                toastr.error(translate('Img.UnsupportedFileType'));
                return;
            }
            if (file.size > maxInsertImageFileSize) {
                toastr.error(translate('Img.MaxFileSize6'));
                return;
            }

            setFile(file);
            setUrl(URL.createObjectURL(file));
            if (onChange) onChange({ id, file })
        }
    }

    const onClear = () => {
        setFile(null);
        setUrl(null);
        setId(null);
        if (onChange) onChange({ id: null, file: null })
    }

    return (
        <div className="form-group">
            <label>{translate('ImgControl.Label')}</label>
            <div className="photo-input">
                <img src={url || '/img/classroom.png'} alt={translate('ImgControl.Label')} />
                <div className="img-buttons">
                    <button
                        className="btn btn-light import-btn"
                        onClick={() => inputEl.current.click()}
                    >
                        <input
                            type="file"
                            ref={inputEl}
                            accept="image/gif,image/jpeg,image/png,image/bmp"
                            onChange={onLoad}
                            onClick={e => e.stopPropagation()}
                        />
                        <span>{translate('ImgControl.Load')}</span>
                    </button>
                    <button
                        className="btn btn-light"
                        onClick={onClear}
                    >{translate('Btn.Delete')}</button>
                </div>
            </div>
        </div>
    );
}

export default ImgControl;