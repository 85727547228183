import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import memoize from 'memoize-one';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from "react-redux";

import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import Modal from '../../../components/Modal';

import "react-datepicker/dist/react-datepicker.css";
import { parseDate, toModalDateTime, toTerm, toPaymentDateTime, getDtMinutes, addDay, toDate } from '../../../utils/date';
import { Constants } from '../../../helpers/Constants';
import { SmallBtn } from '../../../components/smallbtn/SmallBtn';
import { getCourseGroupList } from '../../../utils/course';
import { errorEquals, ERR_INVALID_COURSE_CAPACITY } from '../../../utils/error';

import './course.scss';
import schoolYear from '../../../utils/schoolYear';
import { setStateAsync } from '../../../utils/async';
import NotificationsControl from '../../../components/NotificationsControl';
import RichTextEditor from 'react-rte';
import { getRteValue, toolbarConfig } from '../../../utils/rte';
import DateTimeControl from '../../../components/DateTimeControl/DateTimeControl';


class Course extends React.Component {
    static propTypes = {
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props, context) {
        super(props);

        this.initModal = {
            open: false,
            id: '',
            name: '',
            description: RichTextEditor.createEmptyValue(),
            capacity: '',
            alternate: '',
            teacherId: '',
            openFrom: null,
            openTo: null,
            classroomId: '',
            price: '',
            isExternal: false,
            equipments: '',
            imgId: '',
            file: null,
            imgUrl: '/img/classroom.png',
            lessons: [],
            groupIds: [],
            paymentInfo: '',
            isDisabled: true,
            isHidden: false,
            order: null,
            year: schoolYear().year(),
            notifications: []
        };
        this.state = {
            courses: {},
            teachers: [],
            classrooms: [],
            groups: [],
            selected: '',
            modal: this.initModal,
            hiddenFilter: '',
            disabledFilter: '',
            schyFilter: { value: schoolYear().year(), label: schoolYear().yearText() }
        };
        this.mounted = false;
        this.api = new ApiService();

        this.memGetTeacherOptions = memoize(this.getTeacherOptions);
        this.memGetClassroomOptions = memoize(this.getClassroomOptions);
        this.memGetCourseList = memoize(this.getCourseList);
        this.memGetGroupOptions = memoize(this.getGroupOptions);

        this.yesno = {
            true: context.translate('Codelist.Yes'),
            false: context.translate('Codelist.No')
        }
    }

    componentWillMount() {
        this.loadCourses();
        this.loadTeachers();
        this.loadClassrooms();
        this.loadGroups();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('AdminCourses.Title');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadCourses() {
        this.api.getCourses({ schoolYear: this.state.schyFilter.value })
            .then(response => {
                let courses = _.mapKeys(response.data, 'id');
                if (this.mounted) {
                    this.setState({ courses });
                }
            })
            .catch(error => {
                console.log('Course.loadCourses', error);
            });
    }

    async loadCourseDetail(id) {
        let { courses } = this.state;

        try {
            let response = await this.api.getCourseAdminDetail(id);
            let course = response.data || {};
            courses[id] = { ...(courses[id] || {}), ...course };
            courses = { ...courses };
            await setStateAsync(this, { courses });
        } catch (error) {
            console.log(error)
        }
    }

    loadTeachers() {
        this.api.getTeachers()
            .then(response => {
                let teachers = response.data || [];
                if (this.mounted) {
                    this.setState({ teachers });
                }
            })
            .catch(error => {
                console.log('Course.loadTeachers', error);
            });
    }

    loadClassrooms() {
        this.api.getClassrooms()
            .then(response => {
                let classrooms = response.data || [];
                if (this.mounted) {
                    this.setState({ classrooms });
                }
            })
            .catch(error => {
                console.log('Course.loadClassrooms', error);
            });
    }

    loadGroups() {
        this.api.getGroups()
            .then(response => {
                let groups = response.data || [];
                if (this.mounted) {
                    this.setState({ groups });
                }
            })
            .catch(error => {
                console.log('Course.loadGroups', error);
            });
    }

    add() {
        this.setState({
            modal: {
                ...this.initModal,
                open: true
            }
        });
    }

    async update(id) {
        let { selected, courses } = this.state;

        id = id || selected;
        if (!id) {
            return;
        }

        await this.loadCourseDetail(id);

        let {
            name, imgId, capacity, alternate, teacherId, lessons, openFrom, openTo,
            classroomId, price, isExternal, description, equipments, groups,
            paymentInfo, isDisabled, isHidden, order, year, notifications } = courses[id] || {};
        let groupIds = (groups || []).map(o => o.id);
        lessons = (lessons || []).map(o => ({ ...o, key: o.id }));
        await setStateAsync(this, {
            modal: {
                ...this.initModal,
                open: true,
                id,
                name: name || this.initModal.name,
                capacity,
                alternate: alternate || this.initModal.alternate,
                teacherId: teacherId || this.initModal.teacherId,
                lessons: lessons || this.initModal.lessons,
                openFrom: openFrom || this.initModal.openFrom,
                openTo: openTo || this.initModal.openTo,
                classroomId: classroomId || this.initModal.classroomId,
                price: price || this.initModal.price,
                isExternal: isExternal || this.initModal.isExternal,
                description: description ? getRteValue(description) : this.initModal.description,
                equipments: equipments || this.initModal.equipments,
                groupIds: groupIds || this.initModal.groupIds,
                imgId: imgId || this.initModal.imgId,
                imgUrl: imgId ? `${Constants.apiRoot}img/${imgId}` : this.initModal.imgUrl,
                paymentInfo: paymentInfo || this.initModal.paymentInfo,
                isDisabled: !isDisabled,
                isHidden: !!isHidden,
                order,
                year,
                notifications: [...(notifications || []).map(o => ({ ...o }))]
            },
            selected: id
        });
    }

    closeModal() {
        this.setState({ modal: { ...this.initModal } });
    }

    submit() {
        if (this.isValid()) {
            let {
                id, name, imgId, file, capacity, alternate, teacherId, lessons,
                openFrom, openTo, classroomId, price, isExternal, description, equipments,
                groupIds, paymentInfo, isDisabled, isHidden, order, year, notifications
            } = this.state.modal;
            order = order === undefined || order === null || order === '' ? null : order;
            let data = {
                name, capacity, alternate, teacherId, lessons, openFrom, openTo, classroomId, price, isExternal,
                description: description.toString('html'),
                equipments, groupIds, paymentInfo, isDisabled: !isDisabled, isHidden, order, year, notifications
            };

            if (id) {
                this.api.updateCourse(id, data)
                    .then(response => {
                        if (file) {
                            this.api.setCourseImage(id, file).then(r => {
                                this.onSuccessEdit();
                            }).catch(error => this.onError());
                        } else if (!imgId && response.data.imgId) {
                            this.api.deleteCourseImage(id).then(r => {
                                this.onSuccessEdit();
                            }).catch(error => this.onError());
                        } else {
                            this.onSuccessEdit();
                        }
                    })
                    .catch(error => {
                        if (this.mounted) {
                            if (errorEquals(error, ERR_INVALID_COURSE_CAPACITY)) {
                                toastr.error(this.context.translate('Course.InvalidCapacity'));
                            } else {
                                toastr.error(this.context.translate('Err.System'));
                            }
                        }
                    });
            } else {
                this.api.addCourse(data)
                    .then(response => {
                        if (file && response.data.id) {
                            this.api.setCourseImage(response.data.id, file).then(r => {
                                this.onSuccessAdd();
                            }).catch(error => this.onError());
                        } else {
                            this.onSuccessAdd();
                        }
                    })
                    .catch(error => this.onError());
            }
        }
    }

    onSuccessEdit() {
        this.loadCourses();
        this.closeModal();
        toastr.success(this.context.translate('Course.EditSuccess'));
    }

    onSuccessAdd() {
        this.loadCourses();
        this.closeModal();
        toastr.success(this.context.translate('Course.AddSuccess'));
    }

    onError() {
        if (this.mounted) {
            toastr.error(this.context.translate('Err.System'));
        }
    }

    delete(id) {
        id = id || this.state.selected;
        if (!id) {
            return;
        }

        if (window.confirm(this.context.translate('Course.ConfirmDelete'))) {
            this.api.deleteCourse(id)
                .then(response => {
                    toastr.success(this.context.translate('Course.DeleteSuccess'));
                    if (this.mounted) {
                        this.loadCourses();
                        this.setState({ selected: '' });
                    }
                })
                .catch(error => {
                    console.log('Course.delete error delete course', error);
                    toastr.error(error);
                });
        }
    }

    isValid() {
        let { name } = this.state.modal;
        if (!name) {
            return false;
        }

        return true;
    }

    loadPhoto(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (files && files.length) {
            let maxInsertImageFileSize = 6 * 1024 * 1024; // 6MB
            let allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/gif'];

            let file = files[0];
            if (allowedTypes.indexOf(file.type) < 0) {
                toastr.error(this.context.translate('Img.UnsupportedFileType'));
                return;
            }
            if (file.size > maxInsertImageFileSize) {
                toastr.error(this.context.translate('Img.MaxFileSize6'));
                return;
            }

            this.setState({ modal: { ...this.state.modal, file, imgUrl: URL.createObjectURL(file) } });
        }
    }

    clearPhoto() {
        this.setState({ modal: { ...this.state.modal, file: null, imgUrl: this.initModal.imgUrl, imgId: null } });
    }

    getTeacherOptions(teachers) {
        var result = arraySort([...teachers], 'name');
        result.splice(0, 0, { id: '', name: '' });
        return result;
    }

    getClassroomOptions(classrooms) {
        var result = arraySort([...classrooms], 'name');
        result.splice(0, 0, { id: '', name: '' });
        return result;
    }

    getGroupOptions(groups, selected) {
        var result = groups.map(o => ({ ...o, selected: selected.includes(o.id) }));
        result = arraySort(result, { prop: 'selected', order: 'desc' }, { prop: 'name', order: 'asc' });
        return result;
    }

    getCourseList(courses, disabledFilter, hiddenFilter) {
        let result = _.map(courses);
        if (disabledFilter) {
            result = result.filter(o => `${o.isDisabled}` !== disabledFilter);
        }
        if (hiddenFilter) {
            result = result.filter(o => `${o.isHidden}` === hiddenFilter);
        }
        result = arraySort(result, 'order', 'name');
        return result;
    }

    select(key) {
        this.setState({ selected: key === this.state.selected ? null : key });
    }

    getLessonItems(lessons) {
        let ids = [1, 1, 1, 1];
        let result = arraySort(lessons, 'time').map((o, i) => {
            let term = toTerm(o.time);
            let order = ids[term.id];
            ids[term.id] = ids[term.id] + 1;
            return {
                ...o,
                order,
                term
            };
        });
        // console.log('getLessonItems', { lessons, result });
        return result;
    }

    getYesNoFilterOptions() {
        let result = [
            { id: '', name: this.context.translate('Codelist.All') },
            { id: false, name: this.yesno[false] },
            { id: true, name: this.yesno[true] }
        ];
        return result;
    }

    getSchyOptions(year) {
        let curYear = schoolYear().year();
        if (year < curYear) {
            return [
                { value: year, label: schoolYear(year).yearText() }
            ];
        }

        let result = [
            { value: curYear, label: schoolYear(curYear).yearText() },
            { value: curYear + 1, label: schoolYear(curYear + 1).yearText() }
        ];
        return result;
    }

    getSchyFilterOptions() {
        let curYear = schoolYear().year();
        let result = [];
        for (let i = 2020; i <= curYear + 1; i++) {
            result.push({ value: i, label: schoolYear(i).yearText() })
        }
        return result;
    }

    render() {
        let { translate } = this.context;
        let { courses, selected, modal, teachers, classrooms, groups, hiddenFilter, disabledFilter, schyFilter } = this.state;

        let items = this.memGetCourseList(courses, disabledFilter, hiddenFilter);
        let isSelected = !!selected;
        let teacherOptions = this.memGetTeacherOptions(teachers);
        let classroomOptions = this.memGetClassroomOptions(classrooms);
        let groupOptions = this.memGetGroupOptions(groups, modal.groupIds);
        let lessonItems = this.getLessonItems(modal.lessons);
        let openFrom = modal.openFrom ? parseDate(modal.openFrom) : undefined;
        let saveDisabled = !this.isValid();
        let yesNoFilterOptions = this.getYesNoFilterOptions();

        let schyOptions = this.getSchyOptions(modal.year);
        let schyFilterOptions = this.getSchyFilterOptions();

        // console.log('Course.render', { openFrom });

        return (
            <div className="page-container simple-page course-page">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('General.SchoolYear')}</label>
                            <Select
                                value={schyFilter}
                                options={schyFilterOptions}
                                onChange={schyFilter => this.setState({ schyFilter }, () => {
                                    this.loadCourses();
                                })}
                                menuPortalTarget={document.querySelector('body')}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Disabled')}</label>
                            <select
                                className="form-control"
                                value={disabledFilter}
                                onChange={e => this.setState({ disabledFilter: e.target.value })}
                            >
                                {yesNoFilterOptions.map(o => (
                                    <option key={o.id} value={o.id}>{o.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Hidden')}</label>
                            <select
                                className="form-control"
                                value={hiddenFilter}
                                onChange={e => this.setState({ hiddenFilter: e.target.value })}
                            >
                                {yesNoFilterOptions.map(o => (
                                    <option key={o.id} value={o.id}>{o.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.add()}
                        >{this.context.translate('Btn.Add')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.update()}
                        >{this.context.translate('Btn.Update')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.delete()}
                        >{this.context.translate('Btn.Delete')}</div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-name" />
                            <col className="col-group" />
                            <col className="col-teacher" />
                            <col className="col-title" />
                            <col className="col-capacity" />
                            <col className="col-order" />
                            <col className="col-yesno" />
                            <col className="col-yesno" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{this.context.translate('Course.Name')}</th>
                                <th>{this.context.translate('Course.GroupCol')}</th>
                                <th>{this.context.translate('Course.Teacher')}</th>
                                <th>{this.context.translate('Classroom.Title')}</th>
                                <th>{this.context.translate('Course.Capacity')}</th>
                                <th className="ta-c">{this.context.translate('Course.Order')}</th>
                                <th className="ta-c">{this.context.translate('Course.IsDisabled')}</th>
                                <th className="ta-c">{this.context.translate('Course.IsHidden')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => {
                                let capacity = o.capacity !== null ? `${o.capacity}` : this.context.translate('Course.All');
                                if (o.capacity !== null && o.alternate > 0) {
                                    capacity = `${capacity} + ${o.alternate}`;
                                }

                                return (
                                    <tr
                                        key={o.id}
                                        className={classnames({ selected: selected === o.id })}
                                        onClick={() => this.select(o.id)}
                                        onDoubleClick={() => this.update(o.id)}
                                    >
                                        <td>{o.name}</td>
                                        <td>{getCourseGroupList(o)}</td>
                                        <td>{o.teacherName}</td>
                                        <td>{o.classroomName}</td>
                                        <td>{capacity}</td>
                                        <td className="ta-r">{o.order}</td>
                                        <td className="ta-c">{this.yesno[!o.isDisabled]}</td>
                                        <td className="ta-c">{this.yesno[o.isHidden || false]}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Modal
                    modalClass="course-modal"
                    onClose={() => this.closeModal()}
                    onSubmit={() => this.submitModal()}
                    title={this.context.translate('Course.Title')}
                    show={modal.open}
                    closeOnClick={false}
                    showCloseButton
                >
                    <div>
                        <fieldset>
                            <div className="form-group">
                                <label>{this.context.translate('General.SchoolYear')}</label>
                                <Select
                                    value={{ value: modal.year, label: schoolYear(modal.year).yearText() }}
                                    options={schyOptions}
                                    onChange={year => this.setState({ modal: { ...modal, year: year.value } })}
                                    isDisabled={schyOptions.length === 1}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">{this.context.translate('Course.Name')}</label>
                                <input
                                    className="form-control"
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    autoFocus
                                    value={modal.name}
                                    onChange={e => this.setState({ modal: { ...modal, name: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">{this.context.translate('Course.Description')}</label>
                                <RichTextEditor
                                    name="description"
                                    value={modal.description}
                                    onChange={description => this.setState({ modal: { ...modal, description } })}
                                    toolbarConfig={toolbarConfig}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="capacity">{this.context.translate('Course.Capacity')}</label>
                                <input
                                    className="form-control"
                                    name="capacity"
                                    type="number"
                                    value={modal.capacity}
                                    onChange={e => this.setState({ modal: { ...modal, capacity: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="alternat">{this.context.translate('Course.Alternate')}</label>
                                <input
                                    className="form-control"
                                    name="alternat"
                                    type="number"
                                    autoComplete="off"
                                    value={modal.alternate}
                                    onChange={e => this.setState({ modal: { ...modal, alternate: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="teacher">{this.context.translate('Course.Teacher')}</label>
                                <select
                                    className="form-control"
                                    name="teacher"
                                    value={modal.teacherId}
                                    onChange={e => this.setState({ modal: { ...modal, teacherId: e.target.value } })}
                                >
                                    {teacherOptions.map(o => (
                                        <option key={o.id} value={o.id}>{o.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="openFrom">{this.context.translate('Course.OpenFrom')}</label>
                                <DatePicker
                                    name="openFrom"
                                    ref="openFrom"
                                    className="form-control"
                                    dateFormat={["d. M. yyyy HH:mm", "d.M.yyyy HH:mm", "d. M.yyyy HH:mm", "d.M. yyyy HH:mm"]}
                                    showTimeSelect
                                    selected={openFrom}
                                    onChange={date => {
                                        // console.log('onChange', this.refs.openFrom);
                                        this.setState({ modal: { ...modal, openFrom: toModalDateTime(date) } });
                                        if (!this.refs.openFrom.isCalendarOpen()) {
                                            this.refs.openFrom.setOpen(false, true);
                                            this.refs.openFrom.setFocus(true);
                                        }
                                    }}
                                    onInputClick={() => {
                                        if (this.refs.openFrom.isCalendarOpen()) {
                                            this.refs.openFrom.setOpen(false, true);
                                            this.refs.openFrom.setFocus(true);
                                        }
                                    }}
                                    isClearable
                                    shouldCloseOnSelect={false}
                                    preventOpenOnFocus={true}
                                    placeholderText="d. M. yyyy HH:mm"
                                    strictParsing={true}
                                />
                            </div>
                            <div className="form-group">
                                <label>{translate('Course.OpenTo')}</label>
                                <DateTimeControl
                                    className="form-control"
                                    value={modal.openTo}
                                    onChange={openTo => this.setState({ modal: { ...modal, openTo }})}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="classroom">{this.context.translate('Classroom.Title')}</label>
                                <select
                                    className="form-control"
                                    name="classroom"
                                    value={modal.classroomId}
                                    onChange={e => this.setState({ modal: { ...modal, classroomId: e.target.value } })}
                                >
                                    {classroomOptions.map(o => (
                                        <option key={o.id} value={o.id}>{o.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="price">{this.context.translate('Course.Price')}</label>
                                <input
                                    className="form-control"
                                    name="price"
                                    type="number"
                                    autoComplete="off"
                                    value={modal.price}
                                    onChange={e => this.setState({ modal: { ...modal, price: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="isExternal">{this.context.translate('Course.IsExternal')}</label>
                                <select
                                    className="form-control"
                                    name="isExternal"
                                    value={modal.isExternal}
                                    onChange={e => this.setState({ modal: { ...modal, isExternal: e.target.value } })}
                                >
                                    {Object.keys(this.yesno).map(o => (
                                        <option key={o} value={o}>{this.yesno[o]}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="paymentInfo">{this.context.translate('Course.PaymentInfo')}</label>
                                <textarea
                                    className="form-control"
                                    name="paymentInfo"
                                    rows="3"
                                    value={modal.paymentInfo}
                                    onChange={e => this.setState({ modal: { ...modal, paymentInfo: e.target.value } })}
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="group">{this.context.translate('Course.Group')}</label>
                                <div className="form-control multiselect">
                                    {groupOptions.map((o, i) => {
                                        return (
                                            <div
                                                className={classnames('item', { selected: o.selected })}
                                                key={i}
                                                onClick={() => {
                                                    let groupIds = [];
                                                    if (modal.groupIds.includes(o.id)) {
                                                        groupIds = modal.groupIds.filter(i => i !== o.id);
                                                    } else {
                                                        groupIds = [o.id, ...modal.groupIds];
                                                    }
                                                    this.setState({ modal: { ...modal, groupIds } });
                                                }}
                                            >
                                                <span className="content">{o.name}</span>
                                                <i className={o.selected ? "fa fa-check" : "fa fa-times"} ></i>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="equipments">{this.context.translate('Course.Equipments')}</label>
                                <textarea
                                    className="form-control"
                                    name="equipments"
                                    rows="2"
                                    value={modal.equipments}
                                    onChange={e => this.setState({ modal: { ...modal, equipments: e.target.value } })}
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Course.IsDisabled')}</label>
                                <select
                                    className="form-control"
                                    value={modal.isDisabled}
                                    onChange={e => this.setState({ modal: { ...modal, isDisabled: e.target.value === "true" } })}
                                >
                                    {Object.keys(this.yesno).map(o => (
                                        <option key={o} value={o}>{this.yesno[o]}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Course.IsHidden')}</label>
                                <select
                                    className="form-control"
                                    value={modal.isHidden}
                                    onChange={e => this.setState({ modal: { ...modal, isHidden: e.target.value } })}
                                >
                                    {Object.keys(this.yesno).map(o => (
                                        <option key={o} value={o}>{this.yesno[o]}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="order">{this.context.translate('Course.Order')}</label>
                                <input
                                    className="form-control"
                                    name="order"
                                    type="number"
                                    autoComplete="off"
                                    value={modal.order || modal.order === 0 ? modal.order : ''}
                                    onChange={e => this.setState({ modal: { ...modal, order: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <div className="label-btn">
                                    <label htmlFor="lessons">{this.context.translate('Course.Lessons')}</label>
                                    <SmallBtn
                                        title={this.context.translate('Btn.Add')}
                                        ico="fa fa-plus"
                                        onClick={() => {
                                            let key = new Date().getTime();
                                            let lessons = [...modal.lessons, { time: toModalDateTime(getDtMinutes()), key }];
                                            this.setState({ modal: { ...modal, lessons } });
                                        }}
                                    />
                                    <SmallBtn
                                        title={this.context.translate('Course.CreateLessons')}
                                        ico="fa fa-refresh"
                                        disabled={modal.lessons.length !== 1}
                                        onClick={() => {
                                            let lessons = [modal.lessons[0]];
                                            let dt = toDate(lessons[0].time);
                                            let key = new Date().getTime();
                                            do {
                                                dt = addDay(dt, 7);
                                                if (toTerm(dt).id) {
                                                    lessons.push({ time: toModalDateTime(dt), key });
                                                    key++;
                                                }
                                            } while (toTerm(dt).id)
                                            this.setState({ modal: { ...modal, lessons } });
                                        }}
                                    />
                                </div>
                                <div className="form-control lessons-control">
                                    {lessonItems.map((o, i) => {
                                        let edit = false;
                                        let term = o.term.name;
                                        return (
                                            <div
                                                className="item"
                                                key={i}
                                            >
                                                <div className="term-id">{term} / {o.order}</div>
                                                {!edit ? (
                                                    <DatePicker
                                                        ref={o.key}
                                                        dateFormat={["d. M. yyyy HH:mm", "d.M.yyyy HH:mm", "d. M.yyyy HH:mm", "d.M. yyyy HH:mm"]}
                                                        showTimeSelect
                                                        selected={parseDate(o.time)}
                                                        onChange={date => {
                                                            // console.log('onChange', this.refs[o.key]);
                                                            let lessons = [...modal.lessons];
                                                            lessons[i].time = toModalDateTime(date);
                                                            this.setState({ modal: { ...modal, lessons } });
                                                            if (!this.refs[o.key].isCalendarOpen()) {
                                                                this.refs[o.key].setOpen(false, true);
                                                                this.refs[o.key].setFocus(true);
                                                            }
                                                        }}
                                                        onInputClick={() => {
                                                            if (this.refs[o.key].isCalendarOpen()) {
                                                                this.refs[o.key].setOpen(false, true);
                                                                this.refs[o.key].setFocus(true);
                                                            }
                                                        }}
                                                        shouldCloseOnSelect={false}
                                                        preventOpenOnFocus={true}
                                                        placeholderText="d. M. yyyy HH:mm"
                                                        strictParsing={true}
                                                    />
                                                ) : (
                                                    <div className="value">{toPaymentDateTime(o.value)}</div>
                                                )}
                                                <SmallBtn
                                                    title={this.context.translate('Btn.Delete')}
                                                    ico="fa fa-minus"
                                                    onClick={() => {
                                                        let lessons = [...modal.lessons];
                                                        lessons = lessons.filter(l => l.key !== o.key);
                                                        this.setState({ modal: { ...modal, lessons } });
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="photo">{this.context.translate('Course.Photo')}</label>
                                <div className="photo-input">
                                    <img src={modal.imgUrl} alt={this.context.translate('Course.Photo')} />
                                    <div className="img-buttons">
                                        <button
                                            className="btn btn-light import-btn"
                                            onClick={() => this.refs.photo.click()}
                                        >
                                            <input
                                                name="photo"
                                                type="file"
                                                ref="photo"
                                                accept="image/gif,image/jpeg,image/png,image/bmp"
                                                onChange={e => this.loadPhoto(e)}
                                                onClick={e => e.stopPropagation()}
                                            />
                                            <span>{this.context.translate('Course.LoadPhoto')}</span>
                                        </button>
                                        <button
                                            className="btn btn-light"
                                            onClick={() => this.clearPhoto()}
                                        >{this.context.translate('Btn.Delete')}</button>
                                    </div>
                                </div>
                            </div>
                            <NotificationsControl
                                value={modal.notifications}
                                onChange={notifications => this.setState({ modal: { ...modal, notifications } })}
                            />
                            <div className="form-group buttons">
                                <button
                                    className="btn btn-imsp"
                                    onClick={() => this.submit()}
                                    disabled={saveDisabled}
                                >{this.context.translate('Btn.Save')}</button>
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeModal()}
                                >{this.context.translate('Btn.Cancel')}</button>
                            </div>
                        </fieldset>
                    </div>
                </Modal>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Course);