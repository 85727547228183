import React from 'react';

const JobInfo = ({ job, translate }) => {
// console.log('job', job);
    if (!job) {
        return '';
    }

    let { period, student, studentName } = job;
    let name = student ? studentName || student : translate('JobInfo.All');

    return (
        <div className="job-info">
            <div className="period">{period}</div>
            <div className="name">{name}</div>
        </div>
    );
}

export default JobInfo;