export class Constants {
  // env
  static stsAuthority = process.env.REACT_APP_IDENTITY_URL;
  static apiRoot = process.env.REACT_APP_API_URL;
  static clientRoot = process.env.REACT_APP_APP_ROOT;
  

  static clientId = 'spa';
  static clientScope = 'openid profile api1';
}

// payment type
export const PT_PAYMENT = 1;
export const PT_DEPOSITE = 2;

// meal type
export const MT_SOUP = 0;
export const MT_MENU1 = 1;
export const MT_MENU2 = 2;
export const MT_MENU3 = 3;
export const MT_SNACK = 4;
export const MEAL_TYPES = [ MT_SOUP, MT_MENU1, MT_MENU2, MT_MENU3, MT_SNACK ];
