import _ from 'lodash';
import * as toastr from 'toastr';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';
import React, { Component } from "react";
import Select from 'react-select';
import { connect } from "react-redux";

import Modal from '../../../components/Modal';
import Period from '../../../components/Period';
import { arraySort } from '../../../utils/sort';
import { initPeriod, prevPeriod } from '../../../utils/period';
import { toDate, toModalDate, toPeriodDate } from '../../../utils/date';
import { ApiService } from '../../../services/ApiService';
import JobInfo from './JobInfo';

import './adminBilling.scss';


class AdminBilling extends Component {
    static propTypes = {
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            period: prevPeriod(initPeriod()),
            billings: {},
            students: {},
            info: {},
            cmOpen: false,
            cmPeriod: null,
            cmStudent: '',
            groups: [],
            groupFilter: [],
            studentFilter: []
        };
        this.api = new ApiService();
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('AdminBilling.Title');

        this.loadBillings();
        this.loadStudents();
        this.loadInfo();
        this.loadGroups();
    }

    async loadInfo() {
        try {
            let response = await this.api.getRegBillingProcessInfo();
            if (this.mounted) {
                let info = response.data || {};
                // info.runnignJob = { period: '2021-01-01', student: '9670DBEF-F9EA-4C08-B0EC-BD8AB7D5B8EB', studentName: 'Test User' };
                this.setState({ info });
                let interval = this.isInfo(info) ? 300 : 10000;
                window.setTimeout(() => {
                    this.loadInfo();
                }, interval);
            }
        } catch (error) {
            if (this.mounted) {
                toastr.error(this.context.translate('Err.System'));
            }
        }
    }

    async loadBillings() {
        try {
            let response = await this.api.getRegBillings(this.state.period);
            if (this.mounted) {
                let data = _.mapKeys(response.data, o => this.getKey(o.period, o.studentId));
                this.setState({ billings: data });
            }
        } catch (error) {
            if (this.mounted) {
                toastr.error(this.context.translate('Err.System'));
            }
        }
    }

    async loadStudents() {
        try {
            let response = await this.api.getStudents();
            if (this.mounted) {
                let students = _.mapKeys(response.data || [], 'id');
                this.setState({ students });
            }
        } catch (error) {
            if (this.mounted) {
                toastr.error(this.context.translate('Err.System'));
            }
        }
    }

    async loadGroups() {
        try {
            let response = await this.api.getGroups();
            if (this.mounted) {
                let groups = response.data || [];
                this.setState({ groups });
            }
        } catch (error) {
            if (this.mounted) {
                toastr.error(this.context.translate('Err.System'));
            }
        }
    }

    isInfo(info) {
        return (info.queue && info.queue.length) || info.runningJob;
    }

    getKey(period, student) {
        let d = toDate(period);
        return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${student}`;
    }

    onPeriodChange(period) {
        this.setState({ period }, () => {
            this.loadBillings();
        });
    }

    getStudentOptions(students, translate, emptyKey = 'Codelist.All') {
        let result = [
            { id: '', name: translate(emptyKey) },
            ...(arraySort(_.map(students), 'name'))
        ];
        return result;
    }

    getBillingItems(billings, studentFilter, groupFilter) {
        let result = _.map(billings);
        if (studentFilter && studentFilter.length) {
            let ids = studentFilter.map(o => o.value);
            result = result.filter(o => ids.includes(o.studentId));
        }
        if (groupFilter && groupFilter.length) {
            let ids = groupFilter.map(o => o.value);
            result = result.filter(o => ids.includes(o.groupId));
        }
        arraySort(result, 'userName', 'studentName');
        return result;
    }

    async runCalc() {
        let { cmPeriod, cmStudent } = this.state;

        try {
            await this.api.addRegBillingJob({ period: toModalDate(cmPeriod), studentId: cmStudent || null });
            if (this.mounted) {
                this.setState({ cmOpen: false });
                this.loadInfo();
            }
        } catch (error) {
            if (this.mounted) {
                toastr.error(this.context.translate('Err.System'));
            }
        }
    }

    async export() {
        let { period, studentFilter, groupFilter } = this.state;

        try {
            let student = (studentFilter || []).map(o => o.value);
            let group = (groupFilter || []).map(o => o.value);
            let response = await this.api.exportRegBillings({ period: toModalDate(period), student, group });
            if (this.mounted) {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `billings-${toPeriodDate(period)}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        } catch (error) {
            if (this.mounted) {
                toastr.error(this.context.translate('Err.System'));
            }
        }
    }

    getGroupOptions(groups) {
        let result = (groups || []).map(o => ({ value: o.id, label: o.name }));
        return result;
    }

    getStudentOptions2(students) {
        let result = _.map(students || {}, o => ({ value: o.id, label: o.name }));
        return result;
    }

    render() {
        let { translate } = this.context;
        let { period, billings, students, studentFilter, cmOpen, cmPeriod, info, cmStudent, groups, groupFilter } = this.state;

        let studentCalcOptions = this.getStudentOptions(students, translate, 'Billing.All');
        let billingItems = this.getBillingItems(billings, studentFilter, groupFilter);
        let groupOptions = this.getGroupOptions(groups);
        let studentOptions = this.getStudentOptions2(students);

        let { sumaCreditBefore, sumaDeposit, sumaPayment, sumaCredit } = billingItems.reduce((r, o) => {
            r.sumaCreditBefore += o.creditBefore;
            r.sumaDeposit += o.deposit;
            r.sumaPayment += o.payment;
            r.sumaCredit += o.credit;
            return r;
        }, { sumaCreditBefore: 0, sumaDeposit: 0, sumaPayment: 0, sumaCredit: 0 });
        let balance = sumaCredit - sumaCreditBefore;

        let showInfo = this.isInfo(info);
        // console.log('info', info);

        return (
            <div className="page-container">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Period')}</label>
                            <Period
                                period={period}
                                onChange={p => this.onPeriodChange(p)}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Student')}</label>
                            <Select
                                options={studentOptions}
                                isMulti
                                onChange={studentFilter => this.setState({ studentFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Group')}</label>
                            <Select
                                options={groupOptions}
                                isMulti
                                onChange={groupFilter => this.setState({ groupFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        <button
                            className="btn btn-imsp"
                            onClick={() => this.export()}
                        >{this.context.translate('Btn.Export')}</button>
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.setState({ cmOpen: true, cmPeriod: period, cmStudent: studentFilter && studentFilter.length ? studentFilter[0].value : null })}
                        >{this.context.translate('Billing.Recalculate')}</div>
                    </div>
                </div>
                <div className="page-content">
                    {showInfo &&
                        <div className="billing-process-info">
                            <div className="title">{translate('Billing.ProcTitle')}</div>
                            <div className="info-item">
                                <div className="label">{translate('Billing.ProcQueue')}</div>
                                <div className="label">{(info.queue || []).length}</div>
                            </div>
                            <div className="info-item">
                                <div className="label">{translate('Billing.ProcJob')}</div>
                                <div className="value"><JobInfo job={info.runningJob} translate={translate} /></div>
                            </div>
                            <div className="info-item">
                                <div className="label">{translate('Billing.ProcCalc')}</div>
                                <div className="value"><JobInfo job={info.runningStudentId ? { period: info.runnignPeriod, student: info.runningStudentId, studentName: info.runningStudentName } : null} translate={translate} /></div>
                            </div>
                        </div>
                    }
                    <div className="billing-list">
                        <table className="imsp-table">
                            <colgroup>
                                <col className="col-user" />
                                <col className="col-student" />
                                <col className="col-creditbefore" />
                                <col className="col-deposite" />
                                <col className="col-payment" />
                                <col className="col-credit" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{this.context.translate('Billing.User')}</th>
                                    <th>{this.context.translate('Billing.Student')}</th>
                                    <th className="ta-r">{this.context.translate('Billing.CreditBefore')}</th>
                                    <th className="ta-r">{this.context.translate('Billing.Deposit')}</th>
                                    <th className="ta-r">{this.context.translate('Billing.Payment')}</th>
                                    <th className="ta-r">{this.context.translate('Billing.Credit')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {billingItems.map(o => {
                                    let key = this.getKey(o.period, o.studentId);
                                    return (
                                        <tr
                                            key={key}
                                        >
                                            <td>{o.userName}</td>
                                            <td>{o.studentName}</td>
                                            <td className="ta-r">{o.creditBefore} {this.context.translate('Units.Money')}</td>
                                            <td className="ta-r">{o.deposit} {this.context.translate('Units.Money')}</td>
                                            <td className="ta-r">{o.payment} {this.context.translate('Units.Money')}</td>
                                            <td className="ta-r">{o.credit} {this.context.translate('Units.Money')}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table className="imsp-table">
                            <colgroup>
                                <col className="col-suma" />
                                <col className="col-balance" />
                                <col className="col-creditbefore" />
                                <col className="col-deposit" />
                                <col className="col-payment" />
                                <col className="col-credit" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className="ta-c">{this.context.translate('Billing.Suma')}</th>
                                    <th className="ta-r">{this.context.translate('Billing.Balance')}</th>
                                    <th className="ta-r">{this.context.translate('Billing.CreditBefore')}</th>
                                    <th className="ta-r">{this.context.translate('Billing.Deposit')}</th>
                                    <th className="ta-r">{this.context.translate('Billing.Payment')}</th>
                                    <th className="ta-r">{this.context.translate('Billing.Credit')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="ta-r">{balance} {this.context.translate('Units.Money')}</td>
                                    <td className="ta-r">{sumaCreditBefore} {this.context.translate('Units.Money')}</td>
                                    <td className="ta-r">{sumaDeposit} {this.context.translate('Units.Money')}</td>
                                    <td className="ta-r">{sumaPayment} {this.context.translate('Units.Money')}</td>
                                    <td className="ta-r">{sumaCredit} {this.context.translate('Units.Money')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {cmOpen &&
                    <Modal
                        modalClass="run-billing-modal"
                        onClose={() => this.setState({ cmOpen: false })}
                        onSubmit={() => this.runCalc()}
                        title={this.context.translate('Billing.CalcTitle')}
                        show={true}
                        closeOnClick={false}
                    >
                        <div>
                            <fieldset>
                                <div className="form-group">
                                    <label>{this.context.translate('Billing.Period')}</label>
                                    <DatePicker
                                        className="form-control"
                                        selected={cmPeriod}
                                        // onChangeRaw={e => e.preventDefault()}
                                        onChange={cmPeriod => this.setState({ cmPeriod })}
                                        // strictParsing={true}
                                        dateFormat="MMMM yyyy"
                                        showMonthYearPicker
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{this.context.translate('Billing.Student')}</label>
                                    <select
                                        className="form-control student-selector"
                                        value={cmStudent}
                                        onChange={e => this.setState({ cmStudent: e.target.value })}
                                    >
                                        {studentCalcOptions.map(o => (
                                            <option key={o.id || 0} value={o.id}>{o.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group buttons">
                                    <button
                                        className="btn btn-light"
                                        onClick={() => this.setState({ cmOpen: false })}
                                    >{this.context.translate('Btn.Cancel')}</button>
                                    <button
                                        className="btn btn-imsp"
                                        onClick={() => this.runCalc()}
                                    >{this.context.translate('Billing.CalcSubmit')}</button>
                                </div>
                            </fieldset>
                        </div>
                    </Modal>
                }
            </div>
        );
    }
}

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(AdminBilling);