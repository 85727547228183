import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import DateTimeControl from '../../../components/DateTimeControl/DateTimeControl';
import { toModalDateTime } from '../../../utils/date';
import { AppContext } from '../../AppContext';

import './appointmentControl.scss';


const AppointmentItem = ({ value, onChange }) => {

    value = value || {};

    let { translate } = useContext(AppContext);

    let [time, setTime] = useState(value.time);

    const onTimeChange = time => {
        var item = { ...value, time };
        setTime(time);
        if (onChange) onChange(item);
    }

    const onRemove = () => {
        var item = { ...value, remove: true };
        if (onChange) onChange(item);
    }

    if (value.reserved) {
        return (
            <div className="appointment-item">
                <div className="time-text">{moment(value.time).format('D. M. YYYY  HH:mm')}</div>
                <div className="reserved">{translate('AppointmentControl.Reserved')}</div>
            </div>
        );
    } else {
        return (
            <div className="appointment-item">
                <DateTimeControl
                    value={time}
                    onChange={onTimeChange}
                    isClearable={false}
                />
                <button
                    className="btn btn-light rem-btn"
                    onClick={onRemove}
                ><i className="fa fa-minus"></i></button>
            </div>
        );
    }
}

const AppointmentControl = ({ value, onChange }) => {

    let [items, setItems] = useState(value || []);

    useEffect(() => {
        setItems(value || []);
    }, [value]);

    const initTime = () => {
        if (items.length) {
            return moment(items[items.length - 1].time).add(30, 'minutes').format('YYYY-MM-DD HH:mm');
        } else {
            return toModalDateTime(new Date());
        }
    }

    const onItemChange = item => {
        let id = _.findIndex(items, o => item.id ? o.id === item.id : o.ts === item.ts);
        if (id >= 0) {
            if (item.remove) {
                if (item.id) {
                    items.splice(id, 1, item);
                } else {
                    items.splice(id, 1);
                }
            } else {
                items.splice(id, 1, item);
            }
            let data = [...items];
            if (onChange) onChange(data);
        }
    }

    const onAdd = () => {
        let data = [...items, { time: initTime(), ts: new Date().getTime() }];
        if (onChange) onChange(data);
    }

    return (
        <div className="appointment-control">
            {items.filter(o => !o.remove).map(o => {
                return (
                    <AppointmentItem
                        key={o.id || o.ts}
                        value={o}
                        onChange={onItemChange}
                    />
                );
            })}
            <button
                className="btn btn-light add-btn"
                onClick={onAdd}
            ><i className="fa fa-plus"></i></button>
        </div>
    );
}

export default AppointmentControl;