import _ from 'lodash';
import * as React from 'react';
import * as toastr from 'toastr';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Modal from '../../../components/Modal';
import { ApiService } from '../../../services/ApiService';
import { arraySort } from '../../../utils/sort';
import { ROLE_ADMIN, ROLE_VIEWER } from '../../../utils/role'

import './accounts.scss';


class Account extends React.Component {
    static contextTypes = {
        translate: PropTypes.func.isRequired,
        onTitle: PropTypes.func
    };

    constructor(props, context) {
        super(props);

        this.state = {
            accounts: {},
            modal: {
                open: false,
                id: '',
                title: '',
                isAdmin: false,
                isViewer: false
            },
            nameFilter: '',
            selected: '',
            withoutStudentFilter: ''
        };
        this.api = new ApiService();
        this.mounted = false;
        this.yesno = {
            true: context.translate('Codelist.Yes'),
            false: context.translate('Codelist.No')
        }
    }

    componentWillMount() {
        this.loadAccounts();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Header.Accounts');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadAccounts() {
        this.api.getAccounts(false, true, true)
            .then(response => {
                if (this.mounted) {
                    let accounts = _.mapKeys(response.data, 'id');
                    this.setState({ accounts });
                }
            })
            .catch(error => {
                console.log('Account.loadAccounts', error);
            });
    }

    printRoles(roles) {
        if (!roles || !roles.length) {
            return null;
        }

        return roles.map(o => this.getRoleName(o.roleId)).join(', ');
    }

    getRoleName(id) {
        switch (id) {
            case ROLE_ADMIN:
                return 'admin';
            case ROLE_VIEWER:
                return 'viewer';
            default:
                return null;
        }
    }

    updateAccount(id) {
        id = id || this.state.selected;
        if (!id) {
            return;
        }

        let { name, roles } = this.state.accounts[id];
        // console.log('Account.updateAccount', { name, roles, admin: !!_.find(roles, { roleId: ROLE_ADMIN }) });
        this.setState({
            modal: {
                ...this.state.modal,
                open: true,
                id,
                title: name || '',
                isAdmin: !!_.find(roles, { roleId: ROLE_ADMIN }),
                isViewer: !!_.find(roles, { roleId: ROLE_VIEWER })
            },
            selected: id
        });
    }

    deleteAccount(id) {
        id = id || this.state.selected;
        if (!id) {
            return;
        }

        if (window.confirm(this.context.translate('Account.ConfirmDelete'))) {
            let _self = this;
            this.api.deleteAccount(id, false)
                .then(response => {
                    if (_self.mounted) {
                        if (response.data) {
                            if (response.data.success) {
                                toastr.success(_self.context.translate('Account.DeletedSuccessfuly'));
                                _self.loadAccounts();
                            } else {
                                if (window.confirm(this.context.translate('Account.ConfirmForceDelete'))) {
                                    _self.api.deleteAccount(id, true)
                                        .then(response => {
                                            if (_self.mounted) {
                                                if (response.data) {
                                                    if (response.data.success) {
                                                        toastr.success(_self.context.translate('Account.DeletedSuccessfuly'));
                                                        _self.loadAccounts();
                                                    } else {
                                                        console.log('Account.delete: Unexpected response data result.');
                                                    }
                                                } else {
                                                    console.log('Account.delete: Missing response data.');
                                                }
                                            }
                                        })
                                        .catch(error => {
                                            console.log('Account.deleteAccount error delete account', error);
                                            if (_self.mounted) {
                                                toastr.error(error);
                                            }
                                        });
                                }
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log('Account.deleteAccount error delete account', error);
                    if (this.mounted) {
                        toastr.error(error);
                    }
                });
        }
    }

    closeModal() {
        this.setState({ modal: { ...this.state.modal, open: false } });
    }

    submitModal() {
        if (this.validateModal()) {
            let roles = [];
            if (this.state.modal.isAdmin) {
                roles.push(ROLE_ADMIN);
            }
            if (this.state.modal.isViewer) {
                roles.push(ROLE_VIEWER);
            }
            this.api.updateAccount(
                this.state.modal.id,
                roles)
                .then(response => {
                    this.loadAccounts();
                    this.closeModal();
                    toastr.success('Účet byl úspěšně upraven.');
                })
                .catch(error => {
                    console.log('Account.submitModal error update account', error);
                    toastr.error(error);
                });
        }
    }

    validateModal() {
        return true;
    }

    select(e, id) {
        let selected = this.state.selected === id ? '' : id;
        this.setState({ selected });
    }

    render() {
        let { accounts, nameFilter, selected, withoutStudentFilter } = this.state;

        let items = arraySort(_.map(accounts), 'name');
        if (nameFilter) {
            items = items.filter(o => (o.name || '').toLowerCase().includes(nameFilter.toLowerCase().trim())
                || (o.email || '').toLowerCase().includes(nameFilter.toLowerCase().trim()));
        }
        if (withoutStudentFilter) {
            items = items.filter(o => !o.students || !o.students.length);
        }

        return (
            <div className="page-container account-page">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Name')}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="name"
                                value={nameFilter}
                                onChange={e => this.setState({ nameFilter: e.target.value })}
                                autoFocus
                            />
                        </div>
                        <div
                            className="check-filter"
                            onClick={() => this.setState({ withoutStudentFilter: !withoutStudentFilter })}
                        >
                            <div className="label">{this.context.translate('Account.WithoutStudent')}</div>
                            <input type="checkbox" checked={withoutStudentFilter} onChange={() => { }} />
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className={classnames("btn btn-imsp", { disabled: !selected })}
                            onClick={() => this.updateAccount()}
                        >{this.context.translate('Account.SetRole')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !selected })}
                            onClick={() => this.deleteAccount()}
                        >{this.context.translate('Account.Delete')}</div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-name" />
                            <col className="col-email" />
                            <col className="col-address" />
                            <col className="col-accnumber" />
                            <col className="col-isteacher" />
                            <col className="col-roles" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="col">{this.context.translate('Account.Name')}</th>
                                <th className="col">{this.context.translate('Account.Email')}</th>
                                <th className="col">{this.context.translate('Account.Address')}</th>
                                <th className="col">{this.context.translate('Account.AccNum')}</th>
                                <th className="col ta-c">{this.context.translate('Account.IsTeacher')}</th>
                                <th className="col">{this.context.translate('Account.Roles')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => (
                                <tr
                                    key={o.id}
                                    className={classnames({ selected: selected === o.id })}
                                    onClick={e => this.select(e, o.id)}
                                    onDoubleClick={() => this.updateAccount(o.id)}
                                >
                                    <td>{o.name}</td>
                                    <td>{o.email}</td>
                                    <td>{o.address}</td>
                                    <td>{o.accountNumber}</td>
                                    <td className="ta-c">{this.yesno[!!o.teacher]}</td>
                                    <td>{this.printRoles(o.roles)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <Modal
                    modalClass="diner-modal"
                    onClose={() => this.closeModal()}
                    onSubmit={() => this.submitModal()}
                    title={this.state.modal.title}
                    show={this.state.modal.open}
                    closeOnClick={false}
                >
                    <div>
                        <fieldset>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="isAdmin"
                                    checked={this.state.modal.isAdmin}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, isAdmin: e.target.checked } })}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="isAdmin"
                                >Admin</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="isViewer"
                                    checked={this.state.modal.isViewer}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, isViewer: e.target.checked } })}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="isViewer"
                                >Viewer</label>
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.submitModal()}
                                >Uložit</button>
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeModal()}
                                >Zrušit</button>
                            </div>
                        </fieldset>
                    </div>
                </Modal>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Account);