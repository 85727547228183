import * as toastr from 'toastr';
import classnames from 'classnames';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Constants } from '../../../helpers/Constants';
import { ApiService } from '../../../services/ApiService';
import { getCourseGroupList } from '../../../utils/course';
import { AppContext } from '../../AppContext';

import './meeting.scss';
import RegMeeting from './RegMeeting';
import { errorEquals, ERR_APPOINTMENT_PASSED, ERR_MEETING_DISABLED, ERR_MEETING_REG_NOT_OPEN } from '../../../utils/error';


const Meeting = ({ onTitle, history, match, profileLoaded, profileLoading }) => {

    let api = new ApiService();

    let { translate } = useContext(AppContext);

    let [meeting, setMeeting] = useState({ teacher: {}, classroom: {} });
    let [regModal, setRegModal] = useState({ show: false, ts: null, meeting: null, appointment: null });

    useEffect(() => {
        if (onTitle) onTitle('ClassMeeting.Title');
    }, [onTitle])

    useEffect(() => {
        if (!profileLoading)
            loadMeeting();
    }, [profileLoading]);

    const loadMeeting = async () => {
        try {
            let response = await api.getClassMeetingMenu(match.params.id);
            let data = response.data || {};
            data.teacher = data.teacher || {};
            data.classroom = data.classroom || {};
            setMeeting(data);
        } catch (error) {
            console.log(error);
        }
    }

    const onRegister = appointment => {
        setRegModal({ show: true, ts: new Date().getTime(), meeting, appointment });
    }

    const onUnregister = async appointment => {
        if (window.confirm(translate('RegMeeting.UnregConfirm'))) {
            try {
                let data = {
                    appointmentId: appointment.id
                };
                let response = await api.unregisterMeeting(data);
                if (response.data) {
                    loadMeeting();
                    toastr.success(translate('RegMeeting.UnregSuccess'));
                }
            } catch (error) {
                if (errorEquals(error, ERR_MEETING_DISABLED)) {
                    toastr.error(translate('RegMeeting.MeetingDisabled'));
                } else if (errorEquals(error, ERR_MEETING_REG_NOT_OPEN)) {
                    toastr.error(translate('RegMeeting.MeetingRegNotOpen'));
                } else if (errorEquals(error, ERR_APPOINTMENT_PASSED)) {
                    toastr.error(translate('RegMeeting.AppointmentPassed'));
                } else {
                    toastr.error(translate('Err.System'));
                }
            }
        }
    }

    const onRegModalCancel = () => {
        loadMeeting();
        setRegModal({ show: false, ts: null, meeting: null, appointment: null });
    }

    const onRegModalSubmit = () => {
        loadMeeting();
        setRegModal({ show: false, ts: null, meeting: null, appointment: null });
    }

    let hasHistory = (history || {}).length > 1;
    let groups = getCourseGroupList(meeting);
    let teacherImg = meeting.teacher.imgId ? `${Constants.apiRoot}img/${meeting.teacher.imgId}` : '/img/person.png';
    let classroomImg = meeting.classroom.imgId ? `${Constants.apiRoot}img/${meeting.classroom.imgId}` : '/img/classroom.png';
    let appointments = meeting.appointments || [];
    let regInterval = null;
    if (meeting && meeting.isDisabled) {
        regInterval = [<span key="1">{translate('RegMeeting.MeetingDisabled')}</span>];
    } else if (meeting && (meeting.regFrom || meeting.regTo)) {
        regInterval = [<span key="1">{translate('RegMeeting.AllowInterval')}</span>];
        if (meeting.regFrom) {
            regInterval.push(<span key="2">{translate('RegMeeting.IntervalFrom')}</span>);
            regInterval.push(<span key="3"><strong>{moment(meeting.regFrom).format('D. M. YYYY HH:mm')}</strong></span>);
        }
        if (meeting.regTo) {
            regInterval.push(<span key="4">{translate('RegMeeting.IntervalTo')}</span>);
            regInterval.push(<span key="5"><strong>{moment(meeting.regTo).format('D. M. YYYY HH:mm')}</strong></span>);
        }
    }
    let regDisabled = true;
    if (profileLoaded
        && meeting && !meeting.isDisabled
        && (!meeting.regFrom || moment(meeting.regFrom).isBefore(moment()))
        && (!meeting.regTo || moment(meeting.regTo).isAfter(moment()))) {
        regDisabled = false;
    }

    return (
        <div className="user-page-container meeting-page-container">
            <div className="header-part">
                <div className="title-part">
                    <div className="title">
                        {hasHistory &&
                            <span
                                className="btn btn-imsp btn-back"
                                onClick={() => history.goBack()}
                            ><i className="fa fa-arrow-left" aria-hidden="true"></i></span>
                        }
                        <div className="title-text">{meeting.name}</div>
                    </div>
                    <div className="groups">{groups}</div>
                </div>
            </div>
            <div className="description-part" dangerouslySetInnerHTML={{ __html: meeting.description }} />
            {regInterval &&
                <div className="reg-interval">{regInterval}</div>
            }
            <div className="appointment-part">
                {appointments.map((appointment, i) => {
                    let time = appointment && appointment.time ? moment(appointment.time).format('D. M. YYYY HH:mm') : '';
                    let showReg = !appointment.reserved;
                    let showRes = appointment.reserved && !appointment.studentId;
                    let showUnreg = appointment.reserved && appointment.studentId;
                    return (
                        <div
                            className="appointment-item"
                            key={i}
                        >
                            <div className="time">{time}</div>
                            {showRes &&
                                <div className="item-action reserved">{translate('RegMeeting.Reserved')}</div>
                            }
                            {showReg &&
                                <div className="item-action reg-btn">
                                    <div
                                        className={classnames("btn btn-imsp", { disabled: regDisabled })}
                                        onClick={() => { if (!regDisabled) onRegister(appointment); }}
                                    >{translate('ClassMeeting.Register')}</div>
                                </div>
                            }
                            {showUnreg &&
                                <div className="item-action reg-btn">
                                    <div
                                        className={classnames("btn btn-imsp", { disabled: regDisabled })}
                                        onClick={() => { if (!regDisabled) onUnregister(appointment); }}
                                    >{translate('ClassMeeting.Unregister')}</div>
                                </div>
                            }
                        </div>
                    );
                })}
            </div >
            <div className="lector-part">
                <div className="lector-item">
                    <div className="lector-info">
                        <div className="info-item">
                            <div className="label">{translate('Course.Teacher')}</div>
                            <div className="value">{meeting.teacher.name}</div>
                        </div>
                        <div className="info-item">
                            <div className="label">{translate('Course.Email')}</div>
                            <div className="value">{meeting.teacher.email}</div>
                        </div>
                        <div className="info-item">
                            <div className="label">{translate('Teacher.Phone')}</div>
                            <div className="value">{meeting.teacher.phone}</div>
                        </div>
                    </div>
                    <div className="lector-img">
                        <img src={teacherImg} alt={translate('Course.Teacher')} />
                    </div>
                    <div className="lector-note">
                        <div className="info-item">
                            <div className="value multiline">{meeting.teacher.description}</div>
                        </div>
                    </div>
                </div>
                <div className="lector-item">
                    <div className="lector-info">
                        <div className="info-item">
                            <div className="label">{translate('Menu.Classroom')}</div>
                            <div className="value">{meeting.classroom.name}</div>
                        </div>
                        <div className="info-item">
                            <div className="label">{translate('Classroom.Address')}</div>
                            <div className="value multiline">{meeting.classroom.address}</div>
                        </div>
                    </div>
                    <div className="lector-img">
                        <img src={classroomImg} alt={translate('Classroom.Title')} />
                    </div>
                    <div className="lector-note">
                        <div className="info-item">
                            <div className="value multiline">{meeting.classroom.description}</div>
                        </div>
                    </div>
                </div>
            </div>
            {meeting.imgId &&
                <div className="img">
                    <img src={`${Constants.apiRoot}img/${meeting.imgId}`} alt={translate('ClassMeeting.Title')} />
                </div>
            }
            {
                regModal.show &&
                <RegMeeting
                    key={regModal.ts}
                    onCancel={onRegModalCancel}
                    onSubmit={onRegModalSubmit}
                    meeting={regModal.meeting}
                    appointment={regModal.appointment}
                />
            }
        </div >
    );
}

export default connect(state => ({
    profileLoaded: state.profile.loaded,
    profileLoading: state.profile.loading
}), {})(Meeting);