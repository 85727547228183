import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from "react-redux";

import Modal from '../../../components/Modal';
import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import { Constants } from '../../../helpers/Constants';
import { ERR_USER_IS_TEACHER, errorEquals } from '../../../utils/error'
import schoolYear from '../../../utils/schoolYear';


class Teacher extends React.Component {
    static propTypes = {
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.initModal = {
            open: false,
            id: '',
            name: '',
            email: '',
            phone: '',
            description: '',
            userId: '',
            imgId: '',
            file: null,
            imgUrl: '/img/person.png',
            from: '',
            to: ''
        };
        this.state = {
            teachers: {},
            selected: '',
            modal: this.initModal,
            users: [],
            schyFilter: { value: schoolYear().year(), label: schoolYear().yearText() }
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadTeachers();
        this.loadUsers();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Header.Teachers');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadTeachers() {
        let { schyFilter, selected } = this.state;

        let params = {};
        if (schyFilter && schyFilter.value)
            params.year = schyFilter.value;

        this.api.getTeachers(params)
            .then(response => {
                if (this.mounted) {
                    let teachers = _.mapKeys(response.data, 'id');
                    if (selected && !teachers[selected]) {
                        selected = '';
                    }
                    this.setState({ teachers, selected });
                }
            })
            .catch(error => {
                console.log('Teacher.loadTeachers', error);
            });
    }

    loadUsers() {
        this.api.getAccounts(false, true)
            .then(response => {
                if (this.mounted) {
                    let users = response.data || [];
                    this.setState({ users });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    submitModal() {
        let { id, name, email, phone, userId, imgId, file, description, from, to } = this.state.modal;

        from = from || null;
        to = to || null;

        if (this.validateModal()) {
            if (id) {
                this.api.updateTeacher(id, name, email, phone, userId, imgId, file, description, from, to)
                    .then(response => {
                        this.loadTeachers();
                        this.closeModal();
                        toastr.success(this.context.translate('Teacher.EditSuccess'));
                    })
                    .catch(error => {
                        if (this.mounted) {
                            if (errorEquals(error, ERR_USER_IS_TEACHER)) {
                                toastr.warning(this.context.translate('Err.UserIsTeacher'));
                            } else {
                                toastr.error(this.context.translate('Err.System'));
                            }
                        }
                    });
            } else {
                this.api.addTeacher(name, email, phone, userId, file, description, from, to)
                    .then(response => {
                        this.loadTeachers();
                        this.closeModal();
                        toastr.success(this.context.translate('Teacher.AddSuccess'));
                    })
                    .catch(error => {
                        console.log('Teacher.submitModal error add teacher', error);
                        toastr.error(error);
                    });
            }
        }
    }

    validateModal() {
        return true;
    }

    add() {
        this.setState({
            modal: {
                ...this.initModal,
                open: true
            }
        });
    }

    update(id) {
        id = id || this.state.selected;
        if (!id) {
            return;
        }

        let { name, email, phone, userId, imgId, description } = this.state.teachers[id] || {};
        this.setState({
            modal: {
                ...this.initModal,
                open: true,
                id,
                name: name || this.initModal.name,
                email: email || this.initModal.email,
                phone: phone || this.initModal.phone,
                description: description || this.initModal.description,
                userId: userId || this.initModal.userId,
                imgId: imgId || this.initModal.imgId,
                imgUrl: imgId ? `${Constants.apiRoot}img/${imgId}` : this.initModal.imgUrl
            },
            selected: id
        });
    }

    delete(id) {
        id = id || this.state.selected;
        if (!id) {
            return;
        }

        if (window.confirm(this.context.translate('Teacher.ConfirmDelete'))) {
            this.api.deleteTeacher(id)
                .then(response => {
                    toastr.success(this.context.translate('Teacher.DeleteSuccess'));
                    if (this.mounted) {
                        this.loadTeachers();
                        this.setState({ selected: '' });
                    }
                })
                .catch(error => {
                    console.log('Teacher.delete error delete teacher', error);
                    toastr.error(error);
                });
        }
    }

    select(key) {
        this.setState({ selected: key === this.state.selected ? null : key });
    }

    closeModal() {
        this.setState({ modal: { ...this.initModal } });
    }

    loadPhoto(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (files && files.length) {
            let maxInsertImageFileSize = 6 * 1024 * 1024; // 6MB
            let allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/gif'];

            let file = files[0];
            if (allowedTypes.indexOf(file.type) < 0) {
                toastr.error(this.context.translate('Img.UnsupportedFileType'));
                return;
            }
            if (file.size > maxInsertImageFileSize) {
                toastr.error(this.context.translate('Img.MaxFileSize6'));
                return;
            }

            this.setState({ modal: { ...this.state.modal, file, imgUrl: URL.createObjectURL(file) } });
        }
    }

    clearPhoto() {
        this.setState({ modal: { ...this.state.modal, file: null, imgUrl: this.initModal.imgUrl, imgId: null } });
    }

    getUserOptions(users) {
        users = users || [];
        let result = [{ id: '', name: '' }, ...users];
        return result;
    }

    onUserChanged(e, modal, users) {
        let { name, email } = modal || {};
        let userId = e.target.value;
        if (userId) {
            let user = _.find(users, { id: userId });
            if (user && !name) {
                name = user.name;
            }
            if (user && !email) {
                email = user.email;
            }
        }
        this.setState({ modal: { ...modal, userId, name, email } });
    }

    getSchyFilterOptions() {
        let curYear = schoolYear().year();
        let result = [];
        for (let i = curYear - 1; i <= curYear + 1; i++) {
            result.push({ value: i, label: schoolYear(i).yearText() })
        }
        return result;
    }

    render() {
        let { translate } = this.context;
        let { teachers, selected, modal, users, schyFilter } = this.state;

        let items = arraySort(_.map(teachers), 'name')
        let isSelected = !!selected;
        let userOptions = this.getUserOptions(users);
        let schyFilterOptions = this.getSchyFilterOptions();

        return (
            <div className="page-container simple-page">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('General.SchoolYear')}</label>
                            <Select
                                value={schyFilter}
                                options={schyFilterOptions}
                                onChange={schyFilter => this.setState({ schyFilter }, () => {
                                    this.loadTeachers();
                                })}
                                menuPortalTarget={document.querySelector('body')}
                                isClearable
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.add()}
                        >{this.context.translate('Btn.Add')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.update()}
                        >{this.context.translate('Btn.Update')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.delete()}
                        >{this.context.translate('Btn.Delete')}</div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-name" />
                            <col className="col-email" />
                            <col className="col-phone" />
                            <col className="col-user" />
                            <col className="col-description" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{this.context.translate('Teacher.Name')}</th>
                                <th>{this.context.translate('Teacher.Email')}</th>
                                <th>{this.context.translate('Teacher.Phone')}</th>
                                <th>{this.context.translate('Teacher.User')}</th>
                                <th>{this.context.translate('Teacher.Description')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(o => {
                                return (
                                    <tr
                                        key={o.id}
                                        className={classnames({ selected: selected === o.id })}
                                        onClick={() => this.select(o.id)}
                                        onDoubleClick={() => this.update(o.id)}
                                    >
                                        <td>{o.name}</td>
                                        <td>{o.email}</td>
                                        <td>{o.phone}</td>
                                        <td>{(o.user || {}).name}</td>
                                        <td>{o.description}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Modal
                    modalClass="teacher-modal"
                    onClose={() => this.closeModal()}
                    onSubmit={() => this.submitModal()}
                    title={this.context.translate('Teacher.Title')}
                    show={modal.open}
                    closeOnClick={false}
                >
                    <div>
                        <fieldset>
                            <div className="form-group">
                                <label htmlFor="name">{this.context.translate('Teacher.Name')}</label>
                                <input
                                    className="form-control"
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    autoFocus
                                    value={this.state.modal.name}
                                    onChange={e => this.setState({ modal: { ...this.state.modal, name: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Teacher.Email')}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    autoComplete="off"
                                    value={modal.email}
                                    onChange={e => this.setState({ modal: { ...modal, email: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Teacher.Phone')}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    autoComplete="off"
                                    value={modal.phone}
                                    onChange={e => this.setState({ modal: { ...modal, phone: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="user">{this.context.translate('Teacher.User')}</label>
                                <select
                                    className="form-control"
                                    name="user"
                                    value={modal.userId}
                                    onChange={e => this.onUserChanged(e, modal, users)}
                                >
                                    {userOptions.map(o => (
                                        <option key={o.id} value={o.id}>{o.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Teacher.From')}</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    autoComplete="off"
                                    value={modal.from}
                                    onChange={e => this.setState({ modal: { ...modal, from: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Teacher.To')}</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    autoComplete="off"
                                    value={modal.to}
                                    onChange={e => this.setState({ modal: { ...modal, to: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Teacher.Description')}</label>
                                <textarea
                                    className="form-control"
                                    rows="2"
                                    value={modal.description}
                                    onChange={e => this.setState({ modal: { ...modal, description: e.target.value } })}
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="photo">{this.context.translate('Teacher.Photo')}</label>
                                <div className="photo-input">
                                    <img src={modal.imgUrl} alt={this.context.translate('Teacher.Photo')} />
                                    <div className="img-buttons">
                                        <button
                                            className="btn btn-light import-btn"
                                            onClick={() => this.refs.photo.click()}
                                        >
                                            <input
                                                name="photo"
                                                type="file"
                                                ref="photo"
                                                accept="image/gif,image/jpeg,image/png,image/bmp"
                                                onChange={e => this.loadPhoto(e)}
                                                onClick={e => e.stopPropagation()}
                                            />
                                            <span>{this.context.translate('Teacher.LoadPhoto')}</span>
                                        </button>
                                        <button
                                            className="btn btn-light"
                                            onClick={() => this.clearPhoto()}
                                        >{this.context.translate('Btn.Delete')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn btn-imsp"
                                    onClick={() => this.submitModal()}
                                >{this.context.translate('Btn.Save')}</button>
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeModal()}
                                >{this.context.translate('Btn.Cancel')}</button>
                            </div>
                        </fieldset>
                    </div>
                </Modal>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Teacher);