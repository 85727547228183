import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
// import classnames from 'classnames';
import moment from 'moment';
import 'moment/locale/cs';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { push } from "connected-react-router";

// import { arraySort } from '../../../utils/sort';
import { parseTimeSpan } from '../../../utils/date';
import { ApiService } from '../../../services/ApiService';
import { Constants } from '../../../helpers/Constants';

import './course.scss';
import classnames from 'classnames';
import Modal from '../../../components/Modal';
import { errorEquals, ERR_COURSE_CAPACITY_EXCEEDED, ERR_COURSE_NOT_OPEN, ERR_INVALID_GROUP_ID, ERR_COURSE_DISABLED } from '../../../utils/error';
import { getNameWithGroup, getCourseGroupList } from '../../../utils/course';
import { arraySort } from '../../../utils/sort';
import LessonList from './LessonList';


class Course extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        onTitle: PropTypes.func,
        language: PropTypes.string.isRequired
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props, context) {
        super(props);

        this.state = {
            title: context.translate('Course.Title'),
            pageTitle: 'Course.Title',
            id: props.match.params.id,
            data: {},
            countdown: null,
            regmodal: {
                open: false,
                student: ''
            },
            students: [],
            studentsLoaded: false
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadData();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle(this.state.pageTitle);
        this.loadStudents();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadStudents() {
        this.api.getMyStudents(true)
            .then(response => {
                if (this.mounted) {
                    let students = response.data || [];
                    this.setState({ students, studentsLoaded: true });
                    // console.log('Course.loadStudents', students);
                }
            })
            .catch(error => {
                console.log('Course.loadStudents', error);
                if (this.mounted) {
                    this.setState({ studentsLoaded: true });
                }
            });
    }

    loadData() {
        let { id } = this.state;

        this.api.getCourseDetail(id)
            .then(response => {
                if (this.mounted) {
                    let data = response.data || {};
                    let title = getNameWithGroup(data) || this.context.translate('Course.Title');
                    let pageTitle = data.name ? `@${data.name}` : 'Course.Title';
                    let countdown = this.getCountdown(data);
                    this.setState({ data, title, pageTitle, countdown }, () => {
                        this.countdown = setInterval(() => {
                            let countdown = this.getCountdown(data);
                            if (!countdown) {
                                clearTimeout(this.countdown);
                                this.countdown = null;
                            }
                            this.setState({ countdown });
                        }, 1000);
                        if (this.props.onTitle) this.props.onTitle(pageTitle);
                    });
                    // console.log('Course.loadData', data);
                }
            })
            .catch(error => {
                console.log('Course.loadData', error);
            });
    }

    getCountdown(data) {
        if (data && data.openTime) {
            let now = new Date().getTime();

            if (now < data.openTime) {
                return data.openTime - now;
            } else {
                return null;
            }
        }

        if (data && data.timeToOpen) {
            let time = parseTimeSpan(data.timeToOpen);
            data.openTime = new Date().getTime() + time;
            // console.log('timeToOpen', { timeToOpen: result, now: new Date().getTime() });
            return time;
        }

        return null;
    }

    getTooltip(countdown, students, studentsLoaded, capacity, alternate, count, data) {
        if ((data || {}).isDisabled) {
            return this.context.translate('Course.DisabledCourse');
        }

        if (capacity && count >= capacity + alternate) {
            return this.context.translate('Course.CapacityReached');
        }

        if (studentsLoaded && (!students || !students.length)) {
            return this.context.translate('Course.NoStudentsInfo');
        }

        if (countdown) {
            let diff = countdown;
            diff = (diff - (diff % 1000)) / 1000;
            let s = diff % 60;
            diff = (diff - s) / 60;
            let m = diff % 60;
            diff = (diff - m) / 60;
            let h = diff % 24;
            let d = (diff - h) / 24;
            // console.log('countdown', { countdown, s, m, h, d });
            let time = `${s} s`;
            if (m || h || d) {
                time = `${m} m ${time}`;
                if (h || d) {
                    time = `${h} h ${time}`;
                    if (d) {
                        time = `${d} d ${time}`;
                    }
                }
            }
            // console.log('getTooltip', { diff, s, m });

            return this.context.translate('Course.OpenRegInfo').replace('{ts}', time);
        }

        return '';
    }

    register() {
        let { regmodal, students, data } = this.state;

        let student = this.choseStudent(data, students);
        this.setState({ regmodal: { ...regmodal, student, open: true } });
    }

    choseStudent(course, students) {
        if (!students || !students.length) {
            return '';
        }

        let student = _.find(students, s => !this.getStudentError(course, students, s.id));
        if (student) {
            return student.id;
        }

        return students[0].id;
    }

    getStudentError(course, students, studentId) {
        if (course && studentId && students && students.length) {
            let student = _.find(students, { id: studentId });
            if (student) {
                // console.log('course', course, student);
                if (course.id && student.registerations && student.registerations.length && _.find(student.registerations, o => { return o.courseId === course.id && !o.to })) {
                    return this.context.translate('Course.StudentAlreadyRegistered').replace('{student}', student.name);
                }
                if (course.groups && course.groups.length && !_.some(course.groups, { id: student.groupId })) {
                    return this.context.translate('Course.InvalidGroup');
                }
            }
        }
        return '';
    }

    closeRegmodal() {
        let { regmodal } = this.state;

        this.setState({ regmodal: { ...regmodal, open: false } });
    }

    submitRegmodal() {
        let { students, regmodal, data } = this.state;
        let { student } = regmodal;
        let { id } = data || {};

        if (this.getStudentError(data, students, student)) {
            return;
        }

        this.api.addRegistration(id, student)
            .then(response => {
                this.props.push('/overview');
                toastr.success(this.context.translate('Course.RegisterSuccess'));
            })
            .catch(error => {
                if (this.mounted) {
                    if (errorEquals(error, ERR_COURSE_CAPACITY_EXCEEDED)) {
                        toastr.warning(this.context.translate('Course.CapacityReached'));
                        this.loadData();
                        this.closeRegmodal();
                    } else if (errorEquals(error, ERR_COURSE_NOT_OPEN)) {
                        toastr.warning(this.context.translate('Course.NotOpenYet'));
                    } else if (errorEquals(error, ERR_INVALID_GROUP_ID)) {
                        toastr.warning(this.context.translate('Course.InvalidGroup'));
                    } else if (errorEquals(error, ERR_COURSE_DISABLED)) {
                        toastr.warning(this.context.translate('Course.DisabledCourse'));
                    } else {
                        toastr.error(this.context.translate('Err.System'));
                    }
                }
            });
    }

    getRegDisabled(countdown, students, studentsLoaded, capacity, alternate, count, data) {
        let result = !!countdown ||
            !studentsLoaded ||
            !students ||
            !students.length ||
            (!!capacity && count >= capacity + alternate) ||
            (data || {}).isDisabled ||
            data && data.openTo && moment(data.openTo).isBefore(moment());
        // console.log('getRegDisabled', { countdown, students, studentsLoaded, capacity, alternate, count, result });
        return result;
    }

    render() {
        let { translate } = this.context;
        let { title, data, countdown, regmodal, students, studentsLoaded } = this.state;
        let { count, capacity, alternate, lessons } = data || {};
        let { history, language } = this.props;

        moment.locale(language);

        lessons = lessons || [];
        arraySort(lessons, 'time');

        let capacityText = capacity === null ? translate('Course.All') : capacity;
        let alternateText = alternate || translate('Codelist.No');
        let price = data.price ? `${data.price} ${translate('Units.Money')}` : '-';
        let isExternal = translate(`Codelist.${(data.isExternal ? 'Yes' : 'No')}`);
        let lessonCount = (data.lessons || []).length;
        let description = data.description || '';
        let imgUrl = data.imgId ? `${Constants.apiRoot}img/${data.imgId}` : '/img/classroom.png';
        let teacherName = (data.teacher || {}).name || '';
        let teacherEmail = (data.teacher || {}).email || '';
        let teacherPhone = (data.teacher || {}).phone || '';
        let teacherImg = (data.teacher || {}).imgId ? `${Constants.apiRoot}img/${data.teacher.imgId}` : '/img/person.png';
        let classroomName = (data.classroom || {}).name || '';
        let classroomAddress = (data.classroom || {}).address || '';
        let classroomDescription = (data.classroom || {}).description || '';
        let teacherDescription = (data.teacher || {}).description || '';
        let classroomImg = (data.classroom || {}).imgId ? `${Constants.apiRoot}img/${data.classroom.imgId}` : '/img/classroom.png';
        let regTooltip = this.getTooltip(countdown, students, studentsLoaded, capacity, alternate || 0, count, data);
        let regDisabled = this.getRegDisabled(countdown, students, studentsLoaded, capacity, alternate || 0, count, data);
        let studentError = this.getStudentError(data, students, regmodal.student);
        let reg2Disabled = !!studentError;
        let groups = getCourseGroupList(data);
        let isSmallEquipment = data.equipments && data.equipments.length < 18 && !(data.equipments.match(/\n/g) || []).length;
        let isLargeEquipment = data.equipments && (data.equipments.length >= 18 || (data.equipments.match(/\n/g) || []).length);

        let hasHistory = (history || {}).length > 1;

        return (
            <div className="user-page-container course-container">
                <div className="header-part">
                    <div className="title-part">
                        <div className="title">
                            {hasHistory &&
                                <span
                                    className="btn btn-imsp btn-back"
                                    onClick={() => history.goBack()}
                                ><i className="fa fa-arrow-left" aria-hidden="true"></i></span>
                            }
                            {data.name}
                        </div>
                        <div className="groups">{groups}</div>
                    </div>
                    <div className="buttons">
                        <div
                            className={classnames("btn btn-imsp", { disabled: regDisabled })}
                            onClick={() => { if (!regDisabled) this.register(); }}
                        >{translate('Course.Register')}</div>
                    </div>
                </div>
                <div className="tooltip-part">{regTooltip}</div>
                <div className="description-part" dangerouslySetInnerHTML={{ __html: description }} />
                <div className="info-part">
                    <div className="info-group">
                        <div className="info-item">
                            <div className="label">{translate('Course.Capacity')}</div>
                            <div className="value">{capacityText}</div>
                        </div>
                        <div className="info-item">
                            <div className="label">{translate('Course.Alternate')}</div>
                            <div className="value">{alternateText}</div>
                        </div>
                        <div className="info-item">
                            <div className="label">
                                {translate('Course.Price')}
                                {data.paymentInfo && <span className="note-mark">1)</span>}
                            </div>
                            <div className="value">{price}</div>
                        </div>
                        <div className="info-item">
                            <div className="label">
                                {translate('Course.IsExternal')}
                                {data.isExternal && <span className="note-mark">2)</span>}
                            </div>
                            <div className="value">
                                {isExternal}
                                {/* <span className="note-mark-list">
                                    {data.paymentInfo && <span className="note-mark">1)</span>}
                                    {data.isExternal && <span className="note-mark">2)</span>}
                                </span> */}
                            </div>
                        </div>
                        <div className="info-item">
                            <div className="label">{translate('Course.LessonCount')}</div>
                            <div className="value">{lessonCount}</div>
                        </div>
                        {isSmallEquipment &&
                            <div className="info-item">
                                <div className="label">{translate('Course.Equipments')}</div>
                                <div className="value">{data.equipments}</div>
                            </div>
                        }
                    </div>
                    {isLargeEquipment &&
                        <div className="info-item">
                            <div className="label">{translate('Course.Equipments')}</div>
                            <div className="value multiline">{data.equipments}</div>
                        </div>
                    }
                    {lessonCount &&
                        <div className="info-item">
                            <div className="label">{translate('Course.LessonList')}</div>
                            <div className="value">
                                <LessonList lessons={data.lessons} termId={1} />
                                <LessonList lessons={data.lessons} termId={2} />
                                <LessonList lessons={data.lessons} termId={3} />
                            </div>
                        </div>
                    }
                </div>
                <div className="note-part">
                    {data.paymentInfo &&
                        <div className="note-item">
                            <span className="note-mark">1)</span>
                            <div className="multiline">{data.paymentInfo}</div>
                        </div>
                    }
                    {data.isExternal &&
                        <div className="note-item"><span className="note-mark">2)</span>{translate('Course.NoteExternal')}</div>
                    }
                </div>
                <div className="lector-part">
                    <div className="lector-item">
                        <div className="lector-info">
                            <div className="info-item">
                                <div className="label">{translate('Course.Teacher')}</div>
                                <div className="value">{teacherName}</div>
                            </div>
                            <div className="info-item">
                                <div className="label">{translate('Course.Email')}</div>
                                <div className="value">{teacherEmail}</div>
                            </div>
                            <div className="info-item">
                                <div className="label">{translate('Teacher.Phone')}</div>
                                <div className="value">{teacherPhone}</div>
                            </div>
                        </div>
                        <div className="lector-img">
                            <img src={teacherImg} alt={translate('Course.Teacher')} />
                        </div>
                        <div className="lector-note">
                            <div className="info-item">
                                <div className="value multiline">{teacherDescription}</div>
                            </div>
                        </div>
                    </div>
                    <div className="lector-item">
                        <div className="lector-info">
                            <div className="info-item">
                                <div className="label">{translate('Menu.Classroom')}</div>
                                <div className="value">{classroomName}</div>
                            </div>
                            <div className="info-item">
                                <div className="label">{translate('Classroom.Address')}</div>
                                <div className="value multiline">{classroomAddress}</div>
                            </div>
                        </div>
                        <div className="lector-img">
                            <img src={classroomImg} alt={translate('Classroom.Title')} />
                        </div>
                        <div className="lector-note">
                            <div className="info-item">
                                <div className="value multiline">{classroomDescription}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="img">
                    <img src={imgUrl} alt={translate('Course.Title')} />
                </div>
                <Modal
                    modalClass="register-modal"
                    onClose={() => this.closeRegmodal()}
                    onSubmit={() => this.submitRegmodal()}
                    title={translate('Course.Register')}
                    show={regmodal.open}
                    closeOnClick={true}
                >
                    <div>
                        <fieldset>
                            <div className="form-group">
                                <label htmlFor="course">{translate('Course.Title')}</label>
                                <input
                                    className="form-control"
                                    name="course"
                                    type="text"
                                    autoComplete="off"
                                    disabled
                                    value={title}
                                    onChange={e => { }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="student">{translate('Course.Student')}</label>
                                <select
                                    className="form-control"
                                    name="student"
                                    value={regmodal.student}
                                    onChange={e => this.setState({ regmodal: { ...regmodal, student: e.target.value } })}
                                >
                                    {students.map(o => (
                                        <option key={o.id} value={o.id}>{o.name}</option>
                                    ))}
                                </select>
                                <div className={classnames("control-error", { disabled: !studentError })}>{studentError}</div>
                            </div>
                            <div className="form-group">
                                <button
                                    className={classnames("btn btn-imsp", { disabled: reg2Disabled })}
                                    onClick={() => this.submitRegmodal()}
                                >{translate('Course.DoRegister')}</button>
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeRegmodal()}
                                >{translate('Btn.Back')}</button>
                            </div>
                        </fieldset>
                    </div>
                </Modal>
            </div>
        );
    }
};

export default connect(
    state => ({
        // location: state.router.location,
        language: state.localize.language
    }),
    {
        push
    }
)(Course);