import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import { toTerm, toMmDdFormat, toTimeFormat, parseDate } from '../../../utils/date';

import './lectorAttendance.scss';
import { errorEquals, ERR_PERMISSION_DENIED } from '../../../utils/error';
import schoolYear from '../../../utils/schoolYear';


class LectorAttendance extends React.Component {
    static propTypes = {
        profile: PropTypes.object.isRequired,
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            courses: [],
            courseFilter: '',
            lessonFilter: '',
            attendances: {},
            selected: '',
            schyFilter: { value: schoolYear().year(), label: schoolYear().yearText() }
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadCourses();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Header.LectorAttendance');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadCourses() {
        let { schyFilter } = this.state;

        this.api.getCoursesWithTeacher({ year: schyFilter.value })
            .then(response => {
                if (this.mounted) {
                    let courses = response.data || [];
                    let lesson = this.getSomeLesson(courses, null, (this.props.profile.data || {}).id);
                    let courseFilter = (lesson || {}).courseId;
                    let lessonFilter = (lesson || {}).id;
                    this.setState({ courses, courseFilter, lessonFilter }, () => {
                        this.loadAttendances();
                    });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadAttendances(lessonId) {
        let { lessonFilter, schyFilter } = this.state;
        lessonId = lessonId || lessonFilter;
        // console.log('loadAttendances', { lessonId });
        if (!lessonId) {
            this.setState({ attendances: {} });
            return;
        }

        this.api.getLessonAttendances({ lessonId, year: schyFilter.value })
            .then(response => {
                if (this.mounted) {
                    let attendances = _.mapKeys(response.data, 'id');
                    this.setState({ attendances });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    onCourseFilterChange(e) {
        let { courses } = this.state;

        let courseFilter = e.target.value;
        let lesson = this.getSomeLesson(courses, courseFilter);
        let lessonFilter = (lesson || {}).id;
        // console.log('onCourseFilterChange', { courseFilter, lessonFilter, lesson });
        this.setState({ courseFilter, lessonFilter, selected: '' }, () => {
            this.loadAttendances();
        });
    }

    onLessonFilterChange(e) {
        this.setState({ lessonFilter: e.target.value, selected: '' }, () => {
            this.loadAttendances();
        });
    }

    getCourseList(courses, userId) {
        let result = arraySort(courses, 'name');
        return result;
    }

    getSomeLesson(courses, courseId = null, userId) {
        if (!courses) {
            return null;
        }

        let now = new Date();
        if (courseId) {
            courses = courses.filter(o => o.id === courseId);
        }
        let myBef = [];
        let myAft = [];
        let otherBef = [];
        let otherAft = [];
        for (let i = 0; i < courses.length; i++) {
            const course = courses[i];
            const my = userId && (course.teacher || {}).userId === userId;

            for (let j = 0; j < (course.lessons || []).length; j++) {
                const lesson = course.lessons[j];
                const bef = parseDate(lesson.time) < now.getTime();

                if (my && bef) myBef.push(lesson)
                else if (my && !bef) myAft.push(lesson)
                else if (!my && bef) otherBef.push(lesson)
                else if (!my && !bef) otherAft.push(lesson);
            }
        }
        return this.getLast(arraySort(myBef, 'time'))
            || this.getFirst(arraySort(myAft, 'time'))
            || this.getLast(arraySort(otherBef, 'time'))
            || this.getLast(arraySort(otherAft, 'time'));
    }

    getLast(arr) {
        if (arr && arr.length) {
            return arr[arr.length - 1];
        }

        return null;
    }

    getFirst(arr) {
        if (arr && arr.length) {
            return arr[0];
        }

        return null;
    }

    getLessonOptions(courses, courseFilter) {
        let course = _.find(courses, { id: courseFilter });
        let result = arraySort(((course || {}).lessons || []), 'time').map(o => {
            let name = `${toTerm(o.time).name} / ${o.order}`;
            let time = `${toMmDdFormat(o.time)} ${toTimeFormat(o.time)}`;
            return (
                <option key={o.id} value={o.id}>{name}&nbsp;&nbsp;({time})</option>
            );
        });
        return result
    }

    getAttendanceList(attendances) {
        let result = _.map(attendances);
        return result;
    }

    select(id) {
        let { selected } = this.state;

        selected = id === selected ? '' : id;
        this.setState({ selected });
    }

    setPresence(id, value) {
        if (!id) {
            return;
        }

        this.api.setAttendance(id, value)
            .then(response => {
                if (this.mounted) {
                    let { attendances, lessonFilter } = this.state;

                    attendances = { ...attendances };
                    attendances[id] = { ...attendances[id], present: value };
                    this.setState({ attendances, selected: '' }, () => {
                        this.loadAttendances(lessonFilter);
                    });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    if (errorEquals(error, ERR_PERMISSION_DENIED)) {
                        toastr.warning(this.context.translate('Attendance.InvalidPermission'));
                    } else {
                        toastr.error(this.context.translate('Err.System'));
                    }
                }
            });
    }

    renderPresence(presence, selected, id) {
        if (selected) {
            return (
                <div className="buttons">
                    <div
                        className="presence-btn"
                        onClick={() => this.setPresence(id, true)}
                    ><i className="fa fa-check" aria-hidden="true"></i></div>
                    <div
                        className="presence-btn"
                        onClick={() => this.setPresence(id, null)}
                    ><i className="fa fa-question" aria-hidden="true"></i></div>
                    <div
                        className="presence-btn"
                        onClick={() => this.setPresence(id, false)}
                    ><i className="fa fa-times" aria-hidden="true"></i></div>
                </div>
            );
        } else {
            if (!(presence === null || presence === undefined)) {
                if (presence) {
                    return (<i className="fa fa-check" aria-hidden="true"></i>);
                }
                return (<i className="fa fa-times" aria-hidden="true"></i>);
            }

            return (<i className="fa fa-question" aria-hidden="true"></i>);
        }
    }

    render() {
        let { courses, courseFilter, lessonFilter, attendances, selected } = this.state;
        let { profile } = this.props;

        let courseList = this.getCourseList(courses, (profile.data || {}).id);
        let lessonOptions = this.getLessonOptions(courses, courseFilter);
        let attendanceList = this.getAttendanceList(attendances);

        return (
            <div className="page-container attendance-page">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Course')}</label>
                            <select
                                className="form-control"
                                value={courseFilter}
                                onChange={e => this.onCourseFilterChange(e)}
                            >
                                {courseList.map(o => (
                                    <option key={o.id} value={o.id}>{o.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Lesson')}</label>
                            <select
                                className="form-control"
                                value={lessonFilter}
                                onChange={e => this.onLessonFilterChange(e)}
                            >
                                {lessonOptions}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="structure-list">
                        <div className="header">
                            <div className="pc-style">
                                <div className="line">
                                    <div className="column fill">{this.context.translate('Attendance.Student')}</div>
                                    <div className="column f-120 ta-c">{this.context.translate('Attendance.Presence')}</div>
                                </div>
                            </div>
                            <div className="mob-style">
                                <div className="line">
                                    <div className="column fill">{this.context.translate('Attendance.Student')}</div>
                                    <div className="column f-120 ta-c">{this.context.translate('Attendance.Presence')}</div>
                                </div>
                            </div>
                        </div>
                        <div className="body">
                            {attendanceList.map(o => {
                                let isSelected = selected === o.id;
                                return (
                                    <div
                                        className={classnames('item', { selected: isSelected })}
                                        key={o.id}
                                        onClick={() => this.select(o.id)}
                                    >
                                        <div className="pc-style">
                                            <div className="line">
                                                <div className="value fill">{(o.student || {}).name}</div>
                                                <div className="value f-120 ta-c presence">{this.renderPresence(o.present, isSelected, o.id)}</div>
                                            </div>
                                        </div>
                                        <div className="mob-style">
                                            <div className="line">
                                                <div className="value fill">{(o.student || {}).name}</div>
                                                <div className="value f-120 ta-c presence">{this.renderPresence(o.present, isSelected, o.id)}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        profile: state.profile,
    }),
    {}
)(LectorAttendance);