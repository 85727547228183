import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { ApiService } from '../../../services/ApiService';
import { toDate, toTimeFormat, toLunchShortDate } from '../../../utils/date';
import { getCourseGroupList } from '../../../utils/course';
import { arraySort } from '../../../utils/sort';
import LessonTime from './LessonTime';


class Overview extends React.Component {
    static propTypes = {
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            registrations: []
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadRegistraions();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Overview.Title');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadRegistraions() {
        this.api.getMyRegistrations()
            .then(response => {
                if (this.mounted) {
                    let registrations = response.data || [];
                    this.setState({ registrations });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    formatTime(time, translate) {
        return time
            ? `${translate(`Days.Short.${toDate(time).getDay()}`)} ${toLunchShortDate(time)} ${toTimeFormat(time)}`
            : translate('Overview.NoTime');
    }

    getRegistrationList(registrations) {
        let { translate } = this.context;

        let result = registrations.reduce((r, o) => {
            let time = o.type === 'course'
                ? this.formatTime(o.nextLessonTime, translate)
                : this.formatTime(o.appointmentTime, translate);
            let key = `${o.nextLessonTime ? toDate(o.nextLessonTime).getTime() : 'null'}_${o.type == 'course' ? o.courseId : o.appointmentId}_${o.type}`;
            let studentName = o.isAlternate ? `${o.studentName} (${translate('Overview.Alternate')})` : o.studentName;
            let link = o.type === 'course'
                ? `/course/${o.courseId}`
                : `/meeting/${o.meetingId}`;
            if (o.type == 'course' && key in r) {
                r[key].items.push(o);
                r[key].studentName = `${r[key].studentName}, ${studentName}`;
            } else {
                let item = {
                    key,
                    time,
                    items: [o],
                    name: o.type === 'course' ? o.course.name : o.meetingName,
                    link,
                    studentName,
                    groups: getCourseGroupList(o.course),
                    nextLessonTime: o.nextLessonTime
                };
                r[key] = item;
            }
            return r;
        }, {});
        result = _.map(result);
        // console.log('result', result);
        arraySort(result, 'key');
        return result;
    }

    render() {
        let { push } = this.props;
        let { translate } = this.context;
        let { registrations } = this.state;

        let registrationList = this.getRegistrationList(registrations);

        return (
            <div className="user-page-container">
                <div className="buttons">
                    <div
                        className="btn btn-imsp"
                        onClick={() => push('/menu')}
                    >{translate('Overview.Register')}</div>
                </div>
                <div className="structure-list">
                    <div className="header">
                        <div className="pc-style">
                            <div className="line">
                                <div className="column f-140">{translate('Overview.Time')}</div>
                                <div className="column fill w-30pr">{translate('Overview.Course')}</div>
                                <div className="column fill w-60pr">{translate('Overview.Student')}</div>
                            </div>
                        </div>
                        <div className="mob-style">
                            <div className="line">
                                <div className="column f-140">{translate('Overview.Time')}</div>
                                <div className="column fill w-80pr">{translate('Overview.Course')}</div>
                            </div>
                            <div className="line">
                                <div className="column fill">{translate('Overview.Student')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="body">
                        {registrationList.map(o => {
                            return (
                                <div
                                    className="item"
                                    key={o.key}
                                    onClick={() => push(o.link)}
                                >
                                    <div className="pc-style">
                                        <div className="line">
                                            <div className="value f-140">{o.time}</div>
                                            {/* <div className="value f-140"><LessonTime value={o.nextLessonTime} /></div> */}
                                            <div className="value fill w-30pr">{o.name}</div>
                                            <div className="value fill w-60pr">{o.studentName}</div>
                                        </div>
                                    </div>
                                    <div className="mob-style">
                                        <div className="line">
                                            <div className="value f-140">{o.time}</div>
                                            <div className="value fill w-80pr">{o.name}</div>
                                        </div>
                                        <div className="line">
                                            <div className="value fill">{o.studentName}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    { push }
)(Overview);