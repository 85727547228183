const en = {
	"Account": {
		"Name": "Name",
		"Email": "E-mail",
		"Address": "Address",
		"AccNum": "Account number",
		"Diners": "Diners",
		"Roles": "Roles",
		"SetRole": "Set Authorizations",
		"IsTeacher": "Teacher",
		"SetAsDiner": "Set as diner",
		"Delete": "Delete",
		"DeletedSuccessfuly": "Account successfuly deleted.",
		"ConfirmDelete": "Are you sure you want to delete the selected account?",
		"ConfirmForceDelete": "Data already exists for the selected account. Are you sure you want to delete it?",
		"WithoutStudent": "Without student"
	},
	"Alergen": {
		"Id": "ID",
		"Cs": "Czech",
		"En": "English",
		"ModalTitle": "Alergen",
		"SaveSuccess": "Alergen was saved successfuly.",
		"DeleteSuccess": "Alergen was deleted successfuly."
	},
	"Billing": {
		"User": "User",
		"Diner": "Diner",
		"CreditBefore": "Opening balance",
		"Menu": "Meals",
		"Snack": "Snacks",
		"Total": "Total",
		"Credit": "Closing balance",
		"Recalculate": "Recalculate...",
		"CalcTitle": "Recalculate billing",
		"CalcSubmit": "Run",
		"ConfirmRecalculate": "Are you sure you want to recalculate billing for {period} and all subsequent billing?",
		"RecalculateSent": "Recalculation request sent successfully. Relax for a minute, then refresh the page.",
		"ProcTitle": "Billing calculation process",
		"ProcQueue": "Request queue",
		"ProcJob": "Currently running request",
		"ProcCalc": "Running calculation",
		"Period": "Period",
		"Student": "Student",
		"All": "All",
		"Deposit": "Deposit",
		"Payment": "Payment",
		"Suma": "Suma",
		"Balance": "Balance"
	},
	"Btn": {
        "Add": "Add",
        "Update": "Update",
        "Delete": "Delete",
		"Cancel": "Cancel",
		"Save": "Save",
		"Export": "Export",
		"Import": "Import",
		"Close": "Close",
		"Select": "Select",
		"Send": "Send",
		"Print": "Print",
		"Back": "Back",
		"Create": "Create",
		"Finish": "Conclude"
	},
	"Codelist": {
		"All": "< all >",
		"ChoseDiner": "< chose diner >",
		"Yes": "Yes",
		"No": "No"
	},
	"Daily": {
		"Diner": "Diner",
		"Group": "Group"	
	},
    "Days": {
		"Basic": {
			"0": "Sunday",
			"1": "Monday",
			"2": "Tuesday",
			"3": "Wednesday",
			"4": "Thursday",
			"5": "Friday",
			"6": "Saturday"
		},
		"Short": {
			"0": "Sun",
			"1": "Mon",
			"2": "Tue",
			"3": "Wed",
			"4": "Thu",
			"5": "Fr",
			"6": "Sat"
		}
    },
	"Diner": {
		"Name": "Name",
		"Group": "Group",
		"Account": "Account",
		"UserDiner": "Is account",
		"NotPay": "All inclusive",
		"GlutenFree": "Gluten-free menu",
		"Move": "Move",
		"ModalTitle": "Diner",
		"MoveModalTitle": "Move diners",
		"MoveSubmit": "Move",
		"MoveSuccess": "Diners was moved successfuly.",
		"PaymentId": "Payment ID",
		"Balance": "Balance",
		"Unposted": "Unposted orders",
		"ConfirmDelete": "Are you sure you want to delete the selected diner?",
		"DeleteSuccess": "The diner has been successfully deleted.",
		"DeleteDependencies": "The diner you are about to delete already has data (orders, payments, billing). Are you sure you want to delete it with all data?",
		"Hidden": "Hide in orders"
	},
	"Emails": {
		"To": "To",
		"Subject": "Subject",
		"Message": "Message",
		"SelectTo": "Select reseivers",
		"All": "Everybody",
		"SendSuccess": "E-mail was sent successfuly."
	},
	"Filter": {
        "Period": "Period",
        "Diner": "Diner",
		"Group": "Group",
		"Date": "Date",
		"Title": "Filter",
		"AllInclusive": "All Inclusive",
		"IsUser": "Is user",
		"Name": "Name",
		"Type": "Type",
		"Course": "Enrichment class",
		"Lesson": "Lesson",
		"Student": "Student",
		"Closed": "Show concluded",
		"Alternate": "Waitlist",
		"Account": "Account",
		"Term": "Term",
		"Hidden": "Hidden",
		"Disabled": "Active",
		"ActivityType": "Activity type"
	},
	"Group": {
		"Title": "Group",
		"Name": "Name",
		"Order": "Order in Monthly Review",
		"AddSuccess": "Group created successfuly.",
		"EditSuccess": "Group updated successfuly."
	},
    "Header": {
        "Login": "Login",
        "Register": "Register",
        "Menu": "Menu",
        "Payments": "Payments",
        "Logout": "Logout",
        "Administration": "Administration",
        "Accounts": "User accounts",
        "Diners": "Diners",
        "Groups": "Groups",
        "Lunch": "Menu",
		"Profile": "Profile",
		"AdminPayments": "Payments",
		"Billing": "Billing",
		"Alergens": "Alergens",
		"Settings": "Settings",
		"Reports": "Reports",
		"OrderSummary": "Orders counts",
		"OrderDay": "Diners orders",
		"OrderMonth": "Month orders",
		"Emails": "Send e-mail",
		"About": {
			"Title": "About",
			"Name": "Name",
			"App": "IMSP Registration system",
			"Version": "Version",
			"Copyright": "© 2020 Copyright by IMSP. All right reserved.",
			"By": "Created by"
		},
		"Notice": "Sent notices",
		"Teachers": "Teachers",
		"Classrooms": "Locations",
		"Students": "Students",
		"ReportWeek": "Week overview",
		"LectorAttendance": "Attendance",
		"Registrations": "Registration",
		"AttendanceView": "Attendance",
		"LectorEmail": "Send email"
    },
	"ImportResult": {
		"Title": "Import result"
	},
    "Lunch": {
        "SaveSuccess": "Menu was saved successfuly.",
        "ImportSuccess": "{{count}} records was imported successfuly.",
        "ImportError": "{{count}} records was succesfuly imported, {{errCount}} records finished with error:</br>{{errors}}",
        "Period": "Period",
        "Lang": "Language",
        "DownloadImport": "Download example file",
        "ColDate": "Date",
        "ColLang": "Language",
        "ColSoup": "Soup",
		"RemoveSuccess": "Menu successfully deleted.",
		"ConfirmRemove": "Are you sure you want to delete the selected record?"
    },
    "LunchMenu": {
		"NoDiner": "Welcome to the IMSP Lunch System. You have no diners set up for your account yet, so you can't place an order.",
        "Balance": "Account balance",
        "UnpostedOrders": "Unposted orders",
        "PaymentInfo": "Show payment information",
        "Alergens": "Alergens",
		"NoAlergens": "Alergens are not specified",
		"AlergensNotParsed": "Alergens was not recognized",
		"Modal": {
			"Title": "Payment informations",
			"Number": "Account number",
			"Symbol": "Variable symbol",
			"Amount": "Amount",
			"Note": "The payment amount is only a guideline, calculated based on your account balance and orders in a given month.",
			"Close": "Close",
			"Ss": "Specifický symbol"
		},
		"ExportMenu": "Export menu",
		"MissingBillingInfo": {
			"Part1": "Dear user, You do not have filled in billing information. Please set them up in the ",
			"Part2": " section. If you don't have your billing information filled in, you won't be allowed to order lunches."
		},
		"LimitExceeded": "Maximal debit limit was exceeded.",
		"HidePast": "Hide old"
    },
	"MealType": {
		"0": "Soup",
		"1": "Meat menu",
		"2": "Vegetarian menu",
		"3": "Gluten-free menu",
		"4": "Snack"
	},
	"Monthly": {
		"Diner": "Diner",
		"Group": "Group",
		"Print": {
			"Title": "Monthly order overview",
			"All": "all"
		}
	},
    "Months": {
		"Basic": {
			"1": "January",
			"2": "February",
			"3": "March",
			"4": "April",
			"5": "May",
			"6": "June",
			"7": "July",
			"8": "August",
			"9": "September",
			"10": "October",
			"11": "November",
			"12": "December"
		},
		"OfDate": {
			"1": "January",
			"2": "February",
			"3": "March",
			"4": "April",
			"5": "May",
			"6": "June",
			"7": "July",
			"8": "August",
			"9": "September",
			"10": "October",
			"11": "November",
			"12": "December"
		},
		"Short": {
			"1": "Jan",
			"2": "Feb",
			"3": "Mar",
			"4": "Apr",
			"5": "May",
			"6": "Jun",
			"7": "Jul",
			"8": "Aug",
			"9": "Sept",
			"10": "Oct",
			"11": "Nov",
			"12": "Dec"
		}
	},
	"Notice": {
		"Time": "Send time",
		"Period": "Period",
		"Result": "Result",
		"CountText": "Number of debtors",
		"Ok": "Everything OK",
		"Errors": "Errors",
		"Diner": "Diner",
		"Balance": "Balance",
		"Error": "Error"
	},
	"OrderView": {
		"Date": "Date",
		"Count": "Count"
	},
    "Payment": {
		"TypePayment": "Payment",
		"TypeDeposite": "Deposite",
		"Time": "Time",
		"Diner": "Diner",
		"Type": "Type",
		"Description": "Description",
		"Amount": "Amount",
		"SumaPayment": "Total payments",
		"AddTitle": "New record",
        "ImportSuccess": "{{count}} records was imported successfuly.",
        "ImportError": "{{count}} records was succesfuly imported, {{errCount}} records finished with error:</br>{{errors}}",
		"Balance": "Account balance",
		"TotalDeposite": "Total deposits",
		"ImportExample": "Example import file",
		"Import": "Import deposits",
		"ConfirmDelete": "Are you sure you want to delete the selected record?",
		"MealType": "Meal",
		"EditTitle": "Edit record",
		"Student": "Student",
		"Course": "Course registration",
		"Note": "Note"
	},
	"Profile": {
		"Profile": "Profile",
		"Name": "Name",
		"Email": "E-mail",
		"Invoice": "Billing information",
		"Address": "Address",
		"Account": "Account number",
		"Ico": "Tax ID (IČO)",
		"ProfileSuccess": "Profile was saved.",
		"BillingSuccess": "Billing inforations saved."
	},
	"Settings": {
		"Title": "Settings",
		"Other": "Other parameters",
		"AccountNumber": "Account number",
		"SpecificSymbol": "Specific symbol",
		"OtherSuccess": "Other parameters was saved successfuly.",
		"SendTeacherEmailCopy": "Send a copy of the email from teacher",
		"AdminEmail": "Admin's email",
		"EmailSection": "Email settings",
		"SaveEmailSuccess": "Email settings saved successfully.",
		"NoticeSection": "Negative balance notification",
		"NegBalNotifEnabled": "Notification enabled",
		"NegBalNotifPeriod": "Day of notification",
		"NegBalNotifTime": "Notification time",
		"SaveNoticeSuccess": "Notification settings saved successfully.",
		"ShowCourseDaysBeforeSchy": "Number of days to display circles before the start of the school year",
		"AutoCreatePaymentInterval": "Number of days before the semester for account enrichment classes"
	},
    "Units": {
        "Money": "CZK"
	},
	"Course": {
		"Title": "Course",
		"Name": "Name",
		"Teacher": "Teacher",
		"Capacity": "Capacity",
		"Description": "Description",
		"Alternate": "Waitlist",
		"OpenFrom": "Registration open from",
		"OpenTo": "Registration open to",
		"Price": "Price per lesson",
		"IsExternal": "External course",
		"Equipments": "Equipments",
		"Photo": "Photo",
		"LoadPhoto": "Load photo",
		"EditSuccess": "Course was modified successfully.",
		"AddSuccess": "Course was created successfully.",
		"ConfirmDelete": "Are you sure you want to delete the selected course?",
		"DeleteSuccess": "Course was deleted successfully.",
		"Group": "Group filter",
		"GroupCol": "Group",
		"Lessons": "Lessons",
		"LessonCount": "Lesson count / school year",
		"CreateLessons": "Generate",
		"Register": "Registration",
		"DoRegister": "Register",
		"All": "No limit",
		"Free": "Free",
		"OpenRegInfo": "Registration will open in {ts}.",
		"Student": "Student",
		"NoStudentsInfo": "There is no student assigned to your account.",
		"RegisterSuccess": "The student was successfully registered.",
		"StudentAlreadyRegistered": "Student {student} is already registered.",
		"CapacityReached": "The capacity of the ring has been filled. Further registration is not possible.",
		"NotOpenYet": "The enrichment class is not yet open for registration. Please try again later.",
		"InvalidGroup": "The student's group is not allowed on this enrichment class.",
		"Email": "E-mail",
		"NoteExternal": "This is an external course and is not included in the All-Inclusive option.  All-inclusive students must pay the course fees to the instructor directly.",
		"PaymentInfo": "Payment information",
		"InvalidCapacity": "The capacity of the enrichment class cannot be less than the number of already registered students.",
		"IsDisabled": "Active",
		"IsHidden": "Hidden",
		"DisabledCourse": "The enrichment class is disabled.",
		"LessonList": "Lesson list",
		"Order": "Order",
		"Notifications": "Notifications",
		"CourseDisabled": "The registration cannot be created because the enrichment class is disabled."
	},
	"AdminCourses": {
		"Title": "Enrichment classes"
	},
	"Menu": {
		"Title": "Enrichment offer",
		"MenuLink": "Offer",
		"Courses": "Enrichment class",
		"Group": "Class",
		"Teacher": "Teacher",
		"Price": "Price/Lesson",
		"Capacity": "Capacity",
		"Begin": "Starts on",
		"Classroom": "Where",
		"Ttx": "number of registered persons",
		"Tty": "course capacity",
		"Ttz": "max. number of substitutes",
		"Meetings": "Class meetings"
	},
	"Overview": {
		"Title": "Activity overview",
		"MenuLink": "Overview",
		"Time": "Time",
		"Course": "Activity",
		"Student": "Student",
		"Register": "Register another",
		"NoTime": "none",
		"Alternate": "waitlist"
	},
	"Week": {
		"Activity": "Activity",
		"Time": "Time",
		"Students": "Students",
		"Classroom": "Location",
		"Teacher": "Teacher",
		"Title": "Weekly overview",
		"NoGroup": "No group"
	},
	"Classroom": {
		"Title": "Classroom",
		"Name": "Name",
		"Address": "Address",
		"Description": "Description",
		"Photo": "Photo",
		"LoadPhoto": "Load photo",
		"EditSuccess": "Classroom was successfully modified.",
		"AddSuccess": "Classroom was successfully created.",
		"DeleteSuccess": "Classroom was successfully deleted.",
		"ConfirmDelete": "Are you sure you want to delete the selected classroom?"
	},
	"Payments": {
		"Date": "Date",
		"Type": "Type",
		"Student": "Student",
		"Course": "Enrichment class",
		"Note": "Note",
		"Amount": "Amount",
		"DeleteConfirm": "Are you sure you want to delete the selected transaction?",
		"DeleteSuccess": "Transaction was successfully deleted."
	},
	"TransactionType": {
		"0": "Payment",
		"1": "Deposite",
		"2": "Cancellation",
		"3": "Return"
	},
	"UserBilling": {
		"Student": "Student",
		"Balance": "Balance",
		"Date": "Date",
		"Type": "Type",
		"Note": "Note",
		"Amount": "Amount",
		"Course": "Course",
		"Payment": "Payment",
		"Pay": "Pay",
		"Modal": {
			"Title": "Payment informations",
			"Number": "Account number",
			"Symbol": "Variable symbol",
			"Amount": "Amount",
			"Ss": "Specifický symbol"
		}
	},
	"Attendance": {
		"Student": "Student",
		"Presence": "Present",
		"Lessons": "Lessons",
		"InvalidPermission": "Insufficient permissions. Only a teacher of course or administrator can set attendance.",
		"SumNonInclusive": "Sum of lessons registred non-all-inclusive students",
		"SumInclusive": "Sum of lessons registred all-inclusive students",
		"RegisterCount": "Registred students count"
	},
	"LectorEmail": {
		"Title": "Send email"
	},
	"Students": {
		"Title": "Students",
		"Name": "Name",
		"User": "User account",
		"Group": "Group",
		"Modal": {
			"Title": "Student"
		},
		"Free": "All inclusive",
		"Balance": "Balance",
		"PaymentId": "Payment ID",
		"IsDiner": "Is diner",
		"ImportDiners": "Import diners",
		"All": "All",
		"ConfirmDelete": "Are you sure you want to delete the selected student?",
		"ConfirmForceDelete": "Dependent data (diner, registration, attendance or transactions) are already linked to the selected student. Are you sure you want to delete it? If the deletion is confirmed, the dependent data will also be deleted, the boarder will be unmapped.",
		"DeletedSuccessfuly": "Student deleted successfully.",
		"From": "School year from",
		"To": "School year to"
	},
	"Teacher": {
		"Title": "Teacher",
		"Name": "Name",
		"Photo": "Photo",
		"LoadPhoto": "Load photo",
		"EditSuccess": "Teacher was successfully edited.",
		"AddSuccess": "Teacher was successfully created.",
		"DeleteSuccess": "Teacher was successfully deleted.",
		"ConfirmDelete": "Are you sure you want to delete the selected teacher?",
		"Email": "Email",
		"User": "User",
		"Phone": "Phone",
		"Description": "Description",
		"From": "School year from",
		"To": "School year to"
	},
	"Register": {
		"Title": "Registration",
		"Course": "Enrichment class",
		"Student": "Student",
		"From": "From",
		"To": "To",
		"Alternate": "Waitlist",
		"ConfirmDelete": "Are you sure you want to delete the selected registration?",
		"ConfirmForceDelete": "Dependent data (transaction or attendance) already exists for the selected registration. Consider ending the registration. If the registration is deleted, the dependent data will also be deleted. Do you really want to do this?",
		"ConfirmClose": "Are you sure you want to conclude the selected registration?",
		"RegisterSuccess": "Registration successfully created.",
		"DuplicateRecord": "Registration for the selected circle and student already exists.",
		"UpdAttBtn": "Update attendance",
		"UpdAttSuccess": "Attendance was updated.",
		"Group": "Group",
		"ToDate": "Registration end date",
		"ToDateTip": "Date of the first day when the registration is not valid.",
		"CloseSuccess": "Registration termination set."
	},
	"Err": {
		"System": "Unexpected error. Please contact your system administrator.",
		"UserIsTeacher": "The user is already set up as a teacher.",
		"InvalidInput": "Invalid input parameters."
	},
	"AdminBilling": {
		"Title": "Billing",
		"MenuLink": "Billing"
	},
	"JobInfo": {
		"All": "All students"
	},
	"General": {
		"SchoolYear": "School year"
	},
	"CourseNotification": {
		"Title": "Notification",
		"Text": "{interval} days before {type}",
		"Sent": "Sent at"
	},
	"CourseNotificationType": {
		"R": "enrichment class registration",
		"L": "enrichment class begining"
	},
	"ClassMeetings": {
		"Title": "Class meetings",
		"DeletedSuccess": "Class meeting was deleted.",
		"DeleteConfirm": "Are you sure you want to delete class meeting?"
	},
	"ClassMeeting": {
		"Title": "Class meeting",
		"Name": "Name",
		"Description": "Description",
		"Teacher": "Teacher",
		"RegFrom": "Registration from",
		"RegTo": "Registration to",
		"IsDisabled": "Disabled",
		"IsHidden": "Hidden",
		"Group": "Groups",
		"Appointments": "Appointments",
		"Register": "Register",
		"Unregister": "Cancel registration"
	},
	"ClassMeetingNotificationType": {
		"R": "class meeting registration",
		"L": "class meeting begining"
	},
	"AppointmentControl": {
		"Reserved": "Reserved"
	},
	"RegMeeting": {
		"Title": "Class meeting appointment registration",
		"Appointment": "Appointment",
		"Student": "Student",
		"RegSuccess": "Appointment was registred.",
		"MeetingDisabled": "The class meeting is not active.",
		"MeetingRegNotOpen": "It is currently not possible to register/unregister.",
		"AppointmentPassed": "The deadline has already passed.",
		"AppointmentEngaged": "The appointment cannot be registered because it is already taken.",
		"StudentRegistred": "The appointment cannot be registered because the meeting regarding the selected student is already registered.",
		"AllowInterval": "Term registration is allowed only on the term",
		"IntervalFrom": " from ",
		"IntervalTo": " to ",
		"Reserved": "Reserved",
		"UnregConfirm": "Are you sure you want to cancel your reservation?",
		"UnregSuccess": "Reservation was cancelled.",
		"InvalidGroup": "The appointment cannot be registered because the student does not belong to any of the allowed groups."
	},
	"Appointments": {
		"Link": "Appointments",
		"Title": "Appointments",
		"Time": "Time",
		"Meeting": "Meeting",
		"Student": "Student",
		"User": "Parent",
		"Cancel": "Cancel reservation",
		"CancelConfirm": "Are you sure you want to cancel your reservation?"
	},
	"ImgControl": {
		"Label": "Photo",
		"Load": "Load photo"
	}
};

export default en;