import * as toastr from 'toastr';
import classnames from 'classnames';
import schoolYear, { getSchyFilterOptions } from '../../../utils/schoolYear';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { AppContext } from '../../AppContext';
import { ApiService } from '../../../services/ApiService';
import moment from 'moment';
import { errorEquals, ERR_APPOINTMENT_PASSED, ERR_MEETING_DISABLED, ERR_MEETING_REG_NOT_OPEN } from '../../../utils/error';
import AppointmentModal from './AppointmentModal';


const Appointments = ({ onTitle }) => {

    const defModal = { open: false, ts: null };

    let { translate } = useContext(AppContext);
    let [schyFilter, setSchyFilter] = useState({ value: schoolYear().year(), label: schoolYear().yearText() });
    let [appointments, setAppointments] = useState([]);
    let [selected, setSelected] = useState(null);
    let [modal, setModal] = useState(defModal);

    let api = new ApiService();

    useEffect(() => {
        if (onTitle) onTitle('Appointments.Title');
    }, [onTitle])

    useEffect(() => {
        loadAppointments();
    }, [schyFilter]);

    const loadAppointments = async () => {
        try {
            let response = await api.getAppointments({ year: schyFilter.value });
            let data = response.data || [];
            setAppointments(data);
        } catch (error) {
            console.log(error);
        }
    }

    const onSchyFilterChange = value => {
        setSchyFilter(value);
    }

    const onRowClick = item => {
        setSelected(selected && selected.id === item.id ? null : item);
    }

    const onRowDoubleClick = item => {
        setSelected(item);
        // setModal({ ...modal, open: true, ts: new Date().getTime(), id: item.id });
    }

    const onAdd = () => {
        setModal({ ...modal, open: true, ts: new Date().getTime() });
    }

    const onModalCancel = () => {
        setModal({ ...modal, ...defModal });
    }

    const onModalSubmit = () => {
        loadAppointments();
        onModalCancel();
    }

    const onDelete = async () => {
        if (selected && selected.id && window.confirm(translate('Appointments.CancelConfirm'))) {
            try {
                let data = {
                    appointmentId: selected.id
                };
                let response = await api.unregisterMeeting(data);
                if (response.data) {
                    loadAppointments();
                    toastr.success(translate('RegMeeting.UnregSuccess'));
                }
            } catch (error) {
                if (errorEquals(error, ERR_MEETING_DISABLED)) {
                    toastr.error(translate('RegMeeting.MeetingDisabled'));
                } else if (errorEquals(error, ERR_MEETING_REG_NOT_OPEN)) {
                    toastr.error(translate('RegMeeting.MeetingRegNotOpen'));
                } else if (errorEquals(error, ERR_APPOINTMENT_PASSED)) {
                    toastr.error(translate('RegMeeting.AppointmentPassed'));
                } else {
                    toastr.error(translate('Err.System'));
                }
            }
        }
    }

    let schyFilterOptions = getSchyFilterOptions({ from: 2020, relTo: 1 });

    return (
        <div className="page-container simple-page course-page">
            <div className="page-menu">
                <div className="filter">
                    <div className="filter-group">
                        <label>{translate('General.SchoolYear')}</label>
                        <Select
                            value={schyFilter}
                            options={schyFilterOptions}
                            onChange={onSchyFilterChange}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </div>
                </div>
                <div className="buttons">
                    <div
                        className="btn btn-imsp"
                        onClick={onAdd}
                    >{translate('Btn.Add')}</div>
                    <div
                        className={classnames("btn btn-imsp", { disabled: !selected })}
                        onClick={onDelete}
                    >{translate('Appointments.Cancel')}</div>
                </div>
            </div>
            <div className="page-content">
                <table className="imsp-table">
                    <colgroup>
                        <col className="col-time" />
                        <col className="col-meeting" />
                        <col className="col-student" />
                        <col className="col-user" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{translate('Appointments.Time')}</th>
                            <th>{translate('Appointments.Meeting')}</th>
                            <th>{translate('Appointments.Student')}</th>
                            <th>{translate('Appointments.User')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appointments.map(o => {
                            return (
                                <tr
                                    key={o.id}
                                    className={classnames({ selected: selected && selected.id === o.id })}
                                    onClick={() => onRowClick(o)}
                                    onDoubleClick={() => onRowDoubleClick(o)}
                                >
                                    <td>{o.time && moment(o.time).format('D. M. YYYY  HH:mm') || ''}</td>
                                    <td>{o.classMeetingName}</td>
                                    <td>{o.studentName}</td>
                                    <td>{o.userName}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {modal.open &&
                <AppointmentModal
                    key={modal.ts}
                    id={modal.id}
                    year={schyFilter && schyFilter.value || null}
                    onSubmit={onModalSubmit}
                    onCancel={onModalCancel}
                />
            }
        </div>
    );
}

export default Appointments;