import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Route, Switch, Redirect } from 'react-router'

import Account from './account/Account';
import { isAdmin } from '../../utils/role';

import './adminContent.scss';
import Teacher from './teacher/Teacher';
import Classroom from './classroom/Classroom';
import Course from './course/Course';
import Group from './group/Group';
import Students from './students/Students';
import AdminRegister from './register/AdminRegister';
import AdminPayment from './payment/AdminPayment';
import Settings from './settings/Settings';
import AdminBilling from './billing/AdminBilling';
import ClassMeeting from './classmeeting/ClassMeeting';
import Appointments from './appointments/Appointments';


class AdminContent extends React.Component {
    static propTypes = {
        profile: PropTypes.object,
        path: PropTypes.string,
        push: PropTypes.func.isRequired,
        onTitle: PropTypes.func
    }

    componentWillMount() {
        if (!isAdmin(this.props.profile) && !this.props.profile.pending) {
            console.log('AdminContent.componentWillMount: redirect because user is not admin', this.props.profile);
            this.props.push('/');
        }
    }

    componentWillReceiveProps(nextProps) {
		if (!nextProps.profile.pending && this.props.profile.pending) {
            if (!isAdmin(nextProps.profile)) {
                console.log('AdminContent.componentWillReceiveProps: redirect because user is not admin', this.props.profile);
                this.props.push('/');
            }
        }
	}

    render() {
        let { onTitle } = this.props;

        let show = isAdmin(this.props.profile);

        return (
            <>
            { show &&
                <div className="imsp-admin-section">
                    <Switch>
                        <Route
                            path="/admin/account"
                            render={(props) => <Account {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Route
                            path="/admin/teacher"
                            render={(props) => <Teacher {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Route
                            path="/admin/classroom"
                            render={(props) => <Classroom {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Route
                            path="/admin/course"
                            render={(props) => <Course {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Route
                            path="/admin/meeting"
                            render={(props) => <ClassMeeting {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Route
                            path="/admin/group"
                            render={(props) => <Group {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Route
                            path="/admin/student"
                            render={(props) => <Students {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Route
                            path="/admin/register"
                            render={(props) => <AdminRegister {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Route
                            path="/admin/payment"
                            render={(props) => <AdminPayment {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Route
                            path="/admin/billing"
                            render={(props) => <AdminBilling {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Route
                            path="/admin/settings"
                            render={(props) => <Settings {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Route
                            path="/admin/appointment"
                            render={(props) => <Appointments {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Redirect from="/admin" to="/admin/account" />
                    </Switch>
                </div>
            }
            </>
        );
}
};

export default connect(
    state => ({
        profile: state.profile,
        path: state.router.location.pathname
    }),
    {
        push
    }
)(AdminContent);