import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { prevPeriod, nextPeriod } from '../utils/period';

import './period.scss';
import { toDate, addDay, toLunchShortDate } from '../utils/date';

class Period extends React.Component {
    static propTypes = {
        period: PropTypes.object,
        disablePrev: PropTypes.bool,
        disableNext: PropTypes.bool,
        onChange: PropTypes.func,
        className: PropTypes.string,
        language: PropTypes.string.isRequired,
        type: PropTypes.string
    }

    static defaultProps = {
        type: 'month'
    };

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    onPrevPeriod() {
        let { disablePrev, onChange, period, type } = this.props;

        if (!disablePrev && onChange) {
            onChange(prevPeriod(period, type));
        }
    }

    onNextPeriod() {
        let { disableNext, onChange, period, type } = this.props;

        if (!disableNext && onChange) {
            onChange(nextPeriod(period, type));
        }
    }

    render() {
        let { period, type } = this.props;

        let date = toDate(period);
        let text = '';
        switch (type) {
            case 'week':
                text = `${toLunchShortDate(date)} - ${toLunchShortDate(addDay(date, 6))}`;
                break;
            default:
                text = `${this.context.translate('Months.Basic.' + (date.getMonth() + 1))} ${date.getFullYear()}`;
                break;
        }

        return (
            <div className={classnames("period", this.props.className)}>
                <div
                    className={classnames("btn", { disabled: this.props.disablePrev })}
                    onClick={() => this.onPrevPeriod()}
                ><i className="fa fa-chevron-left" aria-hidden="true"></i></div>
                <div
                    className="label"
                >{text}</div>
                <div
                    className={classnames("btn", { disabled: this.props.disableNext })}
                    onClick={() => this.onNextPeriod()}
                ><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
            </div>
        );
    }
};

export default connect(
    state => ({
        language: state.localize.language
    }),
    {}
)(Period);