import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { push } from "connected-react-router";
import { Route, Switch } from 'react-router'

import userManager from "../utils/userManager";
import Header from '../components/header/Header';
import { AuthService } from '../services/AuthService';
import Profile from './user/profile/Profile';
import AdminContent from './admin/AdminContent';

import './appContent.css';
import Dash from './user/dash/Dash';
import Course from './user/course/Course';
import Billing from './user/billing/Billing';
import ViewContent from './view/ViewContent';
import LectorContent from './lector/LectorContent';
import Overview from './user/overview/Overview';
import Menu from './user/menu/Menu';
import Meeting from './user/meeting/Meeting';


class AppContent extends React.Component {
    static propTypes = {
        profile: PropTypes.object,
        location: PropTypes.object,
        language: PropTypes.string.isRequired,
        push: PropTypes.func.isRequired
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.authService = new AuthService();
        this.state = {
            user: {},
            api: {},
            title: ''
        };
        this.mounted = true;
        this.query = parse(props.location.search.substr(1));
        // console.log('AppContent', { query: this.query, location: props.location });
    }

    componentDidMount() {
        this.getUser();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getUser = () => {
        this.authService.getUser().then(user => {
            if (user) {
                if (this.query.logged) {
                    this.props.push(this.props.location.pathname);
                }
            } else {
                if (this.query.logged) {
                    userManager.signinRedirect({
                        ui_locales: this.props.language
                    });
                } else {
                    console.log('You are not logged in.');
                }
            }

            if (this.mounted) {
                this.setState({ user });
            }
        });
    };

    login() {
        userManager.signinRedirect({
            ui_locales: this.props.language
        });
    };

    logout() {
        userManager.signoutRedirect();
    };

    render() {
        let { title } = this.state;

        let print = this.query.print === 'true';
        let showHeader = !print;
        // console.log('AppContent.render', { profile, user });

        return (
            <div className={classnames('app-content-container', { print })}>
                {showHeader &&
                    <Header
                        user={this.state.user}
                        login={() => this.login()}
                        logout={() => this.logout()}
                        title={(title && title.startsWith('@')) ? title.substring(1) : this.context.translate(title)}
                    />
                }
                <div className={classnames("app-page-container", { print })}>
                    <Switch>
                        <Route
                            path="/admin"
                            render={(props) => <AdminContent {...props} onTitle={title => this.setState({ title })} />}
                        />
                        <Route
                            path="/view"
                            render={(props) => <ViewContent {...props} onTitle={title => this.setState({ title })} />}
                        />
                        <Route
                            path="/lector"
                            render={(props) => <LectorContent {...props} onTitle={title => this.setState({ title })} />}
                        />
                        <Route
                            exact path="/overview"
                            render={(props) => <Overview {...props} onTitle={title => this.setState({ title })} />}
                        />
                        <Route
                            exact path="/profile"
                            render={(props) => <Profile {...props} onTitle={title => this.setState({ title })} />}
                        />
                        <Route
                            exact path="/menu"
                            render={(props) => <Menu {...props} onTitle={title => this.setState({ title })} />}
                        />
                        <Route
                            exact path="/course/:id"
                            render={(props) => <Course {...props} onTitle={title => this.setState({ title })} />}
                        />
                        <Route
                            exact path="/meeting/:id"
                            render={(props) => <Meeting {...props} onTitle={title => this.setState({ title })} />}
                        />
                        <Route
                            exact path="/billing"
                            render={(props) => <Billing {...props} onTitle={title => this.setState({ title })} />}
                        />
                        <Route
                            render={(props) => <Dash {...props} onTitle={title => this.setState({ title })} />}
                        />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        profile: state.profile,
        location: state.router.location,
        language: state.localize.language
    }), {
    push
})(AppContent);