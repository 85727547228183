import * as React from 'react';
// import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { ApiService } from '../../../services/ApiService';

import './dash.scss';
import LectorAttendance from '../../lector/attendance/LectorAttendance';
import Menu from '../menu/Menu';
import Overview from '../overview/Overview';


class Dash extends React.Component {
    static propTypes = {
        push: PropTypes.func.isRequired,
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            info: {},
            infoLoaded: false
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadRegInfo();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadRegInfo() {
        this.api.getRegInfo()
            .then(response => {
                if (this.mounted) {
                    let info = response.data || {};
                    this.setState({ info, infoLoaded: true });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    this.setState({ infoLoaded: true });
                }
            });
    }

    render() {
        let { info, infoLoaded } = this.state;
        let { onTitle } = this.props;

        if (infoLoaded && info) {
            if (info.hasRegistrations) {
                return (<Overview onTitle={title => { if (onTitle) onTitle(title); }} />);
            } else if (info.hasCourse) {
                return (<LectorAttendance onTitle={title => { if (onTitle) onTitle(title); }} />);
            } else {
                return (<Menu onTitle={title => { if (onTitle) onTitle(title); }} />);
            }
        } else {
            return (<></>);
        }
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {
        push
    }
)(Dash);