import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Route, Switch, Redirect } from 'react-router'

import LectorAttendance from './attendance/LectorAttendance';
import LectorEmail from './email/LectorEmail';
import { isTeacher } from '../../utils/role';


class LectorContent extends React.Component {
    static propTypes = {
        profile: PropTypes.object,
        path: PropTypes.string,
        push: PropTypes.func.isRequired,
        onTitle: PropTypes.func
    }

    componentWillMount() {
        if (!isTeacher(this.props.profile) && !this.props.profile.pending) {
            console.log('LectorContent.componentWillMount: redirect because user is not lector', this.props.profile);
            this.props.push('/');
        }
    }

    componentWillReceiveProps(nextProps) {
		if (!nextProps.profile.pending && this.props.profile.pending) {
            if (!isTeacher(nextProps.profile)) {
                console.log('LectorContent.componentWillReceiveProps: redirect because user is not lector', this.props.profile);
                this.props.push('/');
            }
        }
	}

    render() {
        let show = isTeacher(this.props.profile);
        let { onTitle } = this.props;

        return (
            <>
            { show &&
                <div className="imsp-lector-section">
                    <Switch>
                        <Route
                            path="/lector/attendance"
                            render={(props) => <LectorAttendance {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Route
                            path="/lector/email"
                            render={(props) => <LectorEmail {...props} onTitle={title => { if (onTitle) onTitle(title); }} />}
                        />
                        <Redirect from="/lector" to="/lector/attendance" />
                    </Switch>
                </div>
            }
            </>
        );
}
};

export default connect(
    state => ({
        profile: state.profile,
        path: state.router.location.pathname
    }),
    {
        push
    }
)(LectorContent);