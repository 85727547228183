import _ from 'lodash';
import * as toastr from 'toastr';


export const ERR_SYSTEM = 'err_system';
export const ERR_INVALID_COURSE = "err_invalid_course";
export const ERR_INVALID_STUDENT = "err_invalid_student";
export const ERR_DUPLICATE_RECORD = "err_duplicate_record";
export const ERR_COURSE_CAPACITY_EXCEEDED = "err_course_capacity_exceeded";
export const ERR_INVALID_USER_ID = "err_invalid_user_id";
export const ERR_USER_IS_TEACHER = 'err_user_is_teacher';
export const ERR_INVALID_TEACHER_ID = "err_invalid_teacher_id";
export const ERR_INVALID_LESSON = "err_invalid_lesson";
export const ERR_DEPENDENT_DATA = "err_dependent_data";
export const ERR_PERMISSION_DENIED = "err_permission_denied";
export const ERR_INVALID_REGISTRATION = "err_invalid_registration";
export const ERR_INVALID_ATTENDANCE = "err_invalid_attendance";
export const ERR_INVALID_DINER = "err_invalid_diner";
export const ERR_DINER_HAS_STUDENT = "err_diner_has_student";
export const ERR_STUDENT_HAS_DINER = "err_student_has_diner";
export const ERR_INVALID_GROUP_ID = "err_invalid_group_id";
export const ERR_MISSING_DATE = "err_missing_date";
export const ERR_MISSING_STUDENT_ID = "err_missing_student_id";
export const ERR_INVALID_DATE = "err_invalid_date";
export const ERR_INVALID_TRANSACTION_ID = "err_invalid_transaction_id";
export const ERR_INVALID_STUDENT_ID = "err_invalid_student_id";
export const ERR_COURSE_NOT_OPEN = "err_course_not_open";
export const ERR_INVALID_COURSE_CAPACITY = "err_invalid_course_capacity";
export const ERR_COURSE_DISABLED = "err_course_disabled";
export const ERR_INVALID_LESSON_TIME = "err_invalid_lesson_time";
export const ERR_ID_NOT_FOUND = "err_id_not_found";
export const ERR_INVALID_APPOINTMENT = "err_invalid_appointment";
export const ERR_INVALID_NOTIFICATION = "err_invalid_notification";
export const ERR_MEETING_DISABLED = "err_meeting_disabled";
export const ERR_APPOINTMENT_PASSED = "err_appointment_passed";
export const ERR_MEETING_REG_NOT_OPEN = "err_meeting_reg_not_open";
export const ERR_APPOINTMENT_ENGAGED = "err_appointment_engaged";
export const ERR_STUDENT_REGISTRED = "err_student_registred";


export function handleError(error, source, quiet = false) {
    if (!!source) {
        console.log(source, error);
    } else {
        console.log(error);
    }
    if (!quiet) {
        toastr.error(error);
    }
}

export function isImspError(error) {
    return error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.length;
}

export function errorEquals(error, code) {
    return isImspError(error) && _.some(error.response.data.errors, { code });
}

export function getImspErrorCodes(error) {
    return error.response.data.errors.map(o => o.code);
}