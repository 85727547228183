import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from "react-redux";

import Modal from '../../../components/Modal';
import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import { TT_PAYMENT, TT_REFUND, TT_DEPOSIT, TT_STORNO } from '../../../constants/transactionType';
import { toStandardDmySpan, today, toLunchShortDate, toDate, toModalDate } from '../../../utils/date';
import ImportResult from '../../../components/importResult/ImportResult';

import './adminPayment.scss';
import { initPeriod } from '../../../utils/period';
import schoolYear from '../../../utils/schoolYear';


const defaultModal = {
    open: false,
    id: '',
    date: '',
    type: TT_PAYMENT,
    student: '',
    course: '',
    amount: '',
    note: ''
};

class AdminPayment extends React.Component {
    static propTypes = {
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired,
        onTitle: PropTypes.func
    };

    constructor(props, context) {
        super(props);

        this.state = {
            transactions: {},
            selected: '',
            typeFilter: [],
            studentFilter: [],
            students: [],
            courses: [],
            registrations: [],
            courseFilter: [],
            modal: defaultModal,
            importResult: {
                show: false,
                data: {}
            },
            schyFilter: { value: schoolYear().year(), label: schoolYear().yearText() }
        };
        this.mounted = false;
        this.api = new ApiService();
        this.typeOptions = [
            { label: context.translate(`TransactionType.${TT_PAYMENT}`), value: TT_PAYMENT },
            { label: context.translate(`TransactionType.${TT_DEPOSIT}`), value: TT_DEPOSIT },
            { label: context.translate(`TransactionType.${TT_STORNO}`), value: TT_STORNO },
            { label: context.translate(`TransactionType.${TT_REFUND}`), value: TT_REFUND }
        ];
    }

    componentWillMount() {
        this.loadTransactions();
        this.loadStudents();
        this.loadCourses();
        this.loadCourseRegistrations();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Header.Payments');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadTransactions() {
        let { schyFilter, selected } = this.state;

        let params = {
            year: schyFilter.value
        }

        this.api.getTransactions(params)
            .then(response => {
                if (this.mounted) {
                    let transactions = _.mapKeys(response.data, 'id');
                    if (selected && !transactions[selected]) {
                        selected = '';
                    }
                    this.setState({ transactions, selected });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadCourses() {
        this.api.getCourses()
            .then(response => {
                let courses = response.data || [];
                if (this.mounted) {
                    this.setState({ courses });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadCourseRegistrations() {
        this.api.getRegistrations()
            .then(response => {
                let registrations = response.data || [];
                if (this.mounted) {
                    this.setState({ registrations });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadStudents() {
        this.api.getStudents()
            .then(response => {
                let students = response.data || [];
                if (this.mounted) {
                    this.setState({ students });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    getTransactionList(transactions, typeFilter, studentFilter, courseFilter) {
        let result = _.map(transactions);
        if (typeFilter && typeFilter.length) {
            result = result.filter(o => _.some(typeFilter, { value: o.type }));
        }
        if (studentFilter && studentFilter.length) {
            result = result.filter(o => _.some(studentFilter, { value: o.studentId }));
        }
        if (courseFilter && courseFilter.length) {
            result = result.filter(o => _.some(courseFilter, { value: o.courseId }));
        }
        return result;
    }

    getTypeFilterOptions() {
        let result = [
            { value: TT_PAYMENT, label: this.context.translate(`TransactionType.${TT_PAYMENT}`) },
            { value: TT_DEPOSIT, label: this.context.translate(`TransactionType.${TT_DEPOSIT}`) },
            { value: TT_STORNO, label: this.context.translate(`TransactionType.${TT_STORNO}`) },
            { value: TT_REFUND, label: this.context.translate(`TransactionType.${TT_REFUND}`) }
        ];
        return result;
    }

    getStudentFilterOptions(students) {
        let result = (students || []).map(o => ({ value: o.id, label: o.name }));
        arraySort(result, 'label');
        return result;
    }

    getStudentOptions(students) {
        let all = { id: '', name: '' };
        let result = [all, ...arraySort(students, 'name')];
        return result;
    }

    getCourseFilterOptions(courses) {
        let result = (courses || []).map(o => ({ value: o.id, label: o.name }));
        arraySort(result, 'label');
        return result;
    }

    getRegistrationOptions(registrations, studentId) {
        let result = [{ id: '', name: '' }];
        if (studentId) {
            arraySort(registrations.filter(o => o.studentId === studentId), 'courseName', 'from', 'to').forEach(o => {
                let to = o.to ? ` - ${toLunchShortDate(o.to)}` : '';
                let name = `${o.courseName}  (${toLunchShortDate(o.from)}${to})`;
                result.push({ id: o.id, name });
            });
        }
        // console.log('AdminPayments.getRegistrationOptions', { registrations, studentId, result });
        return result;
    }

    select(key) {
        this.setState({ selected: key === this.state.selected ? '' : key });
    }

    add() {
        this.setState({
            modal: {
                ...defaultModal,
                open: true,
                date: today(),
                id: 0
            }
        });
    }

    update(id) {
        let { selected, transactions } = this.state;

        id = id || selected;
        if (!id) {
            return;
        }

        let item = transactions[id] || {};

        this.setState({
            modal: {
                ...defaultModal,
                open: true,
                id,
                date: toDate(item.date) || defaultModal.date,
                type: item.type || defaultModal.type,
                student: item.studentId || defaultModal.student,
                course: item.registrationId || defaultModal.course,
                amount: item.amount || defaultModal.amout,
                note: item.note || defaultModal.note
            }
        });
    }

    delete(id) {
        id = id || this.state.selected;
        if (!id) {
            return;
        }

        if (window.confirm(this.context.translate('Payments.DeleteConfirm'))) {
            let _self = this;
            this.api.deleteTransaction(id)
                .then(response => {
                    if (_self.mounted) {
                        toastr.success(this.context.translate('Payments.DeleteSuccess'));
                        let transactions = { ..._self.state.transactions };
                        delete transactions[id];
                        _self.setState({ transactions });
                        _self.loadTransactions();
                    }
                })
                .catch(error => {
                    if (this.mounted) {
                        toastr.error(this.context.translate('Err.System'));
                    }
                });
        }
    }

    closeModal() {
        this.setState({ modal: defaultModal });
    }

    submitModal() {
        if (this.isValid()) {
            let { id, date, type, student, course, amount, note } = this.state.modal;

            if (id) {
                let data = { type, amount, studentId: student, courseRegistrationId: course, date, note };
                this.api.updateTransaction(id, data)
                    .then(response => {
                        if (this.mounted) {
                            this.loadTransactions();
                            this.closeModal();
                            toastr.success(this.context.translate('Payment.SaveSuccess'));
                        }
                    })
                    .catch(error => {
                        if (this.mounted) {
                            toastr.error(this.context.translate('Err.System'));
                        }
                    });
            } else {
                this.api.addTransaction(date, type, amount, note, student, course)
                    .then(response => {
                        if (this.mounted) {
                            this.loadTransactions();
                            this.closeModal();
                            toastr.success(this.context.translate('Payment.SaveSuccess'));
                        }
                    })
                    .catch(error => {
                        if (this.mounted) {
                            toastr.error(this.context.translate('Err.System'));
                        }
                    });
            }
        }
    }

    isValid() {
        return true;
    }

    import(file) {
        console.log('import');
        if (!file) {
            return;
        }

        let data = new FormData();
        data.append('files', file);

        let _self = this;
        this.api.importTransactions(data)
            .then(response => {
                if (_self.mounted) {
                    _self.setState({ importResult: { show: true, data: response.data } });
                    _self.loadTransactions();
                    _self.fileInput.value = "";
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    getSchyFilterOptions() {
        let curYear = schoolYear().year();
        let result = [];
        for (let i = 2020; i <= curYear + 1; i++) {
            result.push({ value: i, label: schoolYear(i).yearText() })
        }
        return result;
    }

    render() {
        let { translate } = this.context;
        let { selected, transactions, typeFilter, students, studentFilter, courses, courseFilter,
            modal, registrations, importResult, schyFilter } = this.state;

        let transactionList = this.getTransactionList(transactions, typeFilter, studentFilter, courseFilter);
        let typeFilterOptions = this.getTypeFilterOptions();
        let studentOptions = this.getStudentOptions(students);
        let studentFilterOptions = this.getStudentFilterOptions(students);
        let registrationOptions = this.getRegistrationOptions(registrations, modal.student);
        let courseFilterOptions = this.getCourseFilterOptions(courses);
        let schyFilterOptions = this.getSchyFilterOptions();

        let deleteBtnDisabled = !selected;
        let updateBtnDisabled = !selected;

        return (
            <div className="page-container simple-page admin-payment-container">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('General.SchoolYear')}</label>
                            <Select
                                value={schyFilter}
                                options={schyFilterOptions}
                                onChange={schyFilter => this.setState({ schyFilter }, () => {
                                    this.loadTransactions();
                                })}
                                menuPortalTarget={document.querySelector('body')}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Type')}</label>
                            <Select
                                options={typeFilterOptions}
                                isMulti
                                onChange={typeFilter => this.setState({ typeFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Student')}</label>
                            <Select
                                options={studentFilterOptions}
                                isMulti
                                onChange={studentFilter => this.setState({ studentFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Course')}</label>
                            <Select
                                options={courseFilterOptions}
                                isMulti
                                onChange={courseFilter => this.setState({ courseFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.add()}
                        >{this.context.translate('Btn.Add')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: updateBtnDisabled })}
                            onClick={() => this.update()}
                        >{this.context.translate('Btn.Update')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: deleteBtnDisabled })}
                            onClick={() => this.delete()}
                        >{this.context.translate('Btn.Delete')}</div>
                        <div
                            className="btn btn-imsp import-btn"
                        >
                            {this.context.translate('Payment.Import')}
                            <input
                                type={'file'}
                                onChange={e => this.import(e.target.files[0])}
                                ref={ref => this.fileInput = ref}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                        </div>
                        <div className="row-cont-right">
                            <a
                                className="link-button"
                                href="/doc/import-payments.xlsx"
                                download
                                target="_blank"
                            >{this.context.translate('Payment.ImportExample')}</a>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-date" />
                            <col className="col-type" />
                            <col className="col-student" />
                            <col className="col-course" />
                            <col className="col-note" />
                            <col className="col-amount" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{this.context.translate('Payments.Date')}</th>
                                <th>{this.context.translate('Payments.Type')}</th>
                                <th>{this.context.translate('Payments.Student')}</th>
                                <th>{this.context.translate('Payments.Course')}</th>
                                <th>{this.context.translate('Payments.Note')}</th>
                                <th>{this.context.translate('Payments.Amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionList.map(o => {
                                let coef = o.type === TT_PAYMENT || o.type === TT_REFUND ? -1 : 1;
                                return (
                                    <tr
                                        key={o.id}
                                        className={classnames({ selected: selected === o.id, deposit: o.type === TT_DEPOSIT, payment: o.type === TT_PAYMENT, storno: o.type === TT_STORNO, refund: o.type === TT_REFUND })}
                                        onClick={() => this.select(o.id)}
                                    >
                                        <td className="ta-c">{toStandardDmySpan(o.date)}</td>
                                        <td>{this.context.translate(`TransactionType.${o.type}`)}</td>
                                        <td>{o.studentName}</td>
                                        <td>{o.courseName}</td>
                                        <td>{o.note}</td>
                                        <td className="ta-r">{o.amount * coef} {this.context.translate('Units.Money')}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {importResult.show &&
                        <ImportResult
                            data={importResult.data}
                            onClose={() => this.setState({ importResult: { ...importResult, show: false } })}
                        />
                    }
                </div>

                <Modal
                    key={modal.id}
                    modalClass="imsp-modal"
                    onClose={() => this.closeModal()}
                    onSubmit={() => this.submitModal()}
                    title={this.context.translate(`Payment.${modal.id ? 'EditTitle' : 'AddTitle'}`)}
                    show={modal.open}
                    closeOnClick={false}
                >
                    <div>
                        <fieldset>
                            <div className="form-group">
                                <label htmlFor="date">{this.context.translate('Payment.Time')}</label>
                                <DatePicker
                                    className="form-control"
                                    dateFormat={["d. M. yyyy", "d.M.yyyy", "d. M.yyyy", "d.M. yyyy"]}
                                    selected={modal.date}
                                    onChangeRaw={e => e.preventDefault()}
                                    onChange={date => this.setState({ modal: { ...modal, date: date } })}
                                    placeholderText="d. M. yyyy"
                                    strictParsing={true}
                                />
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Payment.Type')}</label>
                                <select
                                    className="form-control"
                                    value={modal.type}
                                    onChange={e => this.setState({ modal: { ...modal, type: e.target.value } })}
                                >
                                    {this.typeOptions.map(o => (
                                        <option key={o.value || 0} value={o.value}>{o.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Payment.Amount')}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={modal.amount}
                                    onChange={e => this.setState({ modal: { ...modal, amount: e.target.value } })}
                                    autoFocus
                                />
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Payment.Note')}</label>
                                <input
                                    className="form-control"
                                    name="description"
                                    type="text"
                                    value={modal.note}
                                    onChange={e => this.setState({ modal: { ...modal, note: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Payment.Student')}</label>
                                <select
                                    className="form-control"
                                    value={modal.student}
                                    onChange={e => this.setState({ modal: { ...modal, student: e.target.value } })}
                                // disabled={!!modal.id}
                                >
                                    {studentOptions.map(o => (
                                        <option key={o.id || 0} value={o.id}>{o.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Payment.Course')}</label>
                                <select
                                    className="form-control"
                                    value={modal.course}
                                    onChange={e => this.setState({ modal: { ...modal, course: e.target.value } })}
                                // disabled={!!modal.id}
                                >
                                    {registrationOptions.map(o => (
                                        <option key={o.id || 0} value={o.id}>{o.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group buttons">
                                <button
                                    className="btn btn-imsp"
                                    onClick={() => this.submitModal()}
                                >{this.context.translate('Btn.Save')}</button>
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeModal()}
                                >{this.context.translate('Btn.Cancel')}</button>
                            </div>
                        </fieldset>
                    </div>
                </Modal>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(AdminPayment);