import * as toastr from 'toastr';
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ApiService } from '../../../services/ApiService';

import './lectorEmail.scss';


class LectorEmail extends React.Component {
    static propTypes = {
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            courses: [],
            selectedCourse: '',
            subject: '',
            message: ''
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadCourses();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('LectorEmail.Title');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadCourses() {
        this.api.getCoursePreview({ teachersOnly: true })
            .then(response => {
                if (this.mounted) {
                    let courses = response.data || [];
                    let selectedCourse = courses.length ? courses[0].id : '';
                    this.setState({ courses, selectedCourse });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    send() {
        let { selectedCourse, subject, message } = this.state;

        if (this.validate(selectedCourse)) {
            this.api.sendLectorEmail(selectedCourse, subject, message)
                .then(response => {
                    if (this.mounted) {
                        toastr.success(this.context.translate('Emails.SendSuccess'));
                        this.clear();
                    }
                })
                .catch(error => {
                    if (this.mounted) {
                        toastr.error(this.context.translate('Err.System'));
                    }
                });
        }
    }

    validate(selectedCourse) {
        if (!selectedCourse) {
            return false;
        }

        return true;
    }

    clear() {
        this.setState({ subject: '', message: '' });
    }

    render() {
        let { courses, selectedCourse, subject, message } = this.state;

        return (
            <div className="page-container emails">
                <div className="email-definition">
                    <div className="form-group to-part">
                        <div className="label">{this.context.translate('Emails.To')}</div>
                        <select
                            className="form-control"
                            value={selectedCourse}
                            onChange={e => this.setState({ selectedCourse: e.target.value })}
                        >
                            {courses.map(o => (
                                <option key={o.id} value={o.id}>{o.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group subject-part">
                        <div className="label">{this.context.translate('Emails.Subject')}</div>
                        <input
                            className="form-control value"
                            type="text"
                            value={subject}
                            onChange={e => this.setState({ subject: e.target.value })}
                        />
                    </div>
                    <div className="form-group message-part">
                        <div className="label">{this.context.translate('Emails.Message')}</div>
                        <textarea
                            className="form-control"
                            cols="40"
                            rows="5"
                            value={message}
                            onChange={e => this.setState({ message: e.target.value })}
                        ></textarea>
                    </div>
                    <div className="button-part">
                        <div
                            className="btn btn-light"
                            onClick={() => this.clear()}
                        >{this.context.translate('Btn.Cancel')}</div>
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.send()}
                        >{this.context.translate('Btn.Send')}</div>
                    </div>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(LectorEmail);