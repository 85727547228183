import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { AppContext } from '../containers/AppContext';
import { SmallBtn } from './smallbtn/SmallBtn';

import './notificationsControl.scss';
import moment from 'moment';


const NotificationItem = ({ value, text, onChange, typeTranslation }) => {

    let { translate } = useContext(AppContext);

    let [interval, setInterval] = useState(value.interval);
    let [type, setType] = useState({ value: value.type, label: translate(`${typeTranslation}.${value.type}`) });

    let typeOptions = [
        { value: "R", label: translate(`${typeTranslation}.R`) },
        // { value: "L", label: translate(`${typeTranslation}.L`) }
    ];

    useEffect(() => {
        if (interval !== value.interval || type.value != value.type) {
            let changedValue = { ...value, interval: parseInt(interval), type: type.value }
            if (onChange) onChange(changedValue);
        }
    }, [interval, type]);

    return (
        <div className="course-notification-item">
            <input
                className="form-control course-notification-interval"
                type="number"
                value={interval}
                onChange={e => setInterval(e.target.value)}
            />
            <div className="course-notification-text">{text}</div>
            <Select
                value={type}
                options={typeOptions}
                onChange={o => setType(o)}
                className="course-notification-type"
            />
            <SmallBtn
                className="course-notification-rem"
                title={translate('Btn.Add')}
                ico="fa fa-minus"
                onClick={() => {
                    let changedValue = { ...value, remove: true }
                    if (onChange) onChange(changedValue);
                }}
            />
        </div>
    );
}

const NotificationsControl = ({ value, onChange, typeTranslation}) => {

    let { translate } = useContext(AppContext);

    let [notifications, setNotifications] = useState(value || []);

    useEffect(() => {
        setNotifications(value);
    }, [value]);

    const add = () => {
        let data = [...notifications, { interval: 14, type: "R", ts: new Date().getTime() }];
        // setNotifications(data);
        if (onChange) onChange(data);
    }

    const onItemChange = (newItem, i) => {
        let data = [...notifications];
        if (newItem.remove) {
            if (newItem.id) {
                data.splice(i, 1, newItem);
            } else {
                data.splice(i, 1);
            }
        } else {
            data.splice(i, 1, newItem);
        }
        // setNotifications(data);
        if (onChange) onChange(data);
    }

    typeTranslation = typeTranslation || 'CourseNotificationType';

    return (
        <div className="form-group">
            <div className="label-btn">
                <label>{translate('Course.Notifications')}</label>
                <SmallBtn
                    title={translate('Btn.Add')}
                    ico="fa fa-plus"
                    onClick={add}
                />
            </div>
            <div>
                {notifications.map((item, i) => {
                    if (!item.remove) {
                        if (item.sentAt) {
                            let text = translate("CourseNotification.Text")
                                .replace('{interval}', item.interval)
                                .replace('{type}', translate(`${typeTranslation}.${item.type}`));
                            return (
                                <div
                                    key={item.id || item.ts}
                                    className="course-notification-item"
                                >
                                    <div className="course-notification-text">{text}</div>
                                    <div className="course-notification-rem">{`${translate('CourseNotification.Sent')} ${moment(item.sentAt).format('DD.MM.YYYY HH:mm')}`}</div>
                                </div>
                            );
                        } else {
                            let text = translate("CourseNotification.Text")
                                .replace('{interval}', '')
                                .replace('{type}', '');
                            return (
                                <NotificationItem
                                    key={item.id || item.ts}
                                    value={item}
                                    text={text}
                                    onChange={newItem => onItemChange(newItem, i)}
                                    typeTranslation={typeTranslation}
                                />
                            );
                        }
                    }
                })}
            </div>
        </div>
    );
}

export default NotificationsControl;