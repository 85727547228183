import { addDay } from "./date";

export function initPeriod(type = 'month') {
    return toPeriod(new Date(), type);
}

export function prevPeriod(value, type = 'month') {
    switch (type) {
        case 'week':
            return addDay(value, -7);
        default:
            return new Date(value.getFullYear(), value.getMonth() - 1, 1);
    }
}

export function nextPeriod(value, type = 'month') {
    switch (type) {
        case 'week':
            return addDay(value, 7);
        default:
            return new Date(value.getFullYear(), value.getMonth() + 1, 1);
    }
}

export function toPeriod(date, type = 'month') {
    switch (type) {
        case 'week':
            let day = date.getDay();
            if (day !== 1) {
                date = day > 1 ? addDay(date, -day + 1) : addDay(date, -6);
            }
            return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        default:
            return new Date(date.getFullYear(), date.getMonth(), 1);
    }
}