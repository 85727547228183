export const lsSet = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const ssSet = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
}

export const lsGetBool = (key, def = 'false') => {
    return JSON.parse(localStorage.getItem(key) ?? def);
}

export const ssGetBool = (key, def = 'false') => {
    return JSON.parse(sessionStorage.getItem(key) ?? def);
}
