import { arraySort } from "./sort";

export const getCourseGroupList = (course) => {
    // console.log('getCourseGroupList', { course });
    return arraySort(((course || {}).groups || []).filter(o => o.name), 'monthOrdersOrder').map(o => o.name).join(', ');
}

export const getNameWithGroup = (course) => {
    let result = (course || {}).name;

    let groups = getCourseGroupList(course);
    if (groups) {
        result = `${result} (${groups})`;
    }

    return result;
}