import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from "react-redux";

import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import Modal from '../../../components/Modal';
import { toModalDate, toStandardDmySpan } from '../../../utils/date';
import { ERR_DEPENDENT_DATA, errorEquals, ERR_COURSE_CAPACITY_EXCEEDED, ERR_DUPLICATE_RECORD, ERR_COURSE_DISABLED } from '../../../utils/error';
import schoolYear from '../../../utils/schoolYear';


class AdminRegister extends React.Component {
    static propTypes = {
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props, context) {
        super(props);

        this.state = {
            registrations: {},
            modal: {
                open: false,
                ts: '',
                id: '',
                mode: '',
                course: null,
                student: null,
                date: ''
            },
            selected: '',
            courseFilter: [],
            courses: [],
            studentFilter: [],
            students: [],
            closedFilter: null,
            alternateFilter: null,
            groupFilter: [],
            groups: [],
            schyFilter: { value: schoolYear().year(), label: schoolYear().yearText() }
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadRegistraions();
        this.loadCourses();
        this.loadStudents();
        this.loadGroups();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Header.Registrations');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadRegistraions() {
        let { schyFilter, selected } = this.state;

        let params = {};
        if (schyFilter && schyFilter.value)
            params.year = schyFilter.value;

        this.api.getRegistrations(params)
            .then(response => {
                if (this.mounted) {
                    let registrations = _.mapKeys(response.data, 'id');
                    if (selected && !registrations[selected]) {
                        selected = '';
                    }
                    this.setState({ registrations, selected });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadCourses() {
        let { schyFilter, courseFilter } = this.state;

        let params = {};
        if (schyFilter && schyFilter.value)
            params.year = schyFilter.value;

        this.api.getCoursesSimple(params)
            .then(response => {
                if (this.mounted) {
                    let courses = (response.data || []).map(o => ({ value: o.id, label: o.name }));
                    arraySort(courses, 'label');
                    courseFilter = courseFilter.filter(o => _.some(courses, { value: o.value }));
                    this.setState({ courses, courseFilter });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadStudents() {
        this.api.getStudents()
            .then(response => {
                if (this.mounted) {
                    let students = (response.data || []).map(o => ({ value: o.id, label: o.name }));
                    arraySort(students, 'label');
                    this.setState({ students });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadGroups() {
        this.api.getGroups()
            .then(response => {
                if (this.mounted) {
                    let groups = response.data || [];
                    this.setState({ groups });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    getRegistrationList(registrations, courseFilter, studentFilter, closedFilter, alternateFilter, groupFilter) {
        let result = _.map(registrations);
        if (courseFilter && courseFilter.length) {
            result = result.filter(o => _.some(courseFilter, { value: o.courseId }));
        }
        if (studentFilter && studentFilter.length) {
            result = result.filter(o => _.some(studentFilter, { value: o.studentId }));
        }
        if (groupFilter && groupFilter.length) {
            result = result.filter(o => _.some(groupFilter, { value: (o.studentGroup || {}).id }));
        }
        if (closedFilter) {
            result = result.filter(o => !!o.to === closedFilter.value);
        }
        if (alternateFilter) {
            result = result.filter(o => o.isAlternate === alternateFilter.value);
        }
        arraySort(result, 'courseName', 'registeredAt');

        // console.log('result', result);
        return result;
    }

    select(key) {
        this.setState({ selected: key === this.state.selected ? '' : key });
    }

    delete(id = '', force = false) {
        let { selected } = this.state;
        id = id || selected;

        if (!id || (!force && !window.confirm(this.context.translate('Register.ConfirmDelete')))) {
            return;
        }

        this.api.deleteRegistration(id, force)
            .then(response => {
                if (this.mounted) {
                    this.loadRegistraions();
                    this.setState({ selected: '' });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    if (errorEquals(error, ERR_DEPENDENT_DATA)) {
                        if (window.confirm(this.context.translate('Register.ConfirmForceDelete'))) {
                            this.delete(id, true);
                        }
                    } else {
                        toastr.error(this.context.translate('Err.System'));
                    }
                }
            });
    }

    finish() {
        let { selected, registrations } = this.state;

        if (!selected || !registrations || !registrations[selected]) {
            return;
        }

        this.setState({
            modal: {
                ts: new Date().getTime(),
                open: true,
                mode: 'close',
                id: selected,
                course: registrations[selected].courseId && { value: registrations[selected].courseId, label: registrations[selected].courseName } || null,
                student: registrations[selected].studentId && { value: registrations[selected].studentId, label: registrations[selected].studentName } || null,
                date: moment().startOf('day').add(1, 'days').toDate()
            }
        });

        // if (!selected || !window.confirm(this.context.translate('Register.ConfirmClose'))) {
        //     return;
        // }

        // this.api.closeRegistration(selected)
        //     .then(response => {
        //         if (this.mounted) {
        //             this.loadRegistraions();
        //         }
        //     })
        //     .catch(error => {
        //         if (this.mounted) {
        //             toastr.error(this.context.translate('Err.System'));
        //         }
        //     });
    }

    updAttend(id) {
        let { selected } = this.state;
        id = id || selected;

        if (!id) {
            console.log('No id to update attendance.');
            return;
        }

        this.api.updateAttendance(selected)
            .then(response => {
                if (this.mounted) {
                    toastr.success(this.context.translate('Register.UpdAttSuccess'));
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    getFinishEnabled(selected, registrations) {
        if (!selected || !registrations) {
            return false;
        }

        let registration = _.find(registrations, { id: selected });
        if (!registration || !!registration.to) {
            return false;
        }

        return true;
    }

    getYesNoFilterOptions() {
        let result = [
            { value: false, label: this.getYesNo(false) },
            { value: true, label: this.getYesNo(true) }
        ];
        return result;
    }

    add() {
        let { courses, students } = this.state;

        this.setState({
            modal: {
                ts: new Date().getTime(),
                open: true,
                mode: 'add',
                course: courses && courses.length ? courses[0] : null,
                student: students && students.length ? students[0] : null
            }
        });
    }

    closeModal() {
        this.setState({ modal: { open: false, ts: '', id: '', mode: '', course: null, student: null, date: '' } });
    }

    submitModal() {
        const { translate } = this.context;
        let { course, student, mode, id, date } = this.state.modal;

        switch (mode) {
            case 'add':
                if (!course || !course.value || !student || !student.value) {
                    toastr.warning(translate('Err.InvalidInput'));
                    return;
                }

                this.api.addRegistration(course.value, student.value)
                    .then(response => {
                        toastr.success(translate('Register.RegisterSuccess'));
                        this.loadRegistraions();
                        this.closeModal();
                    })
                    .catch(error => {
                        if (this.mounted) {
                            if (errorEquals(error, ERR_DUPLICATE_RECORD)) {
                                toastr.warning(translate('Register.DuplicateRecord'));
                            } else if (errorEquals(error, ERR_COURSE_CAPACITY_EXCEEDED)) {
                                toastr.warning(translate('Course.CapacityReached'));
                            } else if (errorEquals(error, ERR_COURSE_DISABLED)) {
                                toastr.warning(translate('Course.CourseDisabled'));
                            } else {
                                toastr.error(translate('Err.System'));
                            }
                        }
                    });
                break;
            case 'close':
                this.api.closeRegistration(id, { date: toModalDate(date) })
                    .then(response => {
                        toastr.success(translate('Register.CloseSuccess'));
                        this.loadRegistraions();
                        this.closeModal();
                    })
                    .catch(error => {
                        if (this.mounted) {
                            toastr.error(this.context.translate('Err.System'));
                        }
                    });
                break;
        }
    }

    getGroupFilterOptions(groups) {
        let result = (groups || []).map(o => ({ value: o.id, label: o.name }));
        arraySort(result, 'label');
        return result;
    }

    getYesNo(value) {
        return this.context.translate(`Codelist.${(value ? 'Yes' : 'No')}`);
    }

    getSchyFilterOptions() {
        let curYear = schoolYear().year();
        let result = [];
        for (let i = curYear - 1; i <= curYear + 1; i++) {
            result.push({ value: i, label: schoolYear(i).yearText() })
        }
        return result;
    }

    render() {
        let { translate } = this.context;
        let { registrations, selected, modal, courseFilter, courses, studentFilter, students,
            closedFilter, alternateFilter, groupFilter, groups, schyFilter } = this.state;

        let registrationList = this.getRegistrationList(registrations, courseFilter, studentFilter, closedFilter, alternateFilter, groupFilter);
        let isSelected = !!selected;
        let finishEnabled = this.getFinishEnabled(selected, registrations);
        let yesNoFilterOptions = this.getYesNoFilterOptions();
        let groupFilterOptions = this.getGroupFilterOptions(groups);
        let schyFilterOptions = this.getSchyFilterOptions();

        console.log('courseFilter', courseFilter);

        return (
            <div className="page-container simple-page">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{translate('Filter.Course')}</label>
                            <Select
                                value={courseFilter}
                                options={courses}
                                isMulti
                                onChange={courseFilter => this.setState({ courseFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                                autoFocus
                            />
                        </div>
                        <div className="filter-group">
                            <label>{translate('Filter.Student')}</label>
                            <Select
                                options={students}
                                isMulti
                                onChange={studentFilter => this.setState({ studentFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{translate('Filter.Group')}</label>
                            <Select
                                options={groupFilterOptions}
                                isMulti
                                onChange={groupFilter => this.setState({ groupFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{translate('Filter.Closed')}</label>
                            <Select
                                options={yesNoFilterOptions}
                                isClearable
                                onChange={closedFilter => this.setState({ closedFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{translate('Filter.Alternate')}</label>
                            <Select
                                options={yesNoFilterOptions}
                                isClearable
                                onChange={alternateFilter => this.setState({ alternateFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('General.SchoolYear')}</label>
                            <Select
                                value={schyFilter}
                                options={schyFilterOptions}
                                onChange={schyFilter => this.setState({ schyFilter }, () => {
                                    this.loadCourses();
                                    this.loadRegistraions();
                                })}
                                menuPortalTarget={document.querySelector('body')}
                                isClearable
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.add()}
                        >{translate('Btn.Create')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !finishEnabled })}
                            onClick={() => { if (finishEnabled) this.finish(); }}
                        >{translate('Btn.Finish')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.delete()}
                        >{translate('Btn.Delete')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.updAttend()}
                        >{translate('Register.UpdAttBtn')}</div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-course" />
                            <col className="col-student" />
                            <col className="col-group" />
                            <col className="col-from" />
                            <col className="col-to" />
                            <col className="col-alternate" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{translate('Register.Course')}</th>
                                <th>{translate('Register.Student')}</th>
                                <th>{translate('Register.Group')}</th>
                                <th>{translate('Register.From')}</th>
                                <th>{translate('Register.To')}</th>
                                <th>{translate('Register.Alternate')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {registrationList.map(o => {
                                return (
                                    <tr
                                        key={o.id}
                                        className={classnames({ selected: selected === o.id })}
                                        onClick={() => this.select(o.id)}
                                    >
                                        <td>{o.courseName}</td>
                                        <td>{o.studentName}</td>
                                        <td>{(o.studentGroup || {}).name}</td>
                                        <td>{toStandardDmySpan(o.from)}</td>
                                        <td>{toStandardDmySpan(o.to)}</td>
                                        <td className="ta-c">{this.getYesNo(!!o.isAlternate)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Modal
                    key={modal.ts}
                    modalClass="classroom-modal"
                    onClose={() => this.closeModal()}
                    onSubmit={() => this.submitModal()}
                    title={translate('Register.Title')}
                    show={modal.open}
                    closeOnClick={false}
                >
                    <div>
                        <fieldset>
                            <div className="form-group">
                                <label>{translate('Filter.Course')}</label>
                                <Select
                                    value={modal.course}
                                    options={courses}
                                    onChange={course => this.setState({ modal: { ...modal, course } })}
                                    isDisabled={modal.mode === 'close'}
                                    autoFocus
                                />
                            </div>
                            <div className="form-group">
                                <label>{translate('Filter.Student')}</label>
                                <Select
                                    value={modal.student}
                                    options={students}
                                    onChange={student => this.setState({ modal: { ...modal, student } })}
                                    isDisabled={modal.mode === 'close'}
                                />
                            </div>
                            {modal.mode === 'close' &&
                                <div className="form-group">
                                    <label title={translate('Register.ToDateTip')}>{translate('Register.ToDate')}</label>
                                    <DatePicker
                                        className="form-control"
                                        dateFormat={["d. M. yyyy", "d.M.yyyy", "d. M.yyyy", "d.M. yyyy"]}
                                        selected={modal.date}
                                        onChangeRaw={e => e.preventDefault()}
                                        onChange={date => this.setState({ modal: { ...modal, date: date } })}
                                        placeholderText="d. M. yyyy"
                                        strictParsing={true}
                                        minDate={moment().startOf('day').add(1, 'days').toDate()}
                                    />
                                </div>
                            }
                            <div className="form-group buttons">
                                <button
                                    className="btn btn-imsp"
                                    onClick={() => this.submitModal()}
                                >{translate('Btn.Save')}</button>
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeModal()}
                                >{translate('Btn.Cancel')}</button>
                            </div>
                        </fieldset>
                    </div>
                </Modal>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(AdminRegister);