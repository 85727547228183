import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { toTerm } from '../../../utils/date';

function getDayTime(time) {
    return moment(time).format('dd  HH:mm');
}

const LessonList = ({ lessons, termId }, { translate }) => {

    let filtered = (lessons || []).filter(o => toTerm(o.time).id === termId);
    if (!filtered.length) {
        return '';
    }

    let dayTime = getDayTime(lessons[0].time);
    let title = `${translate('Filter.Term')} ${termId === 1 ? 'I' : termId === 2 ? 'II' : 'III'} (${dayTime})`;


    return (
        <div className="lesson-list">
            <div className="lesson-list-title">{title}</div>
            <div className="lesson-list-items">
                {filtered.map((o, i) => {
                    let m = moment(o.time);
                    let delimiter = i === (filtered.length - 1) ? '' : ', ';
                    let date = getDayTime(o.time) === dayTime
                        ? m.format('D. M.')
                        : m.format('dd D. M. HH:mm');
                    // console.log('item', o);
                    return (
                        <div key={i} className="lesson-list-item">{`(${date})${delimiter}`}</div>
                    );
                })}
            </div>
        </div>
    );
}

LessonList.contextTypes = {
    translate: PropTypes.func.isRequired
}

export default LessonList;