import _ from 'lodash';
import * as toastr from 'toastr';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';

import Modal from '../../../components/Modal';
import { ApiService } from '../../../services/ApiService';
import { AppContext } from '../../AppContext';
import { errorEquals, ERR_APPOINTMENT_ENGAGED, ERR_APPOINTMENT_PASSED, ERR_MEETING_DISABLED, ERR_MEETING_REG_NOT_OPEN, ERR_STUDENT_REGISTRED } from '../../../utils/error';
import { arraySort } from '../../../utils/sort';
import moment from 'moment';


const AppointmentModal = ({ year, onSubmit, onCancel }) => {

    let api = new ApiService();

    let { translate } = useContext(AppContext);
    let [meeting, setMeeting] = useState(null);
    let [meetings, setMeetings] = useState([]);
    let [appointment, setAppointment] = useState(null);
    let [appointments, setAppointments] = useState([]);
    let [student, setStudent] = useState(null);
    let [students, setStudents] = useState([]);

    useEffect(() => {
        loadMeetings();
        loadStudents();
    }, []);

    useEffect(() => {
        if ((meeting && !_.some(meetings, { id: meeting.value }))) {
            if (meetings.length) {
                setMeeting(meetings[0]);
            } else {
                setMeeting(null);
            }
        } else if (!meeting && meetings.length) {
            setMeeting(meetings[0]);
        }
    }, [meetings]);

    useEffect(() => {
        if (meeting) {
            loadAppointments();
        } else {
            setAppointments([]);
        }
    }, [meeting]);

    useEffect(() => {
        if ((appointment && !_.some(appointments, { id: appointment.value }))) {
            if (appointments.length) {
                setAppointment(appointments[0]);
            } else {
                setAppointment(null);
            }
        } else if (!appointment && appointments.length) {
            setAppointment(appointments[0]);
        }
    }, [appointments]);

    const loadAppointments = async () => {
        if (meeting && meeting.value) {
            try {
                let response = await api.getClassMeeting(meeting.value);
                let data = ((response.data || {}).appointments || []).filter(o => !o.reserved);
                arraySort(data, 'time');
                data = data.map(o => ({ value: o.id, label: o.time && moment(o.time).format('D. M. YYYY  HH:mm') || ''}));
                setAppointments(data);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const loadMeetings = async () => {
        try {
            let response = await api.getClassMeetings({ year });
            let data = (response.data || []).map(o => ({ value: o.id, label: o.name }));
            arraySort(data, 'label');
            setMeetings(data);
        } catch (error) {
            console.log(error);
        }
    }

    const loadStudents = async () => {
        try {
            let response = await api.getStudents({ year });
            let data = (response.data || []).map(o => ({ value: o.id, label: o.name }));
            arraySort(data, 'label');
            setStudents(data);
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmitClick = async () => {
        try {
            let data = {
                appointmentId: appointment && appointment.value || null,
                studentId: student && student.value || null
            };
            let response = await api.registerMeeting(data);
            if (response.data) {
                toastr.success(translate('RegMeeting.RegSuccess'));
                if (onSubmit) onSubmit(); else onCancel();
            }
        } catch (error) {
            if (errorEquals(error, ERR_MEETING_DISABLED)) {
                toastr.error(translate('RegMeeting.MeetingDisabled'));
                if (onCancel) onCancel();
            } else if (errorEquals(error, ERR_MEETING_REG_NOT_OPEN)) {
                toastr.error(translate('RegMeeting.MeetingRegNotOpen'));
                if (onCancel) onCancel();
            } else if (errorEquals(error, ERR_APPOINTMENT_PASSED)) {
                toastr.error(translate('RegMeeting.AppointmentPassed'));
                if (onCancel) onCancel();
            } else if (errorEquals(error, ERR_APPOINTMENT_ENGAGED)) {
                toastr.error(translate('RegMeeting.AppointmentEngaged'));
                if (onCancel) onCancel();
            } else if (errorEquals(error, ERR_STUDENT_REGISTRED)) {
                toastr.error(translate('RegMeeting.StudentRegistred'));
            } else {
                toastr.error(translate('Err.System'));
            }
        }
    }

    return (
        <Modal
            modalClass="appointment-modal"
            onClose={onCancel}
            onSubmit={onSubmit}
            title={translate('RegMeeting.Title')}
            closeOnClick={false}
            showCloseButton
            show
        >
            <div>
                <fieldset>
                    <div className="form-group">
                        <label >{translate('ClassMeeting.Title')}</label>
                        <Select
                            value={meeting}
                            options={meetings}
                            onChange={value => setMeeting(value)}
                            autoFocus
                        />
                    </div>
                    <div className="form-group">
                        <label >{translate('RegMeeting.Appointment')}</label>
                        <Select
                            value={appointment}
                            options={appointments}
                            onChange={value => setAppointment(value)}
                        />
                    </div>
                    <div className="form-group">
                        <label >{translate('RegMeeting.Student')}</label>
                        <Select
                            value={student}
                            options={students}
                            onChange={value => setStudent(value)}
                        />
                    </div>
                    <div className="form-group buttons">
                        <button
                            className="btn btn-light"
                            onClick={onCancel}
                        >{translate('Btn.Cancel')}</button>
                        <button
                            className="btn btn-imsp"
                            onClick={onSubmitClick}
                        >{translate('Btn.Save')}</button>
                    </div>
                </fieldset>
            </div>
        </Modal>
    );
}

export default AppointmentModal;