import * as React from 'react';
import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { ApiService } from '../../../services/ApiService';
import ReactTooltip from 'react-tooltip';
import { arraySort } from '../../../utils/sort';
import CourseContainer from './CourseContainer';
import MeetingContainer from './MeetingContainer';


class Menu extends React.Component {
    static propTypes = {
        push: PropTypes.func.isRequired,
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            courses: []
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadCourses();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Menu.Title');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadCourses() {
        this.api.getCoursePreview()
            .then(response => {
                let courses = response.data || [];
                courses = arraySort(courses, 'name');
                if (this.mounted) {
                    this.setState({ courses }, () => ReactTooltip.rebuild());
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    getItems(courses) {
        let result = (courses || []).filter(o => !o.isHidden);
        arraySort(result, 'order', 'name');
        return result;
    }

    render() {
        return (
            <div className="user-page-container">
                <CourseContainer />
                <MeetingContainer />
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {
        push
    }
)(Menu);