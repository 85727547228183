import _ from 'lodash';
import * as React from 'react';
import * as toastr from 'toastr';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from "react-redux";

import Modal from '../../../components/Modal';
import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';

import './students.scss';
import { ERR_DEPENDENT_DATA, errorEquals } from '../../../utils/error';
import schoolYear from '../../../utils/schoolYear';


class Students extends React.Component {
    static contextTypes = {
        translate: PropTypes.func.isRequired,
        onTitle: PropTypes.func
    };

    constructor(props, context) {
        super(props);

        this.initModal = {
            open: false,
            id: '',
            name: '',
            userId: '',
            groupId: '',
            free: false,
            hidden: false,
            isDiner: true,
            from: '',
            to: ''
        };
        this.initImportModal = {
            open: false,
            diners: {}
        };
        this.state = {
            modal: this.initModal,
            importModal: this.initImportModal,
            students: {},
            selected: '',
            users: [],
            groups: [],
            nameFilter: '',
            groupFilter: [],
            userFilter: [],
            allInclusiveFilter: null,
            diners: [],
            schyFilter: { value: schoolYear().year(), label: schoolYear().yearText() }
        };
        this.api = new ApiService();
        this.mounted = false;

        this.yesno = {
            true: context.translate('Codelist.Yes'),
            false: context.translate('Codelist.No')
        }
    }

    componentWillMount() {
        this.loadStudents();
        this.loadUsers();
        this.loadGroups();
        this.loadDiners();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Header.Students');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadDiners() {
        this.api.getDiners()
            .then(response => {
                let diners = response.data || [];
                if (this.mounted) {
                    this.setState({ diners });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadGroups() {
        this.api.getGroups()
            .then(response => {
                let groups = response.data || [];
                if (this.mounted) {
                    this.setState({ groups });
                }
            })
            .catch(error => {
                console.log('Students.loadGroups', error);
            });
    }

    loadStudents() {
        let { schyFilter, selected } = this.state;

        let params = {};
        if (schyFilter && schyFilter.value)
            params.year = schyFilter.value;

        this.api.getStudents(params)
            .then(response => {
                if (this.mounted) {
                    let students = _.mapKeys(response.data, 'id');
                    if (selected && !students[selected]) {
                        selected = '';
                    }
                    this.setState({ students, selected });
                }
            })
            .catch(error => {
                console.log('Students.loadStudents', error);
            });
    }

    loadUsers() {
        this.api.getAccounts()
            .then(response => {
                let users = response.data || [];
                if (this.mounted) {
                    this.setState({ users });
                }
            })
            .catch(error => {
                console.log('Students.loadUsers', error);
            });
    }

    add() {
        this.setState({
            modal: {
                ...this.initModal,
                open: true
            }
        });
    }

    update(id) {
        id = id || this.state.selected;
        if (!id) {
            return;
        }

        let { name, userId, groupId, free, hidden, isDiner, from, to } = this.state.students[id] || {};
        this.setState({
            modal: {
                ...this.initModal,
                open: true,
                id,
                name: name || this.initModal.name,
                userId: userId || this.initModal.userId,
                groupId: groupId || this.initModal.groupId,
                free: free !== null && free !== undefined ? free : this.initModal.free,
                hidden: hidden !== null && hidden !== undefined ? hidden : this.initModal.hidden,
                isDiner: isDiner || false,
                from: from || this.initModal.from,
                to: to || this.initModal.to
            },
            selected: id
        });
    }

    closeModal() {
        this.setState({ modal: this.initModal });
    }

    submitModal() {
        let { id, name, userId, groupId, free, hidden, isDiner, from, to } = this.state.modal;

        from = from || null;
        to = to || null;

        if (this.validateModal()) {
            if (id) {
                this.api.updateStudent(id, name, userId, groupId, free, hidden, isDiner, from, to)
                    .then(response => {
                        this.loadStudents();
                        this.closeModal();
                        toastr.success(this.context.translate('Students.EditSuccess'));
                    })
                    .catch(error => {
                        console.log('Students.submitModal error update group', error);
                        toastr.error(error);
                    });
            } else {
                this.api.addStudent(name, userId, groupId, free, hidden, isDiner, from, to)
                    .then(response => {
                        this.loadStudents();
                        this.closeModal();
                        toastr.success(this.context.translate('Students.AddSuccess'));
                    })
                    .catch(error => {
                        console.log('Students.submitModal error add group', error);
                        toastr.error(error);
                    });
            }
        }
    }

    validateModal() {
        return true;
    }

    select(key) {
        this.setState({ selected: key === this.state.selected ? null : key });
    }

    getUserOptions(users) {
        let result = arraySort([...users || []], 'name');
        result.splice(0, 0, { id: '', name: '' });
        return result;
    }

    getUserFilterOptions(users) {
        let result = (users || []).map(o => ({ value: o.id, label: o.name }));
        arraySort(result, 'label');
        return result;
    }

    getGroupOptions(groups) {
        let result = arraySort([...groups || []], 'name');
        result.splice(0, 0, { id: '', name: '' });
        return result;
    }

    getGroupFilterOptions(groups) {
        let result = (groups || []).map(o => ({ value: o.id, label: o.name }));
        arraySort(result, 'label');
        return result;
    }

    getStudentList(students, nameFilter, groupFilter, userFilter, allInclusiveFilter) {
        let result = arraySort(_.map(students), 'name')
        if (userFilter && userFilter.length) {
            result = result.filter(o => _.some(userFilter, { value: o.userId }));
        }
        if (allInclusiveFilter) {
            result = result.filter(o => !!o.free === allInclusiveFilter.value);
        }
        if (groupFilter && groupFilter.length) {
            result = result.filter(o => _.some(groupFilter, { value: o.groupId }));
        }
        if (nameFilter) {
            result = result.filter(o => (o.name || '').toLowerCase().includes(nameFilter.toLowerCase().trim())
                || (o.userName || '').toLowerCase().includes(nameFilter.toLowerCase().trim()));
        }
        return result;
    }

    getYesNoFilterOptions() {
        let result = [
            { value: false, label: this.yesno[false] },
            { value: true, label: this.yesno[true] }
        ];
        return result;
    }

    showImportDiners() {
        let { diners } = this.state;
        console.log('xxx', diners);
        diners = diners.filter(o => !o.studentId && !o.isUser);
        diners = diners.map(({ id, name }) => ({
            id,
            name,
            selected: true
        }));
        diners = _.mapKeys(diners, 'id');
        this.setState({ importModal: { ...this.initImportModal, open: true, diners } });
    }

    closeImportModal() {
        this.setState({ importModal: { ...this.initImportModal } });
    }

    submitImportModal() {
        let dinerIds = _.map(this.state.importModal.diners).filter(o => o.selected).map(o => o.id);

        this.api.importDiners(dinerIds)
            .then(response => {
                if (this.mounted) {
                    this.loadStudents();
                    this.loadDiners();
                }
                this.closeImportModal();
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    selectDiner(id) {
        let { importModal } = this.state;

        let diners = { ...importModal.diners };
        if (diners[id]) {
            diners[id].selected = !diners[id].selected;
            this.setState({ importModal: { ...importModal, diners } });
        }
    }

    selectAllDiners() {
        let { importModal } = this.state;

        let diners = { ...importModal.diners };
        let selected = this.getAllDinersSelected(diners);
        selected = selected === 1 ? -1 : 1;

        for (let [, value] of Object.entries(diners)) {
            value.selected = selected === 1;
        }
        this.setState({ importModal: { ...importModal, diners } });
    }

    getAllDinersSelected(diners) {
        diners = diners || this.state.importModal.diners;
        let result = undefined;
        for (let [, value] of Object.entries(diners)) {
            if (result === undefined) {
                result = value.selected ? 1 : -1;
            } else if (result < 0) {
                if (value.selected) {
                    return 0;
                }
            } else if (result > 0) {
                if (!value.selected) {
                    return 0;
                }
            }
        }
        return result || 0;
    }

    delete(id = '', force = false) {
        id = id || this.state.selected;
        
        if (!id || (!force && !window.confirm(this.context.translate('Students.ConfirmDelete')))) {
            return;
        }

        this.api.deleteStudent(id, force)
            .then(response => {
                if (this.mounted) {
                    toastr.success(this.context.translate('Students.DeletedSuccessfuly'));
                    this.loadStudents();
                    this.setState({ selected: '' });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    if (errorEquals(error, ERR_DEPENDENT_DATA)) {
                        if (window.confirm(this.context.translate('Students.ConfirmForceDelete'))) {
                            this.delete(id, true);
                        }
                    } else {
                        toastr.error(this.context.translate('Err.System'));
                    }
                }
            });
    }

    getSchyFilterOptions() {
        let curYear = schoolYear().year();
        let result = [];
        for (let i = curYear - 1; i <= curYear + 1; i++) {
            result.push({ value: i, label: schoolYear(i).yearText() })
        }
        return result;
    }

    render() {
        let { students, selected, modal, users, groups, nameFilter, groupFilter,
            userFilter, allInclusiveFilter, importModal, schyFilter } = this.state;
        let { translate } = this.context;

        let studentList = this.getStudentList(students, nameFilter, groupFilter, userFilter, allInclusiveFilter);
        let isSelected = !!selected;
        let userOptions = this.getUserOptions(users);
        let userFilterOptions = this.getUserFilterOptions(users);
        let groupOptions = this.getGroupOptions(groups);
        let groupFilterOptions = this.getGroupFilterOptions(groups);
        let yesNoFilterOptions = this.getYesNoFilterOptions();
        let schyFilterOptions = this.getSchyFilterOptions();

        let allDinersSelected = importModal.open ? this.getAllDinersSelected() : 0;

        // console.log('render', modal);

        return (
            <div className="page-container group-page">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Name')}</label>
                            <input
                                className="form-control"
                                type="text"
                                name="name"
                                value={nameFilter}
                                onChange={e => this.setState({ nameFilter: e.target.value })}
                                autoFocus
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Account')}</label>
                            <Select
                                options={userFilterOptions}
                                isMulti
                                onChange={userFilter => this.setState({ userFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.Group')}</label>
                            <Select
                                options={groupFilterOptions}
                                isMulti
                                onChange={groupFilter => this.setState({ groupFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('Filter.AllInclusive')}</label>
                            <Select
                                options={yesNoFilterOptions}
                                isClearable
                                onChange={allInclusiveFilter => this.setState({ allInclusiveFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{this.context.translate('General.SchoolYear')}</label>
                            <Select
                                value={schyFilter}
                                options={schyFilterOptions}
                                onChange={schyFilter => this.setState({ schyFilter }, () => {
                                    this.loadStudents();
                                })}
                                menuPortalTarget={document.querySelector('body')}
                                isClearable
                                placeholder={translate('Codelist.All')}
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.add()}
                        >{this.context.translate('Btn.Add')}</div>
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.showImportDiners()}
                        >{this.context.translate('Students.ImportDiners')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.update()}
                        >{this.context.translate('Btn.Update')}</div>
                        <div
                            className={classnames("btn btn-imsp", { disabled: !isSelected })}
                            onClick={() => this.delete()}
                        >{this.context.translate('Btn.Delete')}</div>
                    </div>
                </div>
                <div className="page-content">
                    <table className="imsp-table">
                        <colgroup>
                            <col className="col-name" />
                            <col className="col-user" />
                            <col className="col-group" />
                            <col className="col-free" />
                            <col className="col-payment" />
                            <col className="col-balance" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{this.context.translate('Students.Name')}</th>
                                <th>{this.context.translate('Students.User')}</th>
                                <th>{this.context.translate('Students.Group')}</th>
                                <th>{this.context.translate('Students.Free')}</th>
                                <th>{this.context.translate('Students.PaymentId')}</th>
                                <th>{this.context.translate('Students.Balance')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentList.map(o => {
                                return (
                                    <tr
                                        key={o.id}
                                        className={classnames({ selected: selected === o.id })}
                                        onClick={() => this.select(o.id)}
                                        onDoubleClick={() => this.update(o.id)}
                                    >
                                        <td>{o.name}</td>
                                        <td>{o.userName}</td>
                                        <td>{o.groupName}</td>
                                        <td className="ta-c">{this.yesno[o.free || false]}</td>
                                        <td className="ta-c">{o.paymentId}</td>
                                        <td className="ta-r">{o.balance} {this.context.translate('Units.Money')}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <Modal
                    modalClass="group-modal"
                    onClose={() => this.closeImportModal()}
                    onSubmit={() => this.submitImportModal()}
                    title={translate('Students.ImportDiners')}
                    show={importModal.open}
                    closeOnClick={false}
                >
                    <div>
                        <fieldset>
                            <div className="form-group import-diners-table-container">
                                <table className="imsp-table import-diners-table">
                                    <colgroup>
                                        <col className="col-name" />
                                        <col className="col-selected" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>{this.context.translate('Students.Name')}</th>
                                            <th
                                                className="select-cell ta-c"
                                                onClick={() => this.selectAllDiners()}
                                            >
                                                <span>{this.context.translate('Students.All')}</span>
                                                <i className={classnames('fa', { 'fa-check': allDinersSelected === 1, 'fa-times': allDinersSelected === -1, 'fa-question': allDinersSelected === 0 })} aria-hidden="true"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.map(importModal.diners, o => {
                                            return (
                                                <tr
                                                    key={o.id}
                                                    onClick={() => this.selectDiner(o.id)}
                                                >
                                                    <td>{o.name}</td>
                                                    <td className="select-cell ta-c"><i className={classnames('fa', { 'fa-check': o.selected, 'fa-times': !o.selected })} aria-hidden="true"></i></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="form-group buttons">
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeImportModal()}
                                >{translate('Btn.Cancel')}</button>
                                <button
                                    className="btn btn-imsp"
                                    onClick={() => { if (allDinersSelected > -1) this.submitImportModal(); }}
                                    disabled={allDinersSelected === -1}
                                >{translate('Btn.Import')}</button>
                            </div>
                        </fieldset>
                    </div>
                </Modal>

                <Modal
                    modalClass="group-modal"
                    onClose={() => this.closeModal()}
                    onSubmit={() => this.submitModal()}
                    title={this.context.translate('Students.Modal.Title')}
                    show={modal.open}
                    closeOnClick={false}
                >
                    <div>
                        <fieldset>
                            <input
                                type="hidden"
                                id="id"
                                name="id"
                            />
                            <div className="form-group">
                                <label htmlFor="name">{this.context.translate('Students.Name')}</label>
                                <input
                                    className="form-control"
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    autoFocus={true}
                                    value={modal.name}
                                    onChange={e => this.setState({ modal: { ...modal, name: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="user">{this.context.translate('Students.User')}</label>
                                <select
                                    className="form-control"
                                    name="user"
                                    value={modal.userId}
                                    onChange={e => this.setState({ modal: { ...modal, userId: e.target.value } })}
                                >
                                    {userOptions.map(o => (
                                        <option key={o.id || 0} value={o.id}>{o.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="group">{this.context.translate('Students.Group')}</label>
                                <select
                                    className="form-control"
                                    name="group"
                                    value={modal.groupId}
                                    onChange={e => this.setState({ modal: { ...modal, groupId: e.target.value } })}
                                >
                                    {groupOptions.map(o => (
                                        <option key={o.id || 0} value={o.id}>{o.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="free">{this.context.translate('Students.Free')}</label>
                                <select
                                    className="form-control"
                                    name="free"
                                    value={modal.free}
                                    onChange={e => {
                                        this.setState({ modal: { ...modal, free: e.target.value } });
                                    }}
                                >
                                    {Object.keys(this.yesno).map(o => (
                                        <option key={o} value={o}>{this.yesno[o]}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Students.IsDiner')}</label>
                                <select
                                    className="form-control"
                                    value={modal.isDiner}
                                    onChange={e => {
                                        this.setState({ modal: { ...modal, isDiner: e.target.value } });
                                    }}
                                >
                                    {Object.keys(this.yesno).map(o => (
                                        <option key={o} value={o}>{this.yesno[o]}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Students.From')}</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    autoComplete="off"
                                    value={modal.from}
                                    onChange={e => this.setState({ modal: { ...modal, from: e.target.value } })}
                                />
                            </div>
                            <div className="form-group">
                                <label>{this.context.translate('Students.To')}</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    autoComplete="off"
                                    value={modal.to}
                                    onChange={e => this.setState({ modal: { ...modal, to: e.target.value } })}
                                />
                            </div>

                            <div className="form-group buttons">
                                <button
                                    className="btn btn-imsp"
                                    onClick={() => this.submitModal()}
                                >{this.context.translate('Btn.Save')}</button>
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeModal()}
                                >{this.context.translate('Btn.Cancel')}</button>
                            </div>
                        </fieldset>
                    </div>
                </Modal>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Students);