import * as toastr from 'toastr';
import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import Select from 'react-select';

import Modal from '../../../components/Modal';
import { ApiService } from '../../../services/ApiService';
import { arraySort } from '../../../utils/sort';
import { AppContext } from '../../AppContext';
import { errorEquals, ERR_APPOINTMENT_ENGAGED, ERR_APPOINTMENT_PASSED, ERR_INVALID_GROUP_ID, ERR_MEETING_DISABLED, ERR_MEETING_REG_NOT_OPEN, ERR_STUDENT_REGISTRED } from '../../../utils/error';


const RegMeeting = ({ onCancel, onSubmit, meeting, appointment }) => {

    const api = new ApiService();

    const { translate } = useContext(AppContext);

    let [student, setStudent] = useState(null);
    let [students, setStudents] = useState([]);

    useEffect(() => {
        loadStudents();
    }, []);

    const loadStudents = async () => {
        try {
            let response = await api.getMyStudents();
            let data = (response.data || []).map(o => ({ value: o.id, label: o.name }));
            arraySort(data, 'label');
            setStudents(data);
            if (data.length) {
                setStudent(data[0]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onModalSubmit = async () => {
        try {
            let data = {
                appointmentId: appointment.id,
                studentId: student && student.value || null
            };
            let response = await api.registerMeeting(data);
            if (response.data) {
                toastr.success(translate('RegMeeting.RegSuccess'));
                if (onSubmit) onSubmit(); else onCancel();
            }
        } catch (error) {
            if (errorEquals(error, ERR_MEETING_DISABLED)) {
                toastr.error(translate('RegMeeting.MeetingDisabled'));
                if (onCancel) onCancel();
            } else if (errorEquals(error, ERR_MEETING_REG_NOT_OPEN)) {
                toastr.error(translate('RegMeeting.MeetingRegNotOpen'));
                if (onCancel) onCancel();
            } else if (errorEquals(error, ERR_APPOINTMENT_PASSED)) {
                toastr.error(translate('RegMeeting.AppointmentPassed'));
                if (onCancel) onCancel();
            } else if (errorEquals(error, ERR_APPOINTMENT_ENGAGED)) {
                toastr.error(translate('RegMeeting.AppointmentEngaged'));
                if (onCancel) onCancel();
            } else if (errorEquals(error, ERR_STUDENT_REGISTRED)) {
                toastr.error(translate('RegMeeting.StudentRegistred'));
            } else if (errorEquals(error, ERR_INVALID_GROUP_ID)) {
                toastr.error(translate('RegMeeting.InvalidGroup'));
            } else {
                toastr.error(translate('Err.System'));
            }
        }
    }

    return (
        <Modal
            modalClass="register-meeting-modal"
            onClose={onCancel}
            onSubmit={onModalSubmit}
            title={translate('RegMeeting.Title')}
            closeOnClick={false}
            showCloseButton
            show
        >
            <div>
                <fieldset>
                    <div className="form-group">
                        <label>{translate('ClassMeeting.Title')}</label>
                        <input
                            className="form-control"
                            type="text"
                            value={meeting && meeting.name || ''}
                            onChange={() => { }}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('RegMeeting.Appointment')}</label>
                        <input
                            className="form-control"
                            type="text"
                            value={appointment && appointment.time ? moment(appointment.time).format('D. M. YYYY HH:mm') : ''}
                            onChange={() => { }}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('RegMeeting.Student')}</label>
                        <Select
                            value={student}
                            options={students}
                            onChange={student => setStudent(student)}
                        />
                    </div>
                    <div className="form-group buttons">
                        <button
                            className="btn btn-imsp"
                            onClick={onModalSubmit}
                        >{translate('ClassMeeting.Register')}</button>
                        <button
                            className="btn btn-light"
                            onClick={onCancel}
                        >{translate('Btn.Cancel')}</button>
                    </div>
                </fieldset>
            </div>
        </Modal>
    );
}

export default RegMeeting;