const cs = {
	"Account": {
		"Name": "Jméno",
		"Email": "E-mail",
		"Address": "Adresa",
		"AccNum": "Číslo účtu",
		"Diners": "Strávníci",
		"Roles": "Oprávnění",
		"SetRole": "Nastavit oprávnění",
		"IsTeacher": "Učitel",
		"SetAsDiner": "Nastavit jako strávníka",
		"WithoutStudent": "Bez studenta",
		"Delete": "Smazat",
		"DeletedSuccessfuly": "Účet byl úspěšně smazán.",
		"ConfirmDelete": "Opravdu chcete smazat vybraný účet?",
		"ConfirmForceDelete": "Pro vybraný účet již existují data. Opravdu jej chcete smazat?"
	},
	"Alergen": {
		"Id": "ID",
		"Cs": "Česky",
		"En": "Anglicky",
		"ModalTitle": "Alergen",
		"SaveSuccess": "Alergen byl úspěšně uložen.",
		"DeleteSuccess": "Alergen byl úspěšně smazán."
	},
	"Billing": {
		"User": "Uživatel",
		"Diner": "Strávník",
		"CreditBefore": "Počáteční stav kreditu",
		"Menu": "Hlavní jídla",
		"Snack": "Svačiny",
		"Total": "Celkem",
		"Credit": "Konečný stav kreditu",
		"Recalculate": "Přepočítat...",
		"CalcTitle": "Přepočítat vyúčtování",
		"CalcSubmit": "Spustit",
		"ConfirmRecalculate": "Opravdu chcete spustit přepočet vyúčtování za {period} a všech následujících vyúčtování?",
		"RecalculateSent": "Požadavek na přepočet byl úspěšně odeslán. Na minutku si odpočiňte a pak obnovte stránku.",
		"ProcTitle": "Proces výpočtu vyúčtování",
		"ProcQueue": "Fronta požadavků",
		"ProcJob": "Aktuálně zpracovávaný požadavek",
		"ProcCalc": "Probíhající výpočet",
		"Period": "Období",
		"Student": "Student",
		"All": "Všichni",
		"Deposit": "Vklady",
		"Payment": "Kurzovné",
		"Suma": "Celkem",
		"Balance": "Bilance"
	},
	"Btn": {
		"Add": "Přidat",
		"Update": "Upravit",
		"Delete": "Smazat",
		"Cancel": "Zrušit",
		"Save": "Uložit",
		"Export": "Export",
		"Import": "Import",
		"Close": "Zavřít",
		"Select": "Vybrat",
		"Send": "Odeslat",
		"Print": "Tisk",
		"Back": "Zpět",
		"Create": "Vytvořit",
		"Finish": "Ukončit"
	},
	"Codelist": {
		"All": "< vše >",
		"ChoseDiner": "< vyber strávníka >",
		"Yes": "Ano",
		"No": "Ne"
	},
	"Daily": {
		"Diner": "Strávník",
		"Group": "Skupina"
	},
	"Days": {
		"Basic": {
			"0": "Neděle",
			"1": "Pondělí",
			"2": "Úterý",
			"3": "Středa",
			"4": "Čtvrtek",
			"5": "Pátek",
			"6": "Sobota"
		},
		"Short": {
			"0": "Ne",
			"1": "Po",
			"2": "Út",
			"3": "St",
			"4": "Čt",
			"5": "Pá",
			"6": "So"
		}
	},
	"Diner": {
		"Name": "Jméno",
		"Group": "Skupina",
		"Account": "Účet",
		"UserDiner": "Je uživatel",
		"NotPay": "All inclusive",
		"GlutenFree": "Bezlepkové menu",
		"Move": "Přesunout",
		"ModalTitle": "Strávník",
		"MoveModalTitle": "Přesunout strávníky",
		"MoveSubmit": "Přesunout",
		"MoveSuccess": "Strávníci byli úspěšně přesunuti.",
		"PaymentId": "Variabilní symbol",
		"Balance": "Stav konta",
		"Unposted": "Nezúčtované objednávky",
		"ConfirmDelete": "Opravdu chcete smazat vybraného strávníka?",
		"DeleteSuccess": "Strávník byl úspěšně smazán.",
		"DeleteDependencies": "Strávník, které ho se chystáte smazat, již obsahuje data (objednávky, platby, vyúčtování). Opravdu jej chcete smazat i se všemi daty?",
		"Hidden": "Skrýt v objednávkách"
	},
	"Emails": {
		"To": "Komu",
		"Subject": "Předmět",
		"Message": "Zpráva",
		"SelectTo": "Vyber příjemce",
		"All": "Všichni",
		"SendSuccess": "E-mail byl úspěšně odeslán."
	},
	"Filter": {
		"Period": "Období",
		"Diner": "Strávník",
		"Group": "Skupina",
		"Date": "Datum",
		"Title": "Filtr",
		"AllInclusive": "All inclusive",
		"IsUser": "Je uživatel",
		"Name": "Jméno",
		"Type": "Typ",
		"Course": "Kroužek",
		"Lesson": "Lekce",
		"Student": "Student",
		"Closed": "Zobrazit ukončené",
		"Alternate": "Náhradník",
		"Account": "Účet",
		"Term": "Term",
		"Hidden": "Skryté",
		"Disabled": "Aktivní",
		"ActivityType": "Typ aktivity"
	},
	"Group": {
		"Title": "Skupina",
		"Name": "Název",
		"Order": "Pořadí v Měsíčním přehledu",
		"AddSuccess": "Skupina byla úspěšně vytvořena.",
		"EditSuccess": "Skupina byla úspěšně upravena."
	},
	"Header": {
		"Menu": "Nabídka",
		"Login": "Přihlásit",
		"Register": "Registrovat",
		"Payments": "Platby",
		"Logout": "Odhlásit",
		"Administration": "Správa aplikace",
		"Accounts": "Uživatelské účty",
		"Diners": "Strávníci",
		"Groups": "Skupiny",
		"Lunch": "Jídelní lístek",
		"Profile": "Profil",
		"AdminPayments": "Platby",
		"Billing": "Vyúčtování",
		"Alergens": "Alergeny",
		"Settings": "Nastavení",
		"Reports": "Přehledy",
		"OrderSummary": "Počty objednávek",
		"OrderDay": "Objednávky strávníků",
		"OrderMonth": "Měsíční objednávky",
		"Emails": "Zasílání e-mailů",
		"About": {
			"Title": "O aplikaci",
			"Name": "Název",
			"App": "IMSP Registrační systém",
			"Version": "Verze",
			"Copyright": "© 2020 Copyright by IMSP. All right reserved.",
			"By": "Vytvořil"
		},
		"Notice": "Zaslané upozornění",
		"Teachers": "Učitelé",
		"Classrooms": "Místnosti",
		"Students": "Studenti",
		"ReportWeek": "Týdenní přehled",
		"LectorAttendance": "Docházka",
		"Registrations": "Registrace",
		"AttendanceView": "Docházka",
		"LectorEmail": "Zaslat e-mail"
	},
	"ImportResult": {
		"Title": "Výsledek importu"
	},
	"Lunch": {
		"SaveSuccess": "Menu bylo úspěšně nastaveno.",
		"ImportSuccess": "Bylo úspěšně importováno {{count}} záznamů.",
		"ImportError": "Bylo importováno {{count}} záznamů, {{errCount}} skončilo chybou:</br>{{errors}}",
		"Period": "Období",
		"Lang": "Jazyk",
		"DownloadImport": "Stáhnout vzorový soubor",
		"ColDate": "Datum",
		"ColLang": "Jazyk",
		"ColSoup": "Polévka",
		"RemoveSuccess": "Menu bylo úspěšně smazáno.",
		"ConfirmRemove": "Opravdu chcete smazat vybraný záznam?"
	},
	"LunchMenu": {
		"NoDiner": "Vítejte v IMSP Obědovém systému. Zatím nemáte ke svému účtu nastaveného žádného strávníka, takže nemůžete objednávat.",
		"Balance": "Stav konta",
		"UnpostedOrders": "Nezaúčtované objednávky",
		"PaymentInfo": "Zobrazit údaje pro platbu",
		"Alergens": "Alergeny",
		"NoAlergens": "Alergeny nejsou zadány",
		"AlergensNotParsed": "Alergeny nebyly rozpoznány",
		"Modal": {
			"Title": "Platební údaje",
			"Number": "Číslo účtu",
			"Symbol": "Variabilní symbol",
			"Amount": "Částka",
			"Note": "Částka platby je pouze orientační údaj, vypočítaný na základě stavu konta a objednávek v daném měsíci.",
			"Close": "Zavřít",
			"Ss": "Specifický symbol"
		},
		"ExportMenu": "Export jídelníčku",
		"MissingBillingInfo": {
			"Part1": "Vážený uživateli, nemáte vyplněny fakturační údaje. Nastavte si je prosím v sekci ",
			"Part2": ". Pokud nebudete mít vypněny fakturační údaje, nebude vám dovoleno objednávat obědy."
		},
		"LimitExceeded": "Byla překročena maximální hodnota dluhu.",
		"HidePast": "Skrýt staré"
	},
	"MealType": {
		"0": "Polévka",
		"1": "Masové menu",
		"2": "Vegetariánské menu",
		"3": "Bezlepkové menu",
		"4": "Svačina"
	},
	"Monthly": {
		"Diner": "Strávník",
		"Group": "Skupina",
		"Print": {
			"Title": "Měsíční přehled objednávek",
			"All": "všechny"
		}
	},
	"Months": {
		"Basic": {
			"1": "Leden",
			"2": "Únor",
			"3": "Březen",
			"4": "Duben",
			"5": "Květen",
			"6": "Červen",
			"7": "Červenec",
			"8": "Srpen",
			"9": "Září",
			"10": "Říjen",
			"11": "Listopad",
			"12": "Prosinec"
		},
		"OfDate": {
			"1": "Ledna",
			"2": "Února",
			"3": "Března",
			"4": "Dubna",
			"5": "Května",
			"6": "Června",
			"7": "Července",
			"8": "Srpna",
			"9": "Září",
			"10": "Října",
			"11": "Listopadu",
			"12": "Prosince"
		},
		"Short": {
			"1": "Led",
			"2": "Úno",
			"3": "Bře",
			"4": "Dub",
			"5": "Kvě",
			"6": "Čvn",
			"7": "Čvc",
			"8": "Srp",
			"9": "Zář",
			"10": "Říj",
			"11": "Lis",
			"12": "Pro"
		}
	},
	"Notice": {
		"Time": "Čas odeslání",
		"Period": "Období",
		"Result": "Výsledek",
		"CountText": "Počet dlužníků",
		"Ok": "Vše OK",
		"Errors": "Chyby",
		"Diner": "Strávník",
		"Balance": "Stav konta",
		"Error": "Chyba"
	},
	"OrderView": {
		"Date": "Datum",
		"Count": "Počet"
	},
	"Payment": {
		"TypePayment": "Platba",
		"TypeDeposite": "Vklad",
		"Time": "Čas",
		"Diner": "Strávník",
		"Type": "Typ",
		"Description": "Popis",
		"Amount": "Částka",
		"SumaPayment": "Celkem platby",
		"AddTitle": "Nový záznam",
		"SaveSuccess": "Záznam byl úspěšně uložen.",
		"ImportSuccess": "Bylo úspěšně importováno {{count}} záznamů.",
		"ImportError": "Bylo importováno {{count}} záznamů, {{errCount}} skončilo chybou:</br>{{errors}}",
		"Balance": "Stav konta",
		"TotalDeposite": "Celkem vklady",
		"ImportExample": "Ukázový importní soubor",
		"Import": "Import vkladů",
		"ConfirmDelete": "Opravdu chcete smazat vybraný záznam?",
		"MealType": "Jídlo",
		"EditTitle": "Úprava záznamu",
		"Student": "Student",
		"Course": "Registrace na kroužek",
		"Note": "Poznámka"
	},
	"Profile": {
		"Profile": "Profil",
		"Name": "Jméno",
		"Email": "E-mail",
		"Invoice": "Fakturační údaje",
		"Address": "Adresa",
		"Account": "Číslo účtu",
		"Ico": "IČO",
		"ProfileSuccess": "Profil byl uložen.",
		"BillingSuccess": "Fakturační údaje uloženy."
	},
	"Settings": {
		"Title": "Nastavení",
		"Other": "Ostatní parametry",
		"AccountNumber": "Číslo účtu",
		"OtherSuccess": "Ostatní parametry byly úspěšně uloženy.",
		"SpecificSymbol": "Specifický symbol",
		"SendTeacherEmailCopy": "Zasílat kopii e-mailu od lektora",
		"AdminEmail": "E-mail administrátora",
		"EmailSection": "Nastavení e-mailů",
		"SaveEmailSuccess": "Nastavení e-mailů bylo úspěšně uloženo.",
		"NoticeSection": "Upozornění na záporné konto",
		"NegBalNotifEnabled": "Upozornění zapnuto",
		"NegBalNotifPeriod": "Den upozornění",
		"NegBalNotifTime": "Čas upozornění",
		"SaveNoticeSuccess": "Nastavení upozornění bylo úspěšně uloženo.",
		"ShowCourseDaysBeforeSchy": "Počet dní pro zobrazení kroužků před začátkem školního roku",
		"AutoCreatePaymentInterval": "Počet dní před semestrem pro zaúčtování kroužků"
	},
	"Units": {
		"Money": "Kč"
	},
	"Menu": {
		"Title": "Nabídka kroužků",
		"MenuLink": "Nabídka",
		"Courses": "Zájmové kroužky",
		"Group": "Skupina",
		"Teacher": "Učitel",
		"Price": "Cena/Lekci",
		"Capacity": "Kapacita",
		"Begin": "Začíná dne",
		"Classroom": "Kde",
		"Ttx": "počet registrovaných osob",
		"Tty": "kapacita kroužku",
		"Ttz": "max. počet náhradníků",
		"Meetings": "Třídní schůzky"
	},
	"Img": {
		"UnsupportedFileType": "Nepodporovaný typ souboru",
		"MaxFileSize6": "Maximální povolená velikost souboru je 6MB."
	},
	"Classroom": {
		"Title": "Místnost",
		"Name": "Jméno",
		"Address": "Adresa",
		"Description": "Popis",
		"Photo": "Fotka",
		"LoadPhoto": "Načíst fotku",
		"EditSuccess": "Místnost byla úspěšně upravena.",
		"AddSuccess": "Místnost byla úspěšně vytvořena.",
		"DeleteSuccess": "Místnost byla úspěšně smazána.",
		"ConfirmDelete": "Opravdu chcete smazat vybranou místnost?"
	},
	"Course": {
		"Title": "Kroužek",
		"Name": "Název",
		"Teacher": "Učitel",
		"Capacity": "Kapacita",
		"Description": "Popis",
		"Alternate": "Náhradníci",
		"OpenFrom": "Registrace otevřena od",
		"OpenTo": "Registrace otevřena do",
		"Price": "Cena za lekci",
		"IsExternal": "Externí kroužek",
		"Equipments": "Pracovní pomůcky",
		"Photo": "Fotka",
		"LoadPhoto": "Načíst fotku",
		"EditSuccess": "Kroužek byl úspěšně upraven.",
		"AddSuccess": "Kroužek byl úspěšně vytvořen.",
		"ConfirmDelete": "Opravdu chcete smazat vybraný kroužek?",
		"DeleteSuccess": "Kroužek byl úspěšně smazán.",
		"Group": "Filtr na skupiny",
		"GroupCol": "Skupina",
		"Lessons": "Termíny lekcí",
		"LessonCount": "Počet lekcí / školní rok",
		"CreateLessons": "Vygenerovat",
		"Register": "Registrace",
		"DoRegister": "Registrovat",
		"All": "Bez omezení",
		"Free": "Zadarmo",
		"OpenRegInfo": "Registrace bude otevřena za {ts}.",
		"Student": "Student",
		"NoStudentsInfo": "K vašemu účtu není přidělen žádný student.",
		"RegisterSuccess": "Student byl úspěšně zaregistrován.",
		"StudentAlreadyRegistered": "Student {student} je již registrován.",
		"CapacityReached": "Kapacita kroužku byla naplněna. Další registrace není možná.",
		"NotOpenYet": "Kroužek ještě není otevřen k registraci. Zkuste to prosím později.",
		"InvalidGroup": "Student je ve skupině, která není u tohoto kroužku povolena.",
		"Email": "E-mail",
		"NoteExternal": "This is an external course and is not included in the All-Inclusive option.  All-inclusive students must pay the course fees to the instructor directly.",
		"PaymentInfo": "Platební informace",
		"InvalidCapacity": "Kapacita kroužku nemůže být menší, než počet již zaregistrovaných studentů.",
		"IsDisabled": "Aktivní",
		"IsHidden": "Skryté",
		"DisabledCourse": "Kroužek není aktivní.",
		"LessonList": "Seznam lekcí",
		"Order": "Pořadí",
		"Notifications": "Notifikace",
		"CourseDisabled": "Registrace nelze vytvořit, protože kroužek je neaktivní."
	},
	"Register": {
		"Title": "Registrace",
		"Course": "Kroužek",
		"Student": "Student",
		"From": "Od",
		"To": "Do",
		"Alternate": "Náhradník",
		"ConfirmDelete": "Opravdu chcete smazat vybranou registraci?",
		"ConfirmForceDelete": "Pro vybranou registraci již existují závislá data (transakce nebo docházka). Zvažte, zda není vhodnější provést ukončení registrace. V případě smazání registrace budou smazána i závislá data. Opravdu to chcete udělat?",
		"ConfirmClose": "Opravdu chcete ukončit vybranou registraci?",
		"RegisterSuccess": "Registrace byla úspěšně vytvořena.",
		"DuplicateRecord": "Registrace pro vybraný kroužek a studenta již existuje.",
		"UpdAttBtn": "Aktualizovat docházku",
		"UpdAttSuccess": "Docházka byla aktualizována.",
		"Group": "Skupina",
		"ToDate": "Datum ukončení registrace",
		"ToDateTip": "Datum prvního dne, kdy registrace neplatí.",
		"CloseSuccess": "Ukončení registrace bylo nastaveno."
	},
	"Students": {
		"Title": "Studenti",
		"Name": "Jméno",
		"User": "Uživatelský účet",
		"Group": "Skupina",
		"Modal": {
			"Title": "Student"
		},
		"Free": "All inclusive",
		"Balance": "Stav konta",
		"PaymentId": "Variabilní symbol",
		"IsDiner": "Strávník",
		"ImportDiners": "Import strávníků",
		"All": "Vše",
		"ConfirmDelete": "Opravdu chcete smazat vybraného studenta?",
		"ConfirmForceDelete": "Na vybraného studenta jsou již navázána závislá data (strávník, registrace, docházka nebo transakce). Opravdu jej chcete smazat? V případě potvrzení smazání budou závislá data také smazána, strávník odmapován.",
		"DeletedSuccessfuly": "Student byl úspěšně smazán.",
		"From": "Školní rok od",
		"To": "Školní rok do"
	},
	"Err": {
		"System": "Neočekávaná chyba. Kontaktujte prosím správce systému.",
		"UserIsTeacher": "Uživatel je již nastaven jako učitel.",
		"InvalidInput": "Neplatné vstupní parametry."
	},
	"UserBilling": {
		"Student": "Student",
		"Balance": "Stav účtu",
		"Date": "Datum",
		"Type": "Typ",
		"Note": "Poznámka",
		"Amount": "Množství",
		"Course": "Kroužek",
		"Payment": "Platba",
		"Pay": "Zaplatit",
		"Modal": {
			"Title": "Platební údaje",
			"Number": "Číslo účtu",
			"Symbol": "Variabilní symbol",
			"Amount": "Částka",
			"Ss": "Specifický symbol"
		}
	},
	"TransactionType": {
		"0": "Platba",
		"1": "Vklad",
		"2": "Storno",
		"3": "Vratka"
	},
	"Week": {
		"Activity": "Aktivita",
		"Time": "Čas",
		"Students": "Studenti",
		"Classroom": "Kde",
		"Teacher": "Učitel",
		"Title": "Týdenní přehled",
		"NoGroup": "Bez skupiny"
	},
	"Attendance": {
		"Student": "Student",
		"Presence": "Přítomnost",
		"Lessons": "Lekce",
		"InvalidPermission": "Nedostatečné oprávnění. Nastavovat docházku může pouze učitel kroužku nebo administrátor.",
		"SumNonInclusive": "Součet lekcí registrovaných ne-all-inclusive studentů",
		"SumInclusive": "Součet lekcí registrovaných all-inclusive studentů",
		"RegisterCount": "Počet registrovaných studentů"
	},
	"Payments": {
		"Date": "Datum",
		"Type": "Typ",
		"Student": "Student",
		"Course": "Kroužek",
		"Note": "Poznámka",
		"Amount": "Částka",
		"DeleteConfirm": "Opravdu chcete smazat vybranou transakci?",
		"DeleteSuccess": "Transakce byla úspěšně smazána."
	},
	"Overview": {
		"Title": "Přehled aktivit",
		"MenuLink": "Přehled",
		"Time": "Čas",
		"Course": "Aktivita",
		"Student": "Student",
		"Register": "Registrovat další",
		"NoTime": "není",
		"Alternate": "náhradník"
	},
	"LectorEmail": {
		"Title": "Zaslat e-mail"
	},
	"AdminCourses": {
		"Title": "Kroužky"
	},
	"Teacher": {
		"Title": "Učitel",
		"Name": "Jméno",
		"Photo": "Fotografie",
		"LoadPhoto": "Načíst foto",
		"EditSuccess": "Učitel byl úspěšně upraven.",
		"AddSuccess": "Učitel byl úspěšně vytvořen.",
		"DeleteSuccess": "Učitel byl úspěšně smazán.",
		"ConfirmDelete": "Opravdu chcete smazat vybraného učitele?",
		"Email": "E-mail",
		"User": "Uživatel",
		"Phone": "Telefon",
		"Description": "Popis",
		"From": "Školní rok od",
		"To": "Školní rok do"
	},
	"AdminBilling": {
		"Title": "Vyúčtování",
		"MenuLink": "Vyúčtování"
	},
	"JobInfo": {
		"All": "Všichni studenti"
	},
	"General": {
		"SchoolYear": "Školní rok"
	},
	"CourseNotification": {
		"Title": "Notifikace",
		"Text": "{interval} dní před {type}",
		"Sent": "Odesláno"
	},
	"CourseNotificationType": {
		"R": "registrací na kroužek",
		"L": "začátkem kroužku"
	},
	"ClassMeetings": {
		"Title": "Třídní schůzky",
		"DeletedSuccess": "Třídní schůzka byla smazána.",
		"DeleteConfirm": "Opravdu chcete smazat třídní zchůzku?"
	},
	"ClassMeeting": {
		"Title": "Třídní schůzka",
		"Name": "Název",
		"Description": "Popis",
		"Teacher": "Učitel",
		"RegFrom": "Registrace od",
		"RegTo": "Registrace do",
		"IsDisabled": "Neaktivní",
		"IsHidden": "Skryté",
		"Group": "Skupiny",
		"Appointments": "Termíny",
		"Register": "Registrovat",
		"Unregister": "Zrušit registraci"
	},
	"ClassMeetingNotificationType": {
		"R": "registrací na třídní schůzky",
		"L": "začátkem třídních schůzek"
	},
	"AppointmentControl": {
		"Reserved": "Rezervováno"
	},
	"RegMeeting": {
		"Title": "Registrace termínu třídní schůzky",
		"Appointment": "Termín schůzky",
		"Student": "Student",
		"RegSuccess": "Termín byl zaregistrován.",
		"MeetingDisabled": "Třídní schůzka není aktivní.",
		"MeetingRegNotOpen": "Momentálně není povoleno se (od)registrovat.",
		"AppointmentPassed": "Termín schůzky již proběhl.",
		"AppointmentEngaged": "Termín nelze zaregistrovat, protože je již obsazen.",
		"StudentRegistred": "Termín nelze zaregistrovat, protože schůzka ohledně vybraného studenta je již registrována.",
		"AllowInterval": "Registrace termínu je povolena pouze v termínu",
		"IntervalFrom": " od ",
		"IntervalTo": " do ",
		"Reserved": "Rezervováno",
		"UnregConfirm": "Opravdu chcete zrušit rezervaci?",
		"UnregSuccess": "Rezervace byla zrušena.",
		"InvalidGroup": "Termín nelze zaregistrovat, protože student nepatří do žádné z povolených skupin."
	},
	"Appointments": {
		"Link": "Termíny schůzek",
		"Title": "Termíny schůzek",
		"Time": "Čas",
		"Meeting": "Schůzka",
		"Student": "Student",
		"User": "Rodič",
		"Cancel": "Zrušit rezervaci",
		"CancelConfirm": "Opravdu chcete zrušit rezervaci?"
	},
	"ImgControl": {
		"Label": "Fotka",
		"Load": "Načíst fotku"
	}
};

export default cs;