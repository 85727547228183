import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ApiService } from '../../../services/ApiService';
import { toStandardDmySpan } from '../../../utils/date';
import { TT_PAYMENT, TT_REFUND } from '../../../constants/transactionType';
import Modal from '../../../components/Modal';

import './billing.scss';


class Billing extends React.Component {
    static propTypes = {
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.initPaymentModal = {
            open: false,
            paymentId: '',
            paymentAmount: '',
            name: ''
        }
        this.state = {
            students: [],
            transactions: [],
            selectedStudent: '',
            paymentModal: this.initPaymentModal,
            settings: {}
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadStudents();
        this.loadTransactions();
        this.loadSettings();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Header.Billing');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadStudents() {
        this.api.getMyStudents(true)
            .then(response => {
                if (this.mounted) {
                    let students = response.data || [];
                    this.setState({ students });
                    // console.log('Course.loadStudents', students);
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadTransactions() {
        this.api.getMyTransactions()
            .then(response => {
                if (this.mounted) {
                    let transactions = response.data || [];
                    this.setState({ transactions });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadSettings() {
        this.api.getSettings()
            .then(response => {
                if (this.mounted) {
                    let settings = response.data || {};
                    if (settings.accountNumber) {
                        let parts = settings.accountNumber.split('/');
                        let parts2 = parts[0].split('-');
                        settings.accountPrefix = parts2[0];
                        settings.accountNumberPart = parts2[1];
                        settings.bankCode = parts[1];
                    }
                    this.setState({ settings });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    select(id) {
        let { selectedStudent } = this.state;

        selectedStudent = id === selectedStudent ? '' : id;
        this.setState({ selectedStudent });
    }

    getTransactionList(transactions, selectedStudent) {
        let result = [...transactions];
        if (selectedStudent) {
            result = result.filter(o => o.studentId === selectedStudent);
        }
        return result;
    }

    showPayment(paymentAmount, paymentId, name) {
        this.setState({ paymentModal: { open: true, paymentId, paymentAmount, name } });
    }

    closeModal() {
        this.setState({ paymentModal: { ...this.initPaymentModal, open: false } });
    }

    render() {
        let { students, transactions, selectedStudent, paymentModal, settings } = this.state;
        let { translate } = this.context;

        let transactionList = this.getTransactionList(transactions, selectedStudent);
        let showQr = true;

        return (
            <div className="user-page-container">
                <div className="structure-list">
                    <div className="header">
                        <div className="pc-style">
                            <div className="line">
                                <div className="column fill">{translate('UserBilling.Student')}</div>
                                <div className="column f-120 ta-r">{translate('UserBilling.Balance')}</div>
                                <div className="column f-120 ta-c">{translate('UserBilling.Payment')}</div>
                            </div>
                        </div>
                        <div className="mob-style">
                            <div className="line">
                                <div className="column fill">{translate('UserBilling.Student')}</div>
                            </div>
                            <div className="line">
                                <div className="column f-120">{translate('UserBilling.Payment')}</div>
                                <div className="column fill"></div>
                                <div className="column f-120 ta-r">{translate('UserBilling.Balance')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="body">
                        {students.map(o => {
                            let isSelected = selectedStudent === o.id;
                            let shouldPay = o.balance < 0;

                            return (
                                <div
                                    className={classnames('item', { selected: isSelected })}
                                    key={o.id}
                                    onClick={() => this.select(o.id)}
                                >
                                    <div className="pc-style">
                                        <div className="line">
                                            <div className="value fill">{o.name}</div>
                                            <div className="value f-120 ta-r">{o.balance} {translate('Units.Money')}</div>
                                            <div
                                                className={classnames('value f-120 ta-c', { 'grey-link': !shouldPay, 'red-link': shouldPay })}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    this.showPayment(-o.balance, o.paymentId, o.name);
                                                }}
                                            >{translate('UserBilling.Pay')}</div>
                                        </div>
                                    </div>
                                    <div className="mob-style">
                                        <div className="line">
                                            <div className="value fill">{o.name}</div>
                                        </div>
                                        <div className="line">
                                            <div
                                                className={classnames('value f-120', { 'grey-link': !shouldPay, 'red-link': shouldPay })}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    this.showPayment(-o.balance, o.paymentId, o.name);
                                                }}
                                            >{translate('UserBilling.Pay')}</div>
                                            <div className="value fill"></div>
                                            <div className="value f-120 ta-r">{o.balance} {translate('Units.Money')}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="structure-list">
                    <div className="header">
                        <div className="pc-style">
                            <div className="line">
                                <div className="column f-100 ta-r">{translate('UserBilling.Date')}</div>
                                <div className="column f-100">{translate('UserBilling.Type')}</div>
                                <div className="column fill w-20pr">{translate('UserBilling.Student')}</div>
                                <div className="column fill w-20pr">{translate('UserBilling.Course')}</div>
                                <div className="column fill w-20pr">{translate('UserBilling.Note')}</div>
                                <div className="column f-100 ta-r">{translate('UserBilling.Amount')}</div>
                            </div>
                        </div>
                        <div className="mob-style">
                            <div className="line">
                                <div className="column f-100">{translate('UserBilling.Date')}</div>
                                <div className="column fill"></div>
                                <div className="column f-100 ta-r">{translate('UserBilling.Amount')}</div>
                            </div>
                            <div className="line">
                                <div className="column f-100">{translate('UserBilling.Type')}</div>
                                <div className="column fill w-60pr">{translate('UserBilling.Student')}</div>
                            </div>
                            <div className="line">
                                <div className="column fill w-50pr">{translate('UserBilling.Course')}</div>
                                <div className="column fill w-50pr">{translate('UserBilling.Note')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="body">
                        {transactionList.map(o => {
                            let coef = o.type === TT_PAYMENT || o.type === TT_REFUND ? -1 : 1;
                            return (
                                <div
                                    className="item"
                                    key={o.id}
                                    onClick={() => { }}
                                >
                                    <div className="pc-style">
                                        <div className="line">
                                            <div className="value f-100 ta-r">{toStandardDmySpan(o.date)}</div>
                                            <div className="value f-100">{translate(`TransactionType.${o.type}`)}</div>
                                            <div className="value fill w-20pr">{o.studentName}</div>
                                            <div className="value fill w-20pr">{o.courseName}</div>
                                            <div className="value fill w-20pr">{o.note}</div>
                                            <div className="value f-100 ta-r">{o.amount * coef} {this.context.translate('Units.Money')}</div>
                                        </div>
                                    </div>
                                    <div className="mob-style">
                                        <div className="line">
                                            <div className="value f-100">{toStandardDmySpan(o.date)}</div>
                                            <div className="column fill"></div>
                                            <div className="value f-100 ta-r">{o.amount * coef} {this.context.translate('Units.Money')}</div>
                                        </div>
                                        <div className="line">
                                            <div className="value f-100">{translate(`TransactionType.${o.type}`)}</div>
                                            <div className="value fill w-20pr">{o.studentName}</div>
                                        </div>
                                        <div className="line">
                                            <div className="value fill w-20pr">{o.courseName}</div>
                                        </div>
                                        <div className="line">
                                            <div className="value fill w-20pr">{o.note}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Modal
                    modalClass="payment-info-modal"
                    onClose={() => this.closeModal()}
                    onSubmit={() => this.closeModal()}
                    title={this.context.translate('UserBilling.Modal.Title')}
                    show={paymentModal.open}
                    closeOnClick={true}
                >
                    <div className="payment-info-modal-content">
                        <div className="payment-info">
                            <div className="text-part">
                                <div className="payment-row">
                                    <div className="label">{this.context.translate('UserBilling.Modal.Number')}</div>
                                    <div className="value">{settings.accountNumber}</div>
                                </div>
                                <div className="payment-row">
                                    <div className="label">{this.context.translate('UserBilling.Modal.Symbol')}</div>
                                    <div className="value">{paymentModal.paymentId}</div>
                                </div>
                                <div className="payment-row">
                                    <div className="label">{this.context.translate('UserBilling.Modal.Ss')}</div>
                                    <div className="value">{settings.specificSymbol}</div>
                                </div>
                                <div className="payment-row">
                                    <div className="label">{this.context.translate('UserBilling.Modal.Amount')}</div>
                                    <div className="value">{paymentModal.paymentAmount} {this.context.translate('Units.Money')}</div>
                                </div>
                            </div>
                            {showQr &&
                                <div className="qr-part">
                                    <img
                                        className="qr-img"
                                        alt="qr"
                                        src={`http://api.paylibo.com/paylibo/generator/czech/image?accountPrefix=${settings.accountPrefix}&accountNumber=${settings.accountNumberPart}&bankCode=${settings.bankCode}&amount=${paymentModal.paymentAmount}&currency=CZK&vs=${paymentModal.paymentId}&ss=${settings.specificSymbol}&message=${paymentModal.name}&branding=false`}
                                    />
                                </div>
                            }
                        </div>
                        <div className="buttons-row">
                            <button onClick={() => this.closeModal()}>{this.context.translate('Btn.Close')}</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Billing);