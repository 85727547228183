import classnames from 'classnames';
import React, { useState } from 'react';
import * as toastr from 'toastr';
import { useEffect } from 'react';
import { useContext } from 'react';
import { push } from "connected-react-router";
import { connect } from 'react-redux';

import { AppContext } from '../../AppContext';
import { ApiService } from '../../../services/ApiService';
import { ssGetBool, ssSet } from '../../../utils/storage';

import './menu.scss';
import moment from 'moment';


const CollapsedKey = 'MeetingContainer.Collapsed';

const MeetingContainer = ({ push }) => {

    const api = new ApiService();
    const { translate } = useContext(AppContext);

    let [collapsed, setCollapsed] = useState(ssGetBool(CollapsedKey, "true"));
    let [meetings, setMeetings] = useState([]);

    useEffect(() => {
        loadMeetings();
    }, []);

    useEffect(() => {
        ssSet(CollapsedKey, collapsed);
    }, [collapsed]);

    const loadMeetings = async () => {
        try {
            let response = await api.getClassMeetingsMenu();
            let data = response.data || [];
            setMeetings(data);
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    const onHeaderClick = e => {
        setCollapsed(!collapsed);
    }

    // const getItems = courses => {
    //     let result = (courses || []).filter(o => !o.isHidden);
    //     arraySort(result, 'order', 'name');
    //     return result;
    // }


    // let items = getItems(courses);

    return (
        <div className={classnames('menu-container', { collapsed: collapsed })}>
            <div
                className="mc-header"
                onClick={onHeaderClick}
            >
                <div className="title">{translate('Menu.Meetings')}</div>
                <div
                    className="btn"
                ><i className={classnames('fa', { 'fa-chevron-up': !collapsed, 'fa-chevron-down': collapsed })} aria-hidden="true"></i></div>
            </div>
            <div className="mc-content">
                <div className="structure-list">
                    <div className="header">
                        <div className="pc-style">
                            <div className="line">
                                <div className="column fill w-50pr">{translate('ClassMeeting.Name')}</div>
                                <div className="column fill w-35pr">{translate('ClassMeeting.Teacher')}</div>
                                <div className="column fill w-15pr">{translate('ClassMeeting.Appointments')}</div>
                            </div>
                        </div>
                        <div className="mob-style">
                            <div className="line">
                                <div className="column fill w-100pr">{translate('ClassMeeting.Name')}</div>
                            </div>
                            <div className="line">
                                <div className="column fill w-100pr">{translate('ClassMeeting.Teacher')}</div>
                            </div>
                            <div className="line">
                                <div className="column fill w-100pr">{translate('ClassMeeting.Appointments')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="body">
                        {meetings.map(o => {
                            let appointments = `${moment(o.appointmentFrom).format('D. M.')}  -  ${moment(o.appointmentTo).format('D. M.')}`;
                            return (
                                <div
                                    className="item"
                                    key={o.id}
                                    onClick={() => push(`/meeting/${o.id}`)}
                                >
                                    <div className="pc-style">
                                        <div className="line">
                                            <div className="value fill w-50pr">{o.name}</div>
                                            <div className="value fill w-35pr">{o.teacher}</div>
                                            <div className="value fill w-15pr">{appointments}</div>
                                        </div>
                                    </div>
                                    <div className="mob-style">
                                        <div className="line">
                                            <div className="value fill w-100pr">{o.name}</div>
                                        </div>
                                        <div className="line">
                                            <div className="value fill w-100pr">{o.teacher}</div>
                                        </div>
                                        <div className="line">
                                            <div className="value fill w-100pr">{appointments}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(state => ({}), { push })(MeetingContainer);