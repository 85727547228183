import * as React from 'react';
import classnames from 'classnames';

import './smallBtn.scss';


export const SmallBtn = ({ title, ico, disabled, onClick, className }) => {
    return (
        <div
            className={classnames('small-btn', className, { disabled })}
            title={title}
            onClick={e => {
                if (!disabled && onClick) {
                    onClick(e);
                }
            }}
        >
            <i className={ico}></i>
        </div>
    );
}