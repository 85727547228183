import classnames from 'classnames';
import React, { useState } from 'react';
import * as toastr from 'toastr';
import { useEffect } from 'react';
import { useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { push } from "connected-react-router";
import { connect } from 'react-redux';

import { AppContext } from '../../AppContext';
import { ApiService } from '../../../services/ApiService';
import { arraySort } from '../../../utils/sort';
import { toStandardDmySpan } from '../../../utils/date';
import { getCourseGroupList } from '../../../utils/course';

import './menu.scss';
import { ssGetBool, ssSet } from '../../../utils/storage';


const CollapsedKey = 'CourseContainer.Collapsed';

const CourseContainer = ({ push }) => {

    const api = new ApiService();
    const { translate } = useContext(AppContext);

    let [collapsed, setCollapsed] = useState(ssGetBool(CollapsedKey, "true"));
    let [courses, setCourses] = useState([]);

    useEffect(() => {
        loadCourses();
    }, []);

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [courses]);

    useEffect(() => {
        ssSet(CollapsedKey, collapsed);
    }, [collapsed]);

    const loadCourses = async () => {
        try {
            let response = await api.getCoursePreview();
            let data = response.data || [];
            arraySort(data, 'name');
            setCourses(data);
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    const onHeaderClick = e => {
        setCollapsed(!collapsed);
    }

    const getItems = courses => {
        let result = (courses || []).filter(o => !o.isHidden);
        arraySort(result, 'order', 'name');
        return result;
    }

    const getCourseCapacity = (course, translate) => {
        if (!course) {
            return '';
        }

        let capacity = `${course.count || '0'} / ${course.capacity === null ? translate('Course.All') : course.capacity}`;
        if (course.alternate > 0) {
            capacity += ` + ${course.alternate}`
        }
        return capacity;
    }

    let items = getItems(courses);
    let capacityTooltip = `x / y + z
x - ${translate('Menu.Ttx')}
y - ${translate('Menu.Tty')}
z - ${translate('Menu.Ttz')}`;

    return (
        <div className={classnames('menu-container', { collapsed: collapsed })}>
            <div
                className="mc-header"
                onClick={onHeaderClick}
            >
                <div className="title">{translate('Menu.Courses')}</div>
                <div
                    className="btn"
                ><i className={classnames('fa', { 'fa-chevron-up': !collapsed, 'fa-chevron-down': collapsed })} aria-hidden="true"></i></div>
            </div>
            <div className="mc-content">
                <div className="structure-list">
                    <div className="header">
                        <div className="pc-style">
                            <div className="line">
                                <div className="column fill w-30pr">{translate('Course.Name')}</div>
                                <div className="column f-100 ta-c">{translate('Menu.Begin')}</div>
                                <div className="column fill w-20pr">{translate('Menu.Teacher')}</div>
                                <div className="column f-100">{translate('Menu.Price')}</div>
                                <div
                                    className="column f-100"
                                    data-tip={capacityTooltip}
                                ><div>{translate('Menu.Capacity')} <i className="fa fa-info-circle" /></div></div>
                            </div>
                        </div>
                        <div className="mob-style">
                            <div className="line">
                                <div className="column fill w-60pr">{translate('Course.Name')}</div>
                                <div className="column fill w-40pr ta-r">{translate('Menu.Price')}</div>
                            </div>
                            <div className="line">
                                <div className="column fill w-60pr">{translate('Menu.Teacher')}</div>
                                <div className="column fill w-40pr ta-r">{translate('Menu.Begin')}</div>
                            </div>
                            <div className="line">
                                <div className="column fill w-60pr">{translate('Menu.Classroom')}</div>
                                <div
                                    className="column fill w-40pr ta-r"
                                    data-tip={capacityTooltip}
                                ><div>{translate('Menu.Capacity')} <i className="fa fa-info-circle" /></div></div>
                            </div>
                        </div>
                    </div>
                    <div className="body">
                        {items.map(o => {
                            let capacity = getCourseCapacity(o, translate);
                            let begin = toStandardDmySpan(((arraySort((o || {}).lessons || [], 'time'))[0] || {}).time);
                            let price = o.price === null || o.price === undefined ? '' : `${o.price} ${translate('Units.Money')}`;

                            return (
                                <div
                                    className="item"
                                    key={o.id}
                                    onClick={() => push(`/course/${o.id}`)}
                                >
                                    <div className="pc-style">
                                        <div className="line">
                                            <div className="value fill w-30pr course-name-cell-value">
                                                <div className="course-name-cell-value-name">{o.name}</div>
                                                <div className="course-name-cell-value-groups">{getCourseGroupList(o)}</div>
                                            </div>
                                            <div className="value f-100 ta-r pd-r-10">{begin}</div>
                                            <div className="value fill w-20pr">{o.teacherName}</div>
                                            <div className="value f-100 ta-r">{price}</div>
                                            <div
                                                className="value f-100 ta-c"
                                                data-tip={capacityTooltip}
                                            >{capacity}</div>
                                        </div>
                                    </div>
                                    <div className="mob-style">
                                        <div className="line">
                                            <div className="value fill w-60pr course-name-cell-value-name">{o.name}</div>
                                            <div className="value fill w-40pr ta-r">{price}</div>
                                        </div>
                                        <div className="line">
                                            <div className="value fill w-60pr">{o.teacherName}</div>
                                            <div className="value fill w-40pr ta-r">{begin}</div>
                                        </div>
                                        <div className="line">
                                            <div className="value fill w-60pr">{o.classroomName}</div>
                                            <div className="value fill w-40pr ta-r">{capacity}</div>
                                        </div>
                                        <div className="line course-name-cell-value-groups">
                                            <div className="value fill course-name-cell-value-name">{getCourseGroupList(o)}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <ReactTooltip
                    className="multiline imsp-tooltip"
                    place="bottom"
                    type="custom"
                />
            </div>
        </div>
    );
}

export default connect(state => ({}), { push })(CourseContainer);