import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from "connected-react-router";
import Dropdown from 'react-bootstrap/Dropdown';

import Logo from './components/Logo';
import { isAdmin, isViewer, isTeacher } from '../../utils/role';
import { Constants } from '../../helpers/Constants';
import { setLanguage } from '../../redux/actions/localizeAction'
import Modal from '../Modal';

import './header.scss';


class Header extends React.Component {
    static propTypes = {
        language: PropTypes.string.isRequired,
        profile: PropTypes.object,
        path: PropTypes.string,
        user: PropTypes.object,
        login: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
        setLanguage: PropTypes.func.isRequired,
        title: PropTypes.string
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            about: false
        }
    }

    render() {
        let { title } = this.props;
        
        let isUser = this.props.profile.loaded;
        let showAdmin = isAdmin(this.props.profile);
        let showViewer = isViewer(this.props.profile);
        let showLector = isTeacher(this.props.profile);
        let lang = this.props.language === 'cs' ? 'EN' : 'CS';
        let langChange = this.props.language === 'cs' ? 'en' : 'cs';
        title = (title || '').toUpperCase();
        // console.log('Header.render', { path: this.props.path, title });
        return (
            <div className="imsp-header">
                <div className="imsp-header-content">
                    <Logo
                        onClick={() => this.props.push("/")}
                    />
                    <a
                        className="signpost"
                        href={Constants.stsAuthority}
                    >
                        <div className="signpost-img"></div>
                    </a>
                    <div className="header-title">
                        <div className="title-text">{title}</div>
                    </div>
                    <div className="content">
                        {!isUser &&
                            <>
                                <div
                                    className="header-link"
                                    onClick={() => this.props.login()}
                                >{this.context.translate('Header.Login')}</div>
                                {/* <a
                                className="header-link"
                                href={`${Constants.stsAuthority}/Account/Register`}
                                onClick={() => { }}
                            >{this.context.translate('Header.Register')}</a> */}
                            </>
                        }
                        {isUser &&
                            <Dropdown alignRight>
                                <Dropdown.Toggle
                                    variant="custom"
                                    id="user-menu-dropdown"
                                    className="header-link"
                                >
                                    <span className="account-ico"><i className="fa fa-user" aria-hidden="true"></i></span>
                                    <span className="account-name">{this.props.profile.data.name}</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.setState({ about: true })}>{this.context.translate('Header.About.Title')}</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => this.props.push('/profile')}>{this.context.translate('Header.Profile')}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.props.push('/overview')}>{this.context.translate('Overview.MenuLink')}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.props.push('/menu')}>{this.context.translate('Menu.MenuLink')}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.props.push('/billing')}>{this.context.translate('Header.Billing')}</Dropdown.Item>
                                    {/* <Dropdown.Item onClick={() => this.props.push('/history')}>{this.context.translate('Header.Payments')}</Dropdown.Item> */}
                                    {showLector &&
                                        <>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={() => this.props.push('/lector/attendance')}>{this.context.translate('Header.LectorAttendance')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/lector/email')}>{this.context.translate('Header.LectorEmail')}</Dropdown.Item>
                                        </>
                                    }
                                    {showViewer &&
                                        <>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={() => this.props.push('/view/week')}>{this.context.translate('Header.ReportWeek')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/view/attendance')}>{this.context.translate('Header.AttendanceView')}</Dropdown.Item>
                                        </>
                                    }
                                    {showAdmin &&
                                        <>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={() => this.props.push('/admin/account')}>{this.context.translate('Header.Accounts')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/admin/student')}>{this.context.translate('Header.Students')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/admin/group')}>{this.context.translate('Header.Groups')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/admin/teacher')}>{this.context.translate('Header.Teachers')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/admin/classroom')}>{this.context.translate('Header.Classrooms')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/admin/course')}>{this.context.translate('AdminCourses.Title')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/admin/register')}>{this.context.translate('Header.Registrations')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/admin/meeting')}>{this.context.translate('ClassMeetings.Title')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/admin/appointment')}>{this.context.translate('Appointments.Link')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/admin/payment')}>{this.context.translate('Header.Payments')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/admin/billing')}>{this.context.translate('AdminBilling.MenuLink')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.push('/admin/settings')}>{this.context.translate('Header.Settings')}</Dropdown.Item>
                                        </>
                                    }
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => this.props.logout()}>{this.context.translate('Header.Logout')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        <div
                            className="header-link"
                            onClick={() => this.props.setLanguage(langChange)}
                        >{lang}</div>
                    </div>

                    {this.state.about &&
                        <Modal
                            modalClass="about-modal"
                            onClose={() => this.setState({ about: false })}
                            onSubmit={() => this.setState({ about: false })}
                            title={this.context.translate("Header.About.Title")}
                            show={true}
                            closeOnClick={true}
                        >
                            <div className="list">
                                <div className="info">
                                    <div className="label">{this.context.translate('Header.About.Name')}</div>
                                    <div className="value">{this.context.translate('Header.About.App')}</div>
                                </div>
                                <div className="info">
                                    <div className="label">{this.context.translate('Header.About.Version')}</div>
                                    <div className="value">{process.env.REACT_APP_VERSION}</div>
                                </div>
                                <div className="delimiter"></div>
                                <div className="info">
                                    <div className="label">{this.context.translate('Header.About.By')}</div>
                                    <div className="value"><a target="_blank" rel="noopener noreferrer" href="https://mpenkala.cz">Michal Penkala</a></div>
                                </div>
                                {/* <div className="info">
                                    <div className="value">{this.context.translate('Header.About.Copyright')}</div>
                                </div> */}
                                <div className="buttons">
                                    <button
                                        className="btn btn-light"
                                        onClick={() => this.setState({ about: false })}
                                    >{this.context.translate('Btn.Close')}</button>
                                </div>
                            </div>
                        </Modal>
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        profile: state.profile,
        path: state.router.location.pathname,
        language: state.localize.language
    }),
    {
        push,
        setLanguage
    }
)(Header);