import * as toastr from 'toastr';
import React, { useContext, useState, useEffect } from 'react';
import Select from 'react-select';
import RichTextEditor from 'react-rte';

import schoolYear from '../../../utils/schoolYear';
import Modal from '../../../components/Modal';
import { AppContext } from '../../AppContext';
import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import NotificationsControl from "../../../components/NotificationsControl";
import DateTimeControl from "../../../components/DateTimeControl/DateTimeControl";
import AppointmentControl from "./AppointmentControl";
import { getRteValue, toolbarConfig } from '../../../utils/rte';
import ImgControl from '../../../components/imgControl/ImgControl';
import { toFormData } from '../../../utils/form';


const ClassMeetingModal = ({ id, onSubmit, onCancel }) => {

    let { translate } = useContext(AppContext);

    const getYesNo = value => ({ value, label: translate(`Codelist.${value ? 'Yes' : 'No'}`) })

    let [name, setName] = useState('');
    // let [description, setDescription] = useState('');
    let [description, setDescription] = useState(RichTextEditor.createEmptyValue());
    let [year, setYear] = useState(schoolYear().year());
    let [teacher, setTeacher] = useState(null);
    let [teachers, setTeachers] = useState([]);
    let [classroom, setClassroom] = useState(null);
    let [classrooms, setClassrooms] = useState([]);
    let [regFrom, setRegFrom] = useState(null);
    let [regTo, setRegTo] = useState(null);
    let [isDisabled, setIsDisabled] = useState(getYesNo(false));
    let [isHidden, setIsHidden] = useState(getYesNo(false));
    let [notifications, setNotifications] = useState([]);
    let [groups, setGroups] = useState([]);
    let [groupOptions, setGroupOptions] = useState([]);
    let [appointments, setAppointments] = useState([]);
    let [img, setImg] = useState(null);

    let api = new ApiService();

    useEffect(() => {
        loadTeachers();
        loadClassrooms();
        loadGroups();
        if (id && id !== 'new') {
            loadMeeting(id);
        }
    }, []);

    const loadTeachers = async () => {
        try {
            let response = await api.getTeachers();
            let data = (response.data || []).map(o => ({ value: o.id, label: o.name }));
            arraySort(data, 'label');
            setTeachers(data);
        } catch (error) {
            console.log(error);
        }
    }

    const loadClassrooms = async () => {
        try {
            let response = await api.getClassrooms();
            let data = (response.data || []).map(o => ({ value: o.id, label: o.name }));
            arraySort(data, 'label');
            setClassrooms(data);
        } catch (error) {
            console.log(error);
        }
    }

    const loadGroups = async () => {
        try {
            let response = await api.getGroups();
            let data = response.data || [];
            arraySort(data, 'monthOrdersOrder', 'name');
            data = data.map(o => ({ value: o.id, label: o.name }));
            setGroupOptions(data);
        } catch (error) {
            console.log(error);
        }
    }

    const loadMeeting = async id => {
        try {
            let response = await api.getClassMeeting(id);
            if (response.data) {
                let item = response.data;
                setName(item.name);
                setDescription(getRteValue(item.description));
                setYear(item.year);
                setGroups(item.groups ? item.groups.map(o => ({ value: o.id, label: o.name })) : []);
                setTeacher(item.teacher ? { value: item.teacher.id, label: item.teacher.name } : null);
                setClassroom(item.classroom ? { value: item.classroom.id, label: item.classroom.name } : null);
                setAppointments(item.appointments || []);
                setRegFrom(item.regFrom);
                setRegTo(item.regTo);
                setIsDisabled(getYesNo(item.isDisabled));
                setIsHidden(getYesNo(item.isHidden));
                setNotifications(item.notifications);
                setImg({ id: item.imgId, file: null });
            } else {
                if (onCancel) onCancel();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmitClick = async () => {
        let data = {
            name, year, regFrom, regTo, appointments,
            notifications,
            isDisabled: isDisabled.value,
            isHidden: isHidden.value,
            teacher: teacher ? teacher.value : null,
            classroom: classroom ? classroom.value : null,
            groups: groups ? groups.map(o => o.value) : null,
            description: description.toString('html'),
            imgId: img && img.id,
            img: img && img.file
        };
        try {
            let response = id === 'new'
                ? await api.addClassMeeting(data)
                : await api.updateClassMeeting(id, data);
            if (onSubmit) onSubmit();
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    const getSchyOptions = year => {
        let curYear = schoolYear().year();
        if (year < curYear) {
            return [
                { value: year, label: schoolYear(year).yearText() }
            ];
        }

        let result = [
            { value: curYear, label: schoolYear(curYear).yearText() },
            { value: curYear + 1, label: schoolYear(curYear + 1).yearText() }
        ];
        return result;
    }

    let schyOptions = getSchyOptions(year);
    let yesnoOptions = [
        { value: false, label: translate('Codelist.No') },
        { value: true, label: translate('Codelist.Yes') }
    ]

    // console.log('regFrom', regFrom);

    return (
        <Modal
            modalClass="class-meeting-modal"
            onClose={onCancel}
            onSubmit={onSubmit}
            title={translate('ClassMeeting.Title')}
            closeOnClick={false}
            showCloseButton
            show
        >
            <div>
                <fieldset>
                    <div className="form-group">
                        <label htmlFor="name">{translate('ClassMeeting.Name')}</label>
                        <input
                            className="form-control"
                            name="name"
                            type="text"
                            autoComplete="off"
                            autoFocus
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">{translate('ClassMeeting.Description')}</label>
                        <RichTextEditor
                            name="description"
                            value={description}
                            onChange={setDescription}
                            toolbarConfig={toolbarConfig}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('General.SchoolYear')}</label>
                        <Select
                            value={{ value: year, label: schoolYear(year).yearText() }}
                            options={schyOptions}
                            onChange={year => setYear(year.value)}
                            isDisabled={schyOptions.length === 1}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('ClassMeeting.Group')}</label>
                        <Select
                            value={groups}
                            options={groupOptions}
                            onChange={setGroups}
                            isMulti
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="teacher">{translate('ClassMeeting.Teacher')}</label>
                        <Select
                            value={teacher}
                            options={teachers}
                            onChange={setTeacher}
                            isClearable
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="classroom">{translate('Classroom.Title')}</label>
                        <Select
                            value={classroom}
                            options={classrooms}
                            onChange={setClassroom}
                            isClearable
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('ClassMeeting.Appointments')}</label>
                        <AppointmentControl
                            value={appointments}
                            onChange={setAppointments}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="regFrom">{translate('ClassMeeting.RegFrom')}</label>
                        <DateTimeControl
                            className="form-control"
                            value={regFrom}
                            onChange={setRegFrom}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('ClassMeeting.RegTo')}</label>
                        <DateTimeControl
                            className="form-control"
                            value={regTo}
                            onChange={setRegTo}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('ClassMeeting.IsDisabled')}</label>
                        <Select
                            value={isDisabled}
                            options={yesnoOptions}
                            onChange={value => setIsDisabled(value)}
                        />
                    </div>
                    <div className="form-group">
                        <label>{translate('ClassMeeting.IsHidden')}</label>
                        <Select
                            value={isHidden}
                            options={yesnoOptions}
                            onChange={value => setIsHidden(value)}
                        />
                    </div>
                    <ImgControl
                        value={img}
                        onChange={value => setImg(value)}
                    />
                    <NotificationsControl
                        value={notifications}
                        onChange={value => setNotifications(value)}
                        typeTranslation="ClassMeetingNotificationType"
                    />
                    <div className="form-group buttons">
                        <button
                            className="btn btn-imsp"
                            onClick={onSubmitClick}
                        >{translate('Btn.Save')}</button>
                        <button
                            className="btn btn-light"
                            onClick={onCancel}
                        >{translate('Btn.Cancel')}</button>
                    </div>
                </fieldset>
            </div>
        </Modal>
    );
}

export default ClassMeetingModal;