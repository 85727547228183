import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from "react-redux";
import { parse } from 'qs';

import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';

import './attendanceView.scss';
import { toTerm, toMmDdFormat } from '../../../utils/date';
import schoolYear from '../../../utils/schoolYear';


class AttendanceView extends React.Component {
    static propTypes = {
        onTitle: PropTypes.func,
        location: PropTypes.object
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.query = parse(props.location.search.substr(1));
        this.state = {
            courses: [],
            courseFilter: this.query.course
                ? { value: this.query.course }
                : null,
            termFilter: this.query.term
                ? Array.isArray(this.query.term)
                    ? this.query.term.map(o => ({ value: parseInt(o) }))
                    : [{ value: parseInt(this.query.term) }]
                : [],
            schyFilter: { value: schoolYear().year(), label: schoolYear().yearText() }
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadCourses();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Header.AttendanceView');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadCourses() {
        let { schyFilter } = this.state;

        this.api.getCoursesForAttendance({ year: schyFilter.value })
            .then(response => {
                if (this.mounted) {
                    let courses = _.mapKeys(response.data, 'id');
                    this.setState({ courses }, () => {
                        if (!this.state.courseFilter && Object.keys(courses).length) {
                            this.setState({ courseFilter: { value: courses[Object.keys(courses)[0]].id, label: courses[Object.keys(courses)[0]].name } }, () => {
                                if (this.query.print === 'true') {
                                    this.runPrint();
                                }
                            });
                        } else {
                            if (this.query.print === 'true') {
                                this.runPrint();
                            }
                        }
                    });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    runPrint() {
        // console.log('run print');

        if (!this.state.printShown) {

            if (this.mounted) {
                this.setState({
                    printShown: true
                });
            }

            setTimeout(() => {
                console.log('ptint');
                window.print();

                setTimeout(() => {
                    window.close();
                }, 50);
            }, 500);
        }
    }

    getCourseOptions(courses) {
        let result = _.map(courses || {}, o => ({ value: o.id, label: o.name }));
        arraySort(result, 'label');
        return result;
    }

    getData(courses, courseFilter, termFilter) {
        let lessons = courseFilter ? (courses[courseFilter.value] || {}).lessons : [];
        lessons = arraySort(lessons || [], 'time');
        if (termFilter && termFilter.length) {
            lessons = lessons.filter(o => _.some(termFilter, { value: toTerm(o.time).id }));
        }

        let registrations = courseFilter ? (courses[courseFilter.value] || {}).registrations : [];
        registrations = (registrations || []).filter(o => o.isAlternate !== true);

        // odstranění duplicit studentů, způsobených více registracemi
        registrations = registrations.reduce((r, c) => {
            if (!_.some(r, { studentId: c.studentId })) {
                r.push(c);
            }
            return r;
        }, []);

        arraySort(registrations, 'studentName');
        let dict = _.mapKeys(registrations.map((o, i) => ({ id: o.studentId, idx: i, count: 0 })), 'id');

        var result = [[
            { text: this.context.translate('Attendance.Lessons'), allInclusive: false },
            ...registrations.map(o => ({ text: o.studentName, allInclusive: o.allInclusive }))
        ]];

        arraySort(lessons, 'time').forEach(lesson => {
            let row = [lesson, ...(registrations.map(o => ''))];
            (lesson.attendances || []).forEach(att => {
                if (dict[att.studentId]) {
                    row[dict[att.studentId].idx + 1] = att.present === true ? 1 : att.present === false ? -1 : 0;
                    dict[att.studentId].count++;
                }
            });
            result.push(row);
        });

        // mazání studentů, kteří nemají v daném období žádnou docházku
        for (let i = registrations.length; i > 0; i--) {
            if (!dict[registrations[i - 1].studentId].count) {
                // console.log('mažu studenta', registrations[i - 1].studentName);
                for (let j = 0; j < result.length; j++) {
                    result[j].splice(i, 1);
                }
            }
        }

        // console.log('getData', { courses, courseFilter, termFilter, result });
        return result;
    }

    getTermOptions() {
        let { translate } = this.context;

        let result = [
            { value: 1, label: `${translate('Filter.Term')} I` },
            { value: 2, label: `${translate('Filter.Term')} II` },
            { value: 3, label: `${translate('Filter.Term')} III` }
        ];
        return result;
    }

    getPrintUrl(courseFilter, termFilter) {
        let query = {
            print: true,
            course: courseFilter ? courseFilter.value : ''
        };
        let queryString = Object.keys(query).map(o => `${o}=${query[o]}`).join('&');
        if (termFilter && termFilter.length) {
            termFilter.forEach(o => {
                queryString += `&term=${o.value}`;
            });
        }
        let result = `${this.props.location.pathname}?${queryString}`;
        return result;
    }

    landscapeOrientation() {
        return (
            <style type="text/css">
                {"@media print{@page {size: landscape; margin: 4mm; max-width: 100%;};*::-webkit-scrollbar { width: 0 !important }};"}
            </style>
        );
    };

    getPrintHeader(courses, courseFilter, termOptions, termFilter, translate) {
        let result = translate('Header.AttendanceView');
        let params = [];
        let courseParam = courseFilter ? (courses[courseFilter.value] || {}).name || '' : '';
        if (courseParam) {
            params.push(courseParam);
        }
        if (termFilter && termFilter.length) {
            // console.log('xxx', { termFilter, termOptions });
            let termText = termFilter.map(o => _.find(termOptions, { value: o.value }).label).join(', ');
            if (termText) {
                params.push(termText);
            }
        }
        if (params.length) {
            result += ` (${params.join('; ')})`;
        }
        return result;
    }

    render() {
        let { courses, courseFilter, termFilter } = this.state;
        let { translate } = this.context;

        let courseOptions = this.getCourseOptions(courses);
        let data = this.getData(courses, courseFilter, termFilter);
        let termOptions = this.getTermOptions();

        let incl = data[0].slice(1).reduce((r, c, i) => c.allInclusive ? [...r, i] : r, []);
        let sumIncl = data.slice(1).map(r => r.slice(1).reduce((r, c, i) => (incl.includes(i) && (c === -1 || c === 0 || c === 1) ? r + 1 : r), 0)).reduce((r, c) => r + c, 0);
        let sumNonIncl = data.slice(1).map(r => r.slice(1).reduce((r, c, i) => (!incl.includes(i) && (c === -1 || c === 0 || c === 1) ? r + 1 : r), 0)).reduce((r, c) => r + c, 0);
        let regCount = data[0].length - 1;

        let printUrl = this.getPrintUrl(courseFilter, termFilter);
        let print = this.query.print === 'true';
        let printHeader = this.getPrintHeader(courses, courseFilter, termOptions, termFilter, translate);

        return (
            <div className="page-container">
                {print && this.landscapeOrientation()}
                {!print &&
                    <div className="page-menu w-200px">
                        <div className="filter">
                            <div className="filter-group">
                                <label>{translate('Filter.Course')}</label>
                                <Select
                                    options={courseOptions}
                                    value={courseFilter}
                                    onChange={courseFilter => this.setState({ courseFilter })}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>
                            <div className="filter-group">
                                <label>{translate('Filter.Term')}</label>
                                <Select
                                    options={termOptions}
                                    isMulti
                                    onChange={termFilter => this.setState({ termFilter })}
                                    menuPortalTarget={document.querySelector('body')}
                                    placeholder={translate('Codelist.All')}
                                />
                            </div>
                        </div>
                        <div className="buttons">
                            <a
                                className="btn btn-imsp"
                                href={printUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >{this.context.translate('Btn.Print')}</a>
                        </div>
                    </div>
                }
                <div className="page-content">
                    {print &&
                        <div className="print-header">{printHeader}</div>
                    }
                    <table className="imsp-table att-view-table">
                        <colgroup>
                            <col className="col-first" />
                            {data[0].slice(1).map((o, i) => (
                                <col className="col-other" key={i} />
                            ))}
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="col-first">{data[0][0].text}</th>
                                {data[0].slice(1).map((o, i) => (
                                    <th
                                        className={classnames('rotate', { 'all-inclusive': o.allInclusive })}
                                        key={i}
                                    ><div><span>{o.text}</span></div></th>
                                ))}
                                <th className=""></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.slice(1).map((o, i) => {
                                let name = `${toTerm(o[0].time).name} / ${o[0].order}`;
                                let time = `${toMmDdFormat(o[0].time)}`;
                                return (
                                    <tr
                                        key={i}
                                    >
                                        <td><div className="lesson-id">{name}</div>&nbsp;&nbsp;{time}</td>
                                        {o.slice(1).map((v, j) => (
                                            <td className="att-value" key={j}><i className={classnames('fa colored', { 'fa-check': v === 1, 'fa-times': v === -1, 'fa-question': v === 0 })} aria-hidden="true"></i></td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <table className="imsp-table att-sum-table">
                        <colgroup>
                            <col className="col-label" />
                            <col className="col-value" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{translate('Attendance.RegisterCount')}</th>
                                <td className="ta-r">{regCount}</td>
                            </tr>
                            <tr>
                                <th>{translate('Attendance.SumNonInclusive')}</th>
                                <td className="ta-r">{sumNonIncl}</td>
                            </tr>
                            <tr>
                                <th>{translate('Attendance.SumInclusive')}</th>
                                <td className="ta-r">{sumIncl}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        location: state.router.location
    }),
    {}
)(AttendanceView);