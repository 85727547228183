import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import qs from 'qs';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from "react-redux";

import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import Period from '../../../components/Period';
import { initPeriod } from '../../../utils/period';
import { toTimeFormat, toDate, toPeriodDate, toModalDate } from '../../../utils/date';
import { getNameWithGroup } from '../../../utils/course';

import './week.scss';


const AT_MEETING = 'meeting';
const AT_COURSE = 'course';

const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

class Week extends React.Component {
    static propTypes = {
        language: PropTypes.string.isRequired,
        onTitle: PropTypes.func
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.query = qs.parse(props.location.search.substr(1));
        this.state = {
            period: this.query.period ? toDate(this.query.period) : initPeriod('week'),
            activities: [],
            groups: [],
            groupFilter: this.query.group ? this.query.group.map(o => ({ value: o, label: o })) : [],
            typeFilter: this.query.type ? this.query.type.map(o => ({ value: o, label: o })) : []
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadActivities();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Week.Title');
        this.loadGroups();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadActivities() {
        let { period } = this.state;

        this.api.getWeekActivities({ date: period })
            .then(response => {
                if (this.mounted) {
                    let activities = response.data || [];
                    this.setState({ activities }, () => {
                        if (this.query.print === 'true') {
                            this.runPrint();
                        }
                    });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadGroups() {
        this.api.getGroups()
            .then(response => {
                let groups = response.data || [];
                if (this.mounted) {
                    this.setState({ groups });
                }
            })
            .catch(error => {
                console.log('Week.loadGroups', error);
            });
    }

    runPrint() {
        // console.log('run print');

        if (!this.state.printShown) {

            if (this.mounted) {
                this.setState({
                    printShown: true
                });
            }

            setTimeout(() => {
                window.print();

                setTimeout(() => {
                    window.close();
                }, 50);
            }, 50);
        }
    }

    onPeriodChange(period) {
        this.setState({ period }, () => {
            this.loadActivities();
        });
    }

    getActivityList(activities, groupFilter, typeFilter) {
        let filtered = this.filterActivities(activities, groupFilter, typeFilter);
        let result = filtered.map(o => o.type === AT_MEETING
            ? this.getMeetingActivity(o)
            : this.getCourseActivity(o));

        result = arraySort(result, 'time');
        return result;
    }

    filterActivities(activities, groupFilter, typeFilter) {
        let result = activities;

        if (groupFilter && groupFilter.length) {
            result = result.filter(o => {
                let groups = ((o.type === AT_MEETING ? o.meeting : o.course) || {}).groups || [];
                return _.some(groups, g => _.some(groupFilter, { value: g.id }));
            });
        }

        if (typeFilter && typeFilter.length) {
            result = result.filter(o => _.some(typeFilter, { value: o.type }));
        }

        return result;
    }

    getCourseActivity(activity) {
        var result = {
            ...activity,
            activityName: activity.course.name,
            time: activity.lesson.time,
            timeStr: `${this.context.translate(`Days.Short.${toDate(activity.lesson.time).getDay()}`)} ${toTimeFormat(activity.lesson.time)}`,
            studentNames: _.map(groupBy(activity.students || [], 'groupId'), g => ({ group: g[0].groupName || g[0].groupId, names: g.map(o => (o || {}).name).sort().join(', ') }))
        };
        return result;
    }

    getMeetingActivity(activity) {
        var result = {
            ...activity,
            activityName: activity.meeting.name,
            time: activity.appointment.time,
            timeStr: `${this.context.translate(`Days.Short.${toDate(activity.appointment.time).getDay()}`)} ${toTimeFormat(activity.appointment.time)}`,
            studentNames: _.map(groupBy(activity.students || [], 'groupId'), g => ({ group: g[0].groupName || g[0].groupId, names: g.map(o => (o || {}).name).sort().join(', ') }))
        };
        return result;
    }

    export() {
        let { period, groupFilter, typeFilter } = this.state;
        let { language } = this.props;

        let params = {
            period: toModalDate(period),
            lng: language,
            groups: groupFilter.map(o => o.value),
            types: typeFilter.map(o => o.value)
        };
        this.api.exportWeekCourseReportPdf(params)
            .then(response => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `courses-${toPeriodDate(period)}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            })
    }

    getGroupFilterOptions(groups) {
        let result = (groups || []).map(o => ({ value: o.id, label: o.name }));
        arraySort(result, 'label');
        return result;
    }

    getTypeFilterOptions(translate) {
        let result = [
            { value: AT_COURSE, label: translate('Menu.Courses') },
            { value: AT_MEETING, label: translate('Menu.Meetings') }
        ];
        return result;
    }

    getPrintUrl(period, group, type) {
        let query = {
            print: true,
            period: toModalDate(period)
        };
        if (group && group.length) {
            query.group = group.map(o => o.value);
        }
        if (type && type.length) {
            query.type = type.map(o => o.value);
        }

        let result = `${this.props.location.pathname}?${qs.stringify(query)}`;
        return result;
    }

    render() {
        let { translate } = this.context;
        let { period, activities, groupFilter, groups, typeFilter } = this.state;

        let activityList = this.getActivityList(activities, groupFilter, typeFilter);
        let groupFilterOptions = this.getGroupFilterOptions(groups);
        let typeFilterOptions = this.getTypeFilterOptions(translate);

        let printUrl = this.getPrintUrl(period, groupFilter, typeFilter);
        let print = this.query.print === 'true';
        let printHeader = `${translate('Week.Title')} (${moment(period).format('D. M. YYYY')} - ${moment(period).add(6, 'days').format('D. M. YYYY')})`;

        // console.log('activityList', activityList);

        return (
            <div className="page-container view-week-page">
                {!print &&
                    <div className="page-menu">
                        <div className="filter">
                            <div className="filter-group">
                                <label>{this.context.translate('Filter.Period')}</label>
                                <Period
                                    type="week"
                                    period={period}
                                    onChange={p => this.onPeriodChange(p)}
                                />
                            </div>
                            <div className="filter-group">
                                <label>{this.context.translate('Filter.Group')}</label>
                                <Select
                                    options={groupFilterOptions}
                                    isMulti
                                    onChange={groupFilter => this.setState({ groupFilter })}
                                    menuPortalTarget={document.querySelector('body')}
                                    placeholder={translate('Codelist.All')}
                                />
                            </div>
                            <div className="filter-group">
                                <label>{translate('Filter.ActivityType')}</label>
                                <Select
                                    options={typeFilterOptions}
                                    isMulti
                                    onChange={typeFilter => this.setState({ typeFilter })}
                                    menuPortalTarget={document.querySelector('body')}
                                    placeholder={translate('Codelist.All')}
                                />
                            </div>
                        </div>
                        <div className="buttons">
                            <div
                                className="btn btn-imsp"
                                onClick={() => this.export()}
                            >{this.context.translate('Btn.Export')}</div>
                            <a
                                className="btn btn-imsp"
                                href={printUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >{this.context.translate('Btn.Print')}</a>
                        </div>
                    </div>
                }
                <div className="page-content">
                    {print &&
                        <div className="print-header">{printHeader}</div>
                    }
                    <table className={classnames('table-structure-list', { print })}>
                        <thead><tr><th>
                            <div className="header">
                                <div className="line">
                                    <div className="column fill w-40pr">{this.context.translate('Week.Activity')}</div>
                                    <div className="column fill w-20pr">{this.context.translate('Week.Classroom')}</div>
                                    <div className="column fill w-20pr">{this.context.translate('Week.Teacher')}</div>
                                    <div className="column f-100">{this.context.translate('Week.Time')}</div>
                                </div>
                                <div className="column">{this.context.translate('Week.Students')}</div>
                            </div>
                        </th></tr></thead>
                        <tbody className="body">
                            {activityList.map((o, i) => {
                                return (
                                    <tr key={i}><td>
                                        <div className="item">
                                            <div className="pc-style">
                                                <div className="line">
                                                    <div className="value fill w-40pr b">{o.activityName || ''}</div>
                                                    <div className="value fill w-20pr">{o.classroom && o.classroom.name || ''}</div>
                                                    <div className="value fill w-20pr">{o.teacher && o.teacher.name || ''}</div>
                                                    <div className="value f-100">{o.timeStr}</div>
                                                </div>
                                                <div className="line">
                                                    <table className="student-table">
                                                        <tbody>
                                                            {(o.studentNames || []).map((o, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td className={classnames('group', { nogroup: !o.group })}>{o.group || translate('Week.NoGroup')}</td>
                                                                        <td className="names">{o.names}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </td></tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
        language: state.localize.language
    }),
    {}
)(Week);