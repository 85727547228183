import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import './lessonTime.scss';


const LessonTime = ({ value }, { translate }) => {

    if (!value) {
        return (
            <div className="no-time">{translate('Overview.NoTime')}</div>
        );
    }

    let time = moment(value);
    let now = moment();
    let type = 'default';

    return (
        <div className="lesson-time">
            <div className="lt-day">{time.format('ddd')}</div>
            <div className="lt-date">{time.format('D. M.')}</div>
            <div className="lt-time">{time.format('HH:mm')}</div>
        </div>
    );
}

LessonTime.contextTypes = {
    translate: PropTypes.func.isRequired
};

export default LessonTime;