import * as toastr from 'toastr';
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ApiService } from '../../../services/ApiService';

import './settings.scss';


class Settings extends React.Component {
    static propTypes = {
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            edit: {
                accountNumber: '',
                specificSymbol: '',
                sendTeacherEmailCopy: '',
                adminEmail: '',
                negBalNotifEnabled: '',
                negBalNotifPeriod: '',
                negBalNotifTime: '',
                showCourseDaysBeforeSchy: 0,
                autoCreatePaymentInterval: 0
            }
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadData();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Settings.Title');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadData() {
        this.api.getSettings()
            .then(response => {
                if (this.mounted) {
                    this.setState({ data: response.data });
                }
            })
            .catch(error => {
                console.log('Settings.loadData error', error);
            })
    }

    saveOther() {
        if (this.validateOther()) {
            let { sendTeacherEmailCopy, adminEmail, negBalNotifEnabled, negBalNotifPeriod, negBalNotifTime } = this.state.data;
            let { accountNumber, specificSymbol, showCourseDaysBeforeSchy, autoCreatePaymentInterval } = this.state.edit;

            let data = { accountNumber, specificSymbol, sendTeacherEmailCopy, adminEmail, negBalNotifEnabled, negBalNotifPeriod, negBalNotifTime, showCourseDaysBeforeSchy, autoCreatePaymentInterval };
            this.api.setSettings(data)
                .then(response => {
                    this.loadData();
                    this.setState({ edit: { ...this.state.edit, state: '' } });
                    toastr.success(this.context.translate('Settings.OtherSuccess'));
                })
                .catch(error => {
                    console.log('Settings.saveOther error save other settings', error);
                    toastr.error(error);
                });
        }
    }

    saveEmail() {
        if (this.validateOther()) {
            let { accountNumber, specificSymbol, negBalNotifEnabled, negBalNotifPeriod, negBalNotifTime, showCourseDaysBeforeSchy, autoCreatePaymentInterval } = this.state.data;
            let { sendTeacherEmailCopy, adminEmail } = this.state.edit;

            let data = { accountNumber, specificSymbol, sendTeacherEmailCopy, adminEmail, negBalNotifEnabled, negBalNotifPeriod, negBalNotifTime, showCourseDaysBeforeSchy, autoCreatePaymentInterval };
            this.api.setSettings(data)
                .then(response => {
                    this.loadData();
                    this.setState({ edit: { ...this.state.edit, state: '' } });
                    toastr.success(this.context.translate('Settings.SaveEmailSuccess'));
                })
                .catch(error => {
                    console.log('Settings.saveEmail error', error);
                    toastr.error(error);
                });
        }
    }

    saveNotice() {
        if (this.validateOther()) {
            let { accountNumber, specificSymbol, sendTeacherEmailCopy, adminEmail, showCourseDaysBeforeSchy, autoCreatePaymentInterval } = this.state.data;
            let { negBalNotifEnabled, negBalNotifPeriod, negBalNotifTime } = this.state.edit;

            let data = { accountNumber, specificSymbol, sendTeacherEmailCopy, adminEmail, negBalNotifEnabled, negBalNotifPeriod, negBalNotifTime, showCourseDaysBeforeSchy, autoCreatePaymentInterval };
            this.api.setSettings(data)
                .then(response => {
                    this.loadData();
                    this.setState({ edit: { ...this.state.edit, state: '' } });
                    toastr.success(this.context.translate('Settings.SaveNoticeSuccess'));
                })
                .catch(error => {
                    if (this.mounted) {
                        // if (errorEquals(error, ERR_USER_IS_TEACHER)) {
                        //     toastr.warning(this.context.translate('Err.UserIsTeacher'));
                        // } else {
                            toastr.error(this.context.translate('Err.System'));
                        // }
                    }
                });
        }
    }

    validateOther() {
        return true;
    }

    render() {
        let { data, edit } = this.state;
        let { accountNumber, specificSymbol, sendTeacherEmailCopy, adminEmail,
            negBalNotifEnabled, negBalNotifPeriod, negBalNotifTime, showCourseDaysBeforeSchy, autoCreatePaymentInterval } = data;

        let otherEdit = edit.state === 'other';
        let emailEdit = edit.state === 'email';
        let noticeEdit = edit.state === 'notice';

        return (
            <div className="settings-page">
                <div className="settings-page-content">
                    <div className="section">
                        <div className="section-title">{this.context.translate('Settings.EmailSection')}</div>
                        <div className="section-content">
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.SendTeacherEmailCopy')}</div>
                                {!emailEdit &&
                                    <div className="value ta-r">{sendTeacherEmailCopy ? this.context.translate('Codelist.Yes') : this.context.translate('Codelist.No')}</div>
                                }
                                {emailEdit &&
                                    <input
                                        className="checkbox-control"
                                        type="checkbox"
                                        checked={edit.sendTeacherEmailCopy}
                                        onChange={e => this.setState({ edit: { ...edit, sendTeacherEmailCopy: e.target.checked } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.AdminEmail')}</div>
                                {!emailEdit &&
                                    <div className="value ta-r">{adminEmail}</div>
                                }
                                {emailEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.adminEmail || ''}
                                        onChange={e => this.setState({ edit: { ...edit, adminEmail: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="buttons">
                                {!emailEdit &&
                                    <div
                                        className="btn btn-imsp"
                                        onClick={() => this.setState({ edit: { ...edit, state: 'email', sendTeacherEmailCopy, adminEmail } })}
                                    >{this.context.translate('Btn.Update')}</div>
                                }
                                {emailEdit &&
                                    <>
                                        <div
                                            className="btn btn-default"
                                            onClick={() => this.setState({ edit: { ...edit, state: '' } })}
                                        >{this.context.translate('Btn.Cancel')}</div>
                                        <div
                                            className="btn btn-imsp"
                                            onClick={() => this.saveEmail()}
                                        >{this.context.translate('Btn.Save')}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="section-title">{this.context.translate('Settings.NoticeSection')}</div>
                        <div className="section-content">
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.NegBalNotifEnabled')}</div>
                                {!noticeEdit &&
                                    <div className="value ta-r">{negBalNotifEnabled ? this.context.translate('Codelist.Yes') : this.context.translate('Codelist.No')}</div>
                                }
                                {noticeEdit &&
                                    <input
                                        className="checkbox-control"
                                        type="checkbox"
                                        checked={edit.negBalNotifEnabled}
                                        onChange={e => this.setState({ edit: { ...edit, negBalNotifEnabled: e.target.checked } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.NegBalNotifPeriod')}</div>
                                {!noticeEdit &&
                                    <div className="value ta-r">{negBalNotifPeriod}</div>
                                }
                                {noticeEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.negBalNotifPeriod || ''}
                                        onChange={e => this.setState({ edit: { ...edit, negBalNotifPeriod: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.NegBalNotifTime')}</div>
                                {!noticeEdit &&
                                    <div className="value ta-r">{negBalNotifTime}</div>
                                }
                                {noticeEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.negBalNotifTime || ''}
                                        onChange={e => this.setState({ edit: { ...edit, negBalNotifTime: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="buttons">
                                {!noticeEdit &&
                                    <div
                                        className="btn btn-imsp"
                                        onClick={() => this.setState({ edit: { ...edit, state: 'notice', negBalNotifEnabled, negBalNotifPeriod, negBalNotifTime } })}
                                    >{this.context.translate('Btn.Update')}</div>
                                }
                                {noticeEdit &&
                                    <>
                                        <div
                                            className="btn btn-default"
                                            onClick={() => this.setState({ edit: { ...edit, state: '' } })}
                                        >{this.context.translate('Btn.Cancel')}</div>
                                        <div
                                            className="btn btn-imsp"
                                            onClick={() => this.saveNotice()}
                                        >{this.context.translate('Btn.Save')}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="section-title">{this.context.translate('Settings.Other')}</div>
                        <div className="section-content">
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.AccountNumber')}</div>
                                {!otherEdit &&
                                    <div className="value ta-r">{accountNumber}</div>
                                }
                                {otherEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        autoFocus
                                        value={edit.accountNumber || ''}
                                        onChange={e => this.setState({ edit: { ...edit, accountNumber: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.SpecificSymbol')}</div>
                                {!otherEdit &&
                                    <div className="value ta-r">{specificSymbol}</div>
                                }
                                {otherEdit &&
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.specificSymbol || ''}
                                        onChange={e => this.setState({ edit: { ...edit, specificSymbol: e.target.value } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.ShowCourseDaysBeforeSchy')}</div>
                                {!otherEdit &&
                                    <div className="value ta-r">{showCourseDaysBeforeSchy}</div>
                                }
                                {otherEdit &&
                                    <input
                                        className="form-control value"
                                        type="number"
                                        value={edit.showCourseDaysBeforeSchy || 0}
                                        onChange={e => this.setState({ edit: { ...edit, showCourseDaysBeforeSchy: parseInt(e.target.value) } })}
                                    />
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.AutoCreatePaymentInterval')}</div>
                                {!otherEdit &&
                                    <div className="value ta-r">{autoCreatePaymentInterval}</div>
                                }
                                {otherEdit &&
                                    <input
                                        className="form-control value"
                                        type="number"
                                        value={edit.autoCreatePaymentInterval || 0}
                                        onChange={e => this.setState({ edit: { ...edit, autoCreatePaymentInterval: parseInt(e.target.value) } })}
                                    />
                                }
                            </div>
                            <div className="buttons">
                                {!otherEdit &&
                                    <div
                                        className="btn btn-imsp"
                                        onClick={() => this.setState({ edit: { ...edit, state: 'other', accountNumber, specificSymbol, showCourseDaysBeforeSchy, autoCreatePaymentInterval } })}
                                    >{this.context.translate('Btn.Update')}</div>
                                }
                                {otherEdit &&
                                    <>
                                        <div
                                            className="btn btn-default"
                                            onClick={() => this.setState({ edit: { ...edit, state: '' } })}
                                        >{this.context.translate('Btn.Cancel')}</div>
                                        <div
                                            className="btn btn-imsp"
                                            onClick={() => this.saveOther()}
                                        >{this.context.translate('Btn.Save')}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Settings);