import DatePicker from "react-datepicker";
import React, { useRef } from 'react';
import { parseDate, toModalDateTime } from "../../utils/date";


const DateTimeControl = ({ value, onChange, isClearable, className }) => {

    const dpRef = useRef(null);

    return (
        <DatePicker
            ref={dpRef}
            className={className}
            dateFormat={["d. M. yyyy HH:mm", "d.M.yyyy HH:mm", "d. M.yyyy HH:mm", "d.M. yyyy HH:mm"]}
            showTimeSelect
            selected={value ? parseDate(value) : null}
            onChange={date => {
                if (onChange) onChange(toModalDateTime(date));
                if (!dpRef.current.isCalendarOpen()) {
                    dpRef.current.setOpen(false, true);
                    dpRef.current.setFocus(true);
                }
            }}
            onInputClick={() => {
                if (dpRef.current.isCalendarOpen()) {
                    dpRef.current.setOpen(false, true);
                    dpRef.current.setFocus(true);
                }
            }}
            isClearable={isClearable !== false}
            shouldCloseOnSelect={false}
            preventOpenOnFocus={true}
            placeholderText="d. M. yyyy HH:mm"
            strictParsing={true}
        />
    );
}

export default DateTimeControl;